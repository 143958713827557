import React, {Component} from 'react'
import {Box, Fab, Grid, Tooltip} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {AfipService} from "services";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import DoneIcon from "@material-ui/icons/DoneRounded";
import {FilesDialog} from "components/ventas/afip/cot/dialogs/files.dialog";
import {ArbaDialog} from "components/ventas/afip/cot/dialogs/arba.dialog";
import printJS from 'print-js'

export class AfipCot extends Component {
    state = {
        fechaDesde: (new MomentUtils()).date(new Date()).subtract(1, "days"),
        fechaHasta: (new MomentUtils()).date(new Date()),
        dominio: '',
        documentos: {
            columns: [
                {name: 'tipo', title: 'Tipo'},
                {name: 'numero', title: 'Número'},
                {name: 'ingreso', title: 'Fecha'},
                {name: 'importe', title: 'Importe'},
                {name: 'razsoc', title: 'Cliente'}
            ],
            columnExtensions: [
                {columnName: 'tipo', width: 80, align: 'center'},
                {columnName: 'numero', width: 130},
                {columnName: 'ingreso', width: 120, align: 'center'},
                {columnName: 'importe', width: 100, type: "currency", align: 'right'},
            ],
            data: [],
            selected: []
        },
        filesDialogOpen: false,
        arbaDialogOpen: false,
        res_arba: null
    };

    AfipService = new AfipService(this.context);

    onChangeDate = (field, value) => {
        this.setState({[field]: value}, () => {
            this.getDocuments();
        });
    };

    onChangeDominio = event => this.setState({dominio: event.target.value});

    onSelect = (field, selected) => {
        console.log(selected);
        if (selected.length <= 23)
            this.setState(prevState => (prevState[field].selected = selected));
        else
            this.props.enqueueSnackbar(`Solo puede seleccionar hasta 23 ${field}.`, {variant: 'warning'});
    };

    getDocuments = () => {
        this.AfipService.getDocumentosCOT(
            this.state.fechaDesde.format('DD/MM/YY'),
            this.state.fechaHasta.format('DD/MM/YY'),
            response => this.setState(prevState => prevState.documentos.data = response.data.result),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    openFilesDialog = () => this.setState({filesDialogOpen: true});
    closeFilesDialog = () => this.setState({filesDialogOpen: false});

    openArbaDialog = () => this.setState({arbaDialogOpen: true});
    closeArbaDialog = () => this.setState({arbaDialogOpen: false});

    onFileAction = (action) => {
        // Busco los documentos completos sleeccionados.
        const documentos = this.state.documentos.data.filter((documento, index) => this.state.documentos.selected.indexOf(index) !== -1);
        // Separo los documentos en facturas y remitos.
        let facturas = documentos.filter(documento => documento.tipo === 'FAC');
        let remitos = documentos.filter(documento => documento.tipo === 'REM');
        // Obtengo el id de cada documento.
        facturas = facturas.map(factura => factura.id);
        remitos = remitos.map(remito => remito.id);

        this.AfipService.generateFileCOT(
            facturas,
            remitos,
            this.state.dominio,
            action,
            response => {
                if (action === 'send_to_arba') {
                    if (response.data.success === true)
                        this.setState({res_arba: response.data.result}, () => this.openArbaDialog());
                    else
                        switch(response.data.result.codigoError) {
                            case "01":
                                this.props.enqueueSnackbar(`ARBA: Error interno, intente mas tarde.`, {variant: 'error'})
                                break;
                            default:
                                this.props.enqueueSnackbar(`ARBA: ${response.data.result.mensajeError}`, {variant: 'error'});
                        }
                } else {
                    const fileURL = URL.createObjectURL(response.data);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = response.headers['n-filename'];
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onDownloadArbaResult = () => {
        const printableData = [];
        this.state.res_arba.validacionesRemitos.remito.forEach(remito => {
            printableData.push({
                numeroUnico: 'A' + remito.numeroUnico,
                procesado: remito.procesado,
                cot: remito.cot ? remito.cot : '',
                error: remito.errores && remito.errores.error ? remito.errores.error.descripcion : ''
            });
        });

        printJS({
            printable: printableData,
            type: 'json',
            properties: [
                {field: 'numeroUnico', displayName: 'Documento'},
                {field: 'procesado', displayName: 'Procesado'},
                {field: 'cot', displayName: 'COT'},
                {field: 'error', displayName: 'Mensaje'},
            ],
            documentTitle: 'Niquel_COT_' + this.state.dominio,
            style: `
                @import url('https://fonts.googleapis.com/css?family=Roboto');
                @font-face {
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
                  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
                } 
                * {
                    font-family: "Roboto";
                }
                th,td {
                    text-align: center;
                }
                td:nth-child(4) {
                    font-size: 12px
                }
            `,
            // gridHeaderStyle: 'border: none; align: center',
            // gridStyle: 'align: center',
            header: '<div style="margin-bottom: 20px">' +
                    '<h3 align="center">COT</h3>' +
                    '<p><b>Vehículo patente:</b> ' + this.state.dominio + '</p>' +
                '</div>',
        })
    }

    componentDidMount() {
        this.getDocuments();
    }

    render() {
        const {documentos, fechaDesde, fechaHasta, dominio, res_arba, filesDialogOpen, arbaDialogOpen} = this.state;

        return (
            <>
                <Box className='niquel-afip-main'>
                    <SubHeader
                        title={'AFIP - COT'}
                        subtitle={'Seleccionar Documentos'}
                    />
                    <Box className='container-xl mt-3 mb-3'>
                        <Grid container spacing={2}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        className={'niquel-date-input'}
                                        label="Desde"
                                        format="DD/MM/Y"
                                        value={fechaDesde}
                                        onChange={moment => this.onChangeDate('fechaDesde', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        className={'niquel-date-input'}
                                        label="Hasta"
                                        format="DD/MM/Y"
                                        value={fechaHasta}
                                        onChange={moment => this.onChangeDate('fechaHasta', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Box className={'mt-3'}>
                            <ReactGrid
                                columns={documentos.columns}
                                columnExtensions={documentos.columnExtensions}
                                rows={documentos.data}
                                title={`Documentos`}
                                showResultCount={true}
                                enableFiltering={true}
                                enableSorting={true}
                                enableSearch={true}
                                enableSelection={true}
                                selectedRows={documentos.selected}
                                onSelectionChange={selected => this.onSelect('documentos', selected)}
                                onDelete={selected => this.onSelect('documentos', [])}
                                showSelectAll={false}
                            />
                        </Box>
                        <Tooltip title={"Confirmar"}>
                            <Box className='niquel-fab-btn'>
                                <Fab color="primary" onClick={this.openFilesDialog} disabled={documentos.selected.length < 1}>
                                    <DoneIcon/>
                                </Fab>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                <FilesDialog
                    open={filesDialogOpen}
                    dominio={dominio}
                    onChangeDominio={this.onChangeDominio}
                    documentos={documentos.selected}
                    onDownloadTxt={() => this.onFileAction('txt')}
                    onDownloadXls={() => this.onFileAction('xls')}
                    onSendToArba={() => this.onFileAction('send_to_arba')}
                    onClose={this.closeFilesDialog}
                />
                <ArbaDialog
                    open={arbaDialogOpen}
                    result={res_arba}
                    dominio={dominio}
                    onDownload={this.onDownloadArbaResult}
                    onClose={this.closeArbaDialog}
                />
            </>
        )
    }
}

AfipCot.contextType = MainContext;
AfipCot = withSnackbar(AfipCot);
