import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import {MainContext} from "contexts/main.context";
import {AppBar, Badge, Box, IconButton, Toolbar, Tooltip, Typography, useScrollTrigger, withStyles} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import MailIcon from '@material-ui/icons/Mail';
import FullScreenEnterIcon from '@material-ui/icons/FullscreenRounded';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExitRounded';
import {isMobile, osName} from 'react-device-detect';
import {AuthService} from "services";
import {toggleFullScreen} from "assets/utils";
import styles from './styles';
import './header.styles.scss';
import {NotificationsMenu} from "./notifications.menu";
import {UserMenu} from "./user.menu";

const navBack = history => history.goBack();

function ElevationScroll(props) {
    const {children} = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 48,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

class Header extends React.Component {
    AuthService = new AuthService(this.context);

    anchorRef = React.createRef();

    onLogout = () => {
        this.AuthService.logout(
            () => {
                this.props.history.push('');
            }
        )
    };

    render() {
        const {classes, history} = this.props;
        const {sidebarOpen, headerTitle, isFullScreen} = this.context;

        return (
            <div className='niquel-header'>
                <ElevationScroll {...this.props}>
                    <AppBar className={classNames(classes.appBar, {[classes.appBarShift]: sidebarOpen})}>
                        <Toolbar variant="dense">
                            <Tooltip title='Volver' placement='right'>
                            <span className='btn-back d-inline-flex align-items-center' onClick={() => navBack(history)}>
                                <BackIcon fontSize={"small"}/>
                                <Typography variant="body1" color="inherit" noWrap>{headerTitle}</Typography>
                            </span>
                            </Tooltip>
                            <Box className={classes.grow}/>
                            <Box>
                                {(isMobile || osName === 'Android') && (
                                    <Tooltip title={isFullScreen ? 'Salir de pantalla completa' : 'Pantalla completa'}>
                                        <IconButton color="inherit" onClick={toggleFullScreen}>
                                            {isFullScreen ? <FullScreenExitIcon fontSize={"small"}/> : <FullScreenEnterIcon fontSize={"small"}/>}
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title={'Correo'}>
                                    <IconButton color="inherit">
                                        <Badge badgeContent={0} color="secondary">
                                            <MailIcon fontSize={"small"} style={{color: 'rgba(255,255,255,.5)'}}/>
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <NotificationsMenu
                                    anchorRef={this.anchorRef}
                                    context={this.context}
                                />
                                <UserMenu
                                    anchorRef={this.anchorRef}
                                    context={this.context}
                                    onLogout={this.onLogout}
                                />
                            </Box>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
            </div>
        )
    };
}

Header.contextType = MainContext;
Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Header));
