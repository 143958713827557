import React from "react";
import classNames from 'classnames';
import {Grid, IconButton, InputAdornment, MenuItem, TextField, Tooltip, withStyles} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import {calcImporteByArticulo, regExpInteger, regExpPercentage, regExpPrice} from "assets/utils.js";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import {formatPrice, InputInteger} from "assets/utils";

const styles = theme => ({
    textField: {
        '&.quantity': {
            width: '3rem'
        },
        '&.bonificacion': {
            width: '4.5rem'
        },
        '&.precio': {
            width: '6.7rem'
        },
        marginRight: '1rem',
        '& div': {
            fontSize: '.9rem !important'
        }
    },
    itemTitle: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    lastItem: {
        marginRight: '0 !important'
    }
});

class ArticuloEditFields extends React.Component {
    static defaultProps = {
        id: '-',
        detalle: 'Artículo',
        cantidad: 1,
        precio: 100,
        bonificacion: 0,
        deposito: 1,
        afacturar: 1,
        adescargar: 1,
        onEditArticulo: () => {
        },
        onRemoveArticulo: () => {
        },
        showDelete: false
    };

    handleChange = sField => oEvent => {
        let {detalle, cantidad, precio, bonificacion, deposito, afacturar, adescargar} = this.props;
        let value = oEvent.target.value;

        switch (sField) {
            case 'detalle':
                detalle = value;
                break;
            case 'cantidad':
                cantidad = value;
                afacturar = cantidad;
                adescargar = cantidad;
                break;
            case 'precio':
                precio = regExpPrice.test(value) ? value : precio;
                break;
            case 'bonificacion':
                if (this.context.loggedUser.role !== 'A' && value > 10)
                    return;

                bonificacion = regExpPercentage.test(value) ? value : bonificacion;
                break;
            case 'deposito':
                deposito = value;
                break;
            case 'afacturar':
                afacturar = regExpInteger.test(value) && value <= cantidad ? value : afacturar;
                adescargar = afacturar;
                break;
            case 'adescargar':
                adescargar = regExpInteger.test(value) && value <= afacturar ? value : adescargar;
                break;
            default:
                value = null;
        }

        const importe = calcImporteByArticulo(cantidad, precio, bonificacion);
        const valid = this.isValid(importe, detalle);

        this.props.onEditArticulo({
            detalle: detalle,
            cantidad: cantidad,
            precio: precio,
            bonificacion: bonificacion,
            deposito: deposito,
            afacturar: afacturar,
            adescargar: adescargar,
            importe: importe,
            isValid: valid
        });
    };

    isValid = (importe, descripcion) => {
        let valid = !isNaN(importe) && descripcion !== '';
        return valid;
    };

    getBonificacionHelperText = () => {
        if (!isNaN(parseFloat(this.props.bonificacion_cliente)))
            return '% fijo por cliente';

        if (this.context.loggedUser.role !== 'A')
            return 'Max 10%';
    };

    render() {
        const {
            classes, id, detalle, cantidad, precio, bonificacion, bonificacion_cliente, deposito, afacturar, adescargar, showDelete, mode, depositos,
            onRemoveArticulo
        } = this.props;
        const importe = calcImporteByArticulo(cantidad, precio, bonificacion);
        const isValid = this.isValid(importe);

        return (
            <Grid container>
                <Grid item container className='mb-3'>
                    <Grid>
                        <Typography variant={"caption"}>Cod. artículo</Typography>
                        <Typography variant={"body1"} color={"textSecondary"}>{id}</Typography>
                    </Grid>
                    <Grid className={'ml-4'}>
                        <Typography variant={"caption"}>Total</Typography>
                        <Typography>
                            <Typography component={'span'} color={"textSecondary"}>{isValid ? '$ ' : ' '}</Typography>
                            <Typography component={'span'} color={isValid ? 'textPrimary' : 'error'}>{isValid ? formatPrice(importe) : 'Precio Inválido'}</Typography>
                        </Typography>
                    </Grid>
                    {showDelete && (
                        <Grid className={'ml-4'}>
                            <Tooltip title='Quitar'>
                                <IconButton
                                    edge={"end"}
                                    aria-label="Delete"
                                    onClick={() => onRemoveArticulo(id)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <Grid item container className='mb-2' spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Detalle"
                            className={classNames(classes.textField, 'detail')}
                            value={detalle}
                            margin="none"
                            onChange={this.handleChange('detalle')}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Cantidad"
                            value={cantidad}
                            margin="none"
                            onChange={this.handleChange('cantidad')}
                            onFocus={e => e.target.select()}
                            InputProps={{
                                inputComponent: InputInteger
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Precio Unitario"
                            value={precio}
                            margin="none"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                readOnly: this.context.loggedUser.cmpid === 'coutoune'
                            }}
                            onChange={this.handleChange('precio')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Bonificación"
                            value={bonificacion}
                            margin="none"
                            helperText={this.getBonificacionHelperText()}
                            disabled={this.context.loggedUser.cmpid === 'bernerbsas' && !isNaN(parseFloat(bonificacion_cliente))}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                // Bonificación es temporalmente readonly (03/11/19)
                                // FIX 16/07 - Solo para Berner.
                                readOnly: this.context.loggedUser.cmpid === 'bernerbsas'
                            }}
                            onChange={this.handleChange('bonificacion')}
                        />
                    </Grid>
                    {/*FIX 16/07 - Se quitan estos 3 campos a pedido de Hugo*/}
                    {/*{mode === 'P' && (*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <TextField*/}
                    {/*            select*/}
                    {/*            fullWidth*/}
                    {/*            label="Depósito"*/}
                    {/*            value={deposito}*/}
                    {/*            margin="none"*/}
                    {/*            onChange={this.handleChange('deposito')}*/}
                    {/*        >*/}
                    {/*            {depositos.map(option => (*/}
                    {/*                <MenuItem key={option.id} value={option.id}>*/}
                    {/*                    {option.description} ({option.id})*/}
                    {/*                </MenuItem>*/}
                    {/*            ))}*/}
                    {/*        </TextField>*/}
                    {/*    </Grid>*/}
                    {/*)}*/}
                    {/*{mode === 'P' && (*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <TextField*/}
                    {/*            fullWidth*/}
                    {/*            label="Facturar"*/}
                    {/*            value={afacturar}*/}
                    {/*            margin="none"*/}
                    {/*            onChange={this.handleChange('afacturar')}*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*)}*/}
                    {/*{mode === 'P' && (*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <TextField*/}
                    {/*            fullWidth*/}
                    {/*            label="Descargar"*/}
                    {/*            value={adescargar}*/}
                    {/*            margin="none"*/}
                    {/*            onChange={this.handleChange('adescargar')}*/}
                    {/*        />*/}
                    {/*    </Grid>*/}
                    {/*)}*/}
                </Grid>
            </Grid>
        )
    }
}

ArticuloEditFields.contextType = MainContext;
ArticuloEditFields = withSnackbar(ArticuloEditFields);

export default withStyles(styles)(ArticuloEditFields);
