import React, {Component} from 'react'
import {Box, IconButton, Tooltip} from "@material-ui/core";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import ClientesService from 'services/clientes.service';
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import ClienteOcasionalIcon from "@material-ui/icons/PersonAddRounded";

export class FacturasClientes extends Component {
    ClientesService = new ClientesService(this.context);

    state = {
        columns: [
            {name: 'id', title: 'ID Cliente', width: 115},
            {name: 'razsoc', title: 'Razón Social'},
            {name: 'localidad', title: 'Localidad'},
            {name: 'direccion', title: 'Dirección'},
            {name: 'apenom', title: 'Vendedor', width: 200}
        ],
        rows: []
    };

    componentDidMount() {
        this.ClientesService.getAll(
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    navList = (row) => this.props.history.push(`${this.props.match.url}/${row.id}`);

    navClienteOcasional = () => this.props.history.push(`clientes/create`);

    render() {
        const {columns, rows} = this.state;

        return (
            <Box className='niquel-facturas-clientes'>
                <SubHeader
                    title={'Facturas'}
                    subtitle={'Selección de Cliente'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={'Clientes'}
                        columns={columns}
                        rows={rows}
                        enableFiltering={true}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navList}
                    />
                </Box>
            </Box>
        )
    }
}

FacturasClientes.contextType = MainContext;
FacturasClientes = withSnackbar(FacturasClientes);