import React, {Component} from 'react'
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {Box, Button, Grid, InputAdornment, MenuItem, TextField, Tooltip, Typography} from '@material-ui/core/index';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers/index";
import MomentUtils from '@date-io/moment/build/index';
import 'moment/locale/es';
import ArticulosMainTable from "components/helpers/articulos/articulos.main.table";
import {LeyendasDialog} from 'components/helpers/dialogs/leyendas.dialog';
import {formatPrice, formatQuantity} from "assets/utils";
import './pedidos.operation.scss';
import SubHeader from "components/helpers/subHeader/subHeader";
import {isMobile, osName} from "react-device-detect";

export class PedidosOperation extends Component {
    state = {
        leyendasDialogOpen: false
    };

    onOpenLeyendasDialog = () => this.setState({leyendasDialogOpen: true});
    onCloseLeyendasDialog = () => this.setState({leyendasDialogOpen: false});
    onConfirmLeyendasDialog = (leyenda1, leyenda2, leyenda3, leyenda4) => {
        this.props.onChangeHeaderData('leyenda1', leyenda1);
        this.props.onChangeHeaderData('leyenda2', leyenda2);
        this.props.onChangeHeaderData('leyenda3', leyenda3);
        this.props.onChangeHeaderData('leyenda4', leyenda4);
        this.onCloseLeyendasDialog();
    };

    render() {
        const {loggedUser} = this.context;
        const {leyendasDialogOpen} = this.state;
        const {
            operation, cliente, pedidos, id, condComerciales,/* vendedores, transportes,*/ tipoasientos, talfacturas, talremitos,
            articulos, bonificacionesLitros, headerData, selectedArticulos, importe, importeBonificado, totalArticulos, totalLitros, maxArticulos,
            onChangeHeaderData, onAddArticulos, onEditArticulo, onRemoveArticulos, onChangeDocument, onOpenDireccionEntregaDialog
        } = this.props;
        const editable = operation === 'CREATE';

        return (
            <Box className='niquel-pedidos-operation'>
                <SubHeader
                    title={'Pedidos'}
                    subtitle={operation === 'VIEW' ? 'Revisión de Pedido' : 'Nuevo Pedido'}
                    cliente={cliente}
                    options={operation === 'VIEW' ? pedidos : undefined}
                    optionsTitle={'Pedido'}
                    optionSelectedId={id}
                    optionsDescriptionSecondaryField={'ingreso'}
                    onChangeOption={onChangeDocument}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={3}>
                        <Grid container item md={10}>
                            <Grid container item alignItems='center' spacing={3}>
                                <Grid item>
                                    <TextField
                                        label="Bonificación"
                                        className='mr-2 inputBonificacion'
                                        value={headerData.bonificacion}
                                        onChange={event => onChangeHeaderData('bonificacion', event.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            // Bonificación es temporalmente readonly (03/11/19)
                                            // readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O'),
                                            readOnly: true
                                        }}
                                    />
                                    <TextField
                                        select
                                        required
                                        label="Lista de Precio"
                                        className='mr-2 select'
                                        value={headerData.listaprecio}
                                        onChange={event => {
                                            onChangeHeaderData('listaprecio', event.target.value);
                                            if (selectedArticulos.length > 0)
                                                this.props.enqueueSnackbar('El cambio de lista de precios solo afectará a los nuevos items que se agreguen al pedido.')
                                        }}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {['1', '2', '3', '4', '5', '6'].map(value => (
                                            <MenuItem key={value} value={value}>
                                                Lista {value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    {(loggedUser.role === 'A' || loggedUser.role === 'S' || loggedUser.role === 'O') && (
                                        <TextField
                                            required
                                            label="Usuario"
                                            className='mr-2 input'
                                            value={headerData.usuario}
                                            onChange={event => onChangeHeaderData('usuario', event.target.value)}
                                            InputProps={{
                                                readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                            }}
                                        />
                                    )}
                                    {editable === false && (loggedUser.role === 'A' || loggedUser.role === 'S' || loggedUser.role === 'O') && (
                                        <TextField
                                            required
                                            label="Vendedor"
                                            className='mr-2 input'
                                            value={headerData.vendedor}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    )}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            required
                                            className='mr-2 date'
                                            label="Ingreso"
                                            format="DD/MM/Y"
                                            disablePast={editable}
                                            value={headerData.ingreso}
                                            onChange={moment => onChangeHeaderData('ingreso', moment)}
                                            InputProps={{
                                                readOnly: editable === false,
                                                onClick: (e) => editable === false && e.stopPropagation()
                                            }}
                                        />
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            required
                                            className='mr-2 date'
                                            label="Entrega"
                                            format="DD/MM/Y"
                                            disablePast={editable}
                                            value={headerData.entrega}
                                            onChange={moment => onChangeHeaderData('entrega', moment)}
                                            InputProps={{
                                                readOnly: editable === false,
                                                onClick: (e) => editable === false && e.stopPropagation()
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <TextField
                                        label="Nota de Compra"
                                        className='mr-2 input'
                                        value={headerData.notacompra}
                                        onChange={event => onChangeHeaderData('notacompra', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Tooltip title='Editar leyendas'>
                                        <Button color="primary" variant='outlined' size={"small"} className='ml-2' onClick={this.onOpenLeyendasDialog}>
                                            {/*<ListIcon fontSize={"default"} className='mr-2'/>*/}
                                            Leyendas
                                        </Button>
                                    </Tooltip>
                                    {editable === true && (
                                        <Tooltip title='Seleccionar dirección de entrega'>
                                            <Button color="primary" variant='outlined' size={"small"} className='ml-2' onClick={onOpenDireccionEntregaDialog}>
                                                {/*<ShippingIcon fontSize={"default"} className='mr-2'/>*/}
                                                Entrega
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Cond. Comer."
                                        className='mr-2 select'
                                        style={{maxWidth: '10rem'}}
                                        value={headerData.condcomercial}
                                        onChange={event => onChangeHeaderData('condcomercial', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {condComerciales.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        required
                                        label="Tal. Factura"
                                        className='mr-2 select'
                                        style={{maxWidth: '14rem'}}
                                        value={headerData.talfactura}
                                        onChange={event => onChangeHeaderData('talfactura', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {talfacturas.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        required
                                        label="Tal. Remito"
                                        className='mr-2 select'
                                        style={{maxWidth: '14rem'}}
                                        value={headerData.talremito}
                                        onChange={event => onChangeHeaderData('talremito', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {talremitos.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        required
                                        label="Tipo Asiento"
                                        className='mr-2 select'
                                        style={{maxWidth: '14rem'}}
                                        value={headerData.tipoasiento}
                                        onChange={event => onChangeHeaderData('tipoasiento', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {tipoasientos.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item md={2} direction={"column"} spacing={1}>
                            <Tooltip title={formatPrice(importe, true) + ' - ' + formatPrice(headerData.bonificacion) + '% (total - bonificación)'}>
                                <Grid item>
                                    <Typography variant={"caption"} display="block" align="right" color={"textSecondary"}>Total Bonificado</Typography>
                                    <Typography variant="h6" align="right">{formatPrice(importeBonificado * 1.21, true)}</Typography>
                                </Grid>
                            </Tooltip>
                            <Grid item>
                                <Typography variant="caption" display="block" align="right" color={"textSecondary"}>Total sin Bonificar</Typography>
                                <Typography variant="subtitle1" align='right'>{formatPrice(importe * 1.21, true)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box className='pedi-articulos'>
                        <ArticulosMainTable
                            articulos={articulos}
                            selected={selectedArticulos}
                            editable={editable}
                            mode={'P'}
                            maxArticulos={maxArticulos}
                            listaprecio={`lista${headerData.listaprecio}`}
                            bonificacionesLitros={bonificacionesLitros}
                            importe={importe * 1.21}
                            totalArticulos={totalArticulos}
                            importeArticulos={importe * 1.21}
                            totalLitros={totalLitros}
                            bonificacionGeneral={headerData.bonificacion}
                            cliente={cliente}
                            onAdd={onAddArticulos}
                            onEdit={onEditArticulo}
                            onRemove={onRemoveArticulos}
                        />
                    </Box>

                    <LeyendasDialog
                        open={leyendasDialogOpen}
                        editable={editable}
                        mode={'P'}
                        leyenda1={headerData.leyenda1}
                        leyenda2={headerData.leyenda2}
                        leyenda3={headerData.leyenda3}
                        leyenda4={headerData.leyenda4}
                        onConfirm={this.onConfirmLeyendasDialog}
                        onCancel={this.onCloseLeyendasDialog}
                        fullScreen={(isMobile || osName === 'Android')}
                    />
                </Box>
            </Box>
        )
    }
}

PedidosOperation.contextType = MainContext;
PedidosOperation = withSnackbar(PedidosOperation);

PedidosOperation.defaultProps = {
    operation: 'CREATE',
    cliente: {},
    pedidos: [],
    condComerciales: [],
    vendedores: [],
    articulos: [],
    headerData: {
        bonificacion: 0,
        listaprecio: '1',
        condcomercial: '',
        notacompra: '',
        usuario: '',
        vendedor: '',
        ingreso: (new MomentUtils()).date(new Date()),
        entrega: (new MomentUtils()).date(new Date()),
        leyenda1: '',
        leyenda2: '',
        leyenda3: '',
        leyenda4: ''
    },
    selectedArticulos: [],
    importe: 0,
    importeBonificado: 0
};
