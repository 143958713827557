import React, {Component} from 'react';
import {UtilsService} from 'services';
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import {ItemList} from "./item";
import {ConfirmDialog} from "components/ventas/recepciones/cobranzas/dialogs/confirm.dialog";
import {Box, Grid, MenuItem, TextField} from "@material-ui/core";
import ItemInfoDialog from "components/ventas/recepciones/cobranzas/dialogs/item.info.dialog";

const TIPOS = [
    {id: 'todo', name: 'Todo'},
    {id: 'CEL', name: 'Cheques Electrónicos'},
    {id: 'TRF', name: 'Transferencias Bancarias'},
    {id: 'AEF', name: 'Acreditaciones en Efectivo'},
];

export class Pendientes extends Component {
    state = {
        items: null,
        tmp_item: null,
        filter_tipo: 'todo',
        infoDialogOpen: false,
        confirmDialogOpened: false
    };

    UtilsService = new UtilsService(this.context);

    componentDidMount() {
        this.getRecepciones();
    }

    onChangeFilter = (field, value) => this.setState({[field]: value});

    getRecepciones = () => {
        this.UtilsService.getRecepcionesCobranza(
            {
                filter: 'PENDIENTES',
                sortBy: 'fecha',
                sortDir: 'ASC'
            },
            response => {
                this.setState({items: response.data.result}, this.setPendientes);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    setPendientes = () => this.props.setPendientes(this.state.items.filter(item => item.recibido !== true).length);

    onConfirmItem = (item) => {
        // const item = this.state.tmp_item;
        const index = this.state.tmp_index;

        this.UtilsService.updateRecepcionesCobranza(item,
            response => {
                this.setState(prevState => {
                    // prevState.items[index].recibido = true;
                    prevState.items.splice(index, 1);
                    return prevState;
                }, this.setPendientes);

                this.props.enqueueSnackbar("El ítem se confirmó correctamente", {variant: 'success'});
                this.closeConfirmDialog();
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    openConfirmDialog = (item, index) => this.setState({confirmDialogOpened: true, tmp_item: item, tmp_index: index});
    closeConfirmDialog = () => this.setState({confirmDialogOpened: false});

    onInfoPress = item => this.setState({tmp_item: item, infoDialogOpen: true})
    closeInfoDialog = () => this.setState({infoDialogOpen: false});

    filterItems = (items, tipo) => {
        if (items !== null && tipo !== 'todo')
            items = items.filter(item => item.tipo === tipo);

        return items;
    }

    render() {
        const {items, confirmDialogOpened, tmp_item, filter_tipo, infoDialogOpen} = this.state;
        let filteredItems = this.filterItems(items, filter_tipo);

        return (
            <Box className='container-xl mt-3 mb-3'>
                <Grid container spacing={2}>
                    <Grid item>
                        <TextField
                            select
                            label="Tipo"
                            className='mr-2'
                            value={filter_tipo}
                            onChange={event => this.onChangeFilter('filter_tipo', event.target.value)}
                        >
                            {TIPOS.map(tipo => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Box className={'mt-3'}>
                    <ItemList
                        items={filteredItems}
                        type={'PENDIENTE'}
                        noResultsText={'No hay items pendientes'}
                        onPrimaryAction={this.openConfirmDialog}
                        // onSecondaryAction={this.openConfirmDialog}
                        onInfoPress={this.onInfoPress}
                    />
                </Box>
                <ConfirmDialog
                    open={confirmDialogOpened}
                    item={tmp_item}
                    onClose={this.closeConfirmDialog}
                    onConfirm={this.onConfirmItem}
                />
                <ItemInfoDialog
                    open={infoDialogOpen}
                    item={tmp_item}
                    onClose={this.closeInfoDialog}
                />
            </Box>
        )
    }
}

Pendientes.contextType = MainContext;
Pendientes = withSnackbar(Pendientes);
