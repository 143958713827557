import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography} from '@material-ui/core';
import {formatPrice} from "assets/utils";
import {MainContext} from "contexts/main.context";

class ItemInfoDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                maxWidth={"sm"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ItemInfoDialogContent {...this.props}/>
            </Dialog>
        );
    }
}

const Item = props => (
    <Grid item>
        <Typography variant={"caption"} color={"textSecondary"} align={props.align}>
            {props.title}
        </Typography>
        <Typography variant={"body1"}>
            {props.text}
        </Typography>
    </Grid>
);

class ItemInfoDialogContent extends React.Component {
    state = {};

    render() {
        const {onClose, item} = this.props;

        return (
            <React.Fragment>
                <DialogTitle>Detalles</DialogTitle>
                <DialogContent>
                    <div className={'border-bottom w-50 pb-2 mb-2'}>
                        <Typography variant={"caption"} color={"textSecondary"}>{'Cobranza Asociada'}</Typography>
                    </div>
                    <Grid container spacing={1} justify={"space-between"} className={'mt-1'}>
                        <Item title={'ID Cobranza'} text={item.idrecibo}/>
                        <Item title={'Núm Recibo'} text={item.numrec}/>
                        <Item title={'Ingreso'} text={item.ingreso}/>
                        <Item title={'Descuento'} text={`${item.pordesc}% ${item.descfijo ? '(fijo)' : ''}`}/>
                        <Item title={'Días (ponde)'} text={item.dpromitems}/>
                    </Grid>

                    <div className={'border-bottom w-50 mt-4 pb-2 mb-2'}>
                        <Typography variant={"caption"} color={"textSecondary"}>{item.tipo_desc}</Typography>
                    </div>
                    <Grid container spacing={3} className={'mt-1'}>
                        <Item title={'Fecha Ítem'} text={item.fecha} align={'right'}/>
                        <Item title={'Importe'} text={formatPrice(item.importe, true)} align={'right'}/>
                    </Grid>

                    {item.tipo === 'TRF' && (
                        <Grid container spacing={3} className={'mt-1'}>
                            <Item title={'N° Transfe'} text={item.numero}/>
                            <Item title={'Cuenta'} text={item.cuenta_desc}/>
                        </Grid>
                    )}
                    {item.tipo === 'CEL' && (
                        <Grid container spacing={3} className={'mt-1'}>
                            <Item title={'N° Cheque'} text={item.numero}/>
                            <Item title={'Banco'} text={item.banco_desc}/>
                        </Grid>
                    )}

                    <Grid container spacing={3} className={'mt-4'}>
                        <Item title={'Cliente'} text={item.cliente}/>
                    </Grid>

                    <Grid container spacing={3} className={'mt-1'}>
                        <Item title={'Vendedor'} text={item.vendedor}/>
                    </Grid>

                    <Divider className={'mt-4 mb-3'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}

ItemInfoDialogContent.contextType = MainContext;
export default ItemInfoDialog;
