import React, {Component} from 'react'
import {Box, Tooltip} from "@material-ui/core/index";
import {ReactGrid} from '../../../helpers/reactGrid/reactGrid';
import AddIcon from '@material-ui/icons/Add';
import ClientesService from "services/clientes.service";
import CotizacionesService from "services/cotizaciones.service";
import {withSnackbar} from "notistack";
import {sortDate, sortNumeric} from 'assets/utils';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import CopyCotizacionIcon from '@material-ui/icons/FilterNoneRounded';
import {CotizacionReferenciaDialog} from "components/helpers/dialogs/cotizacionReferencia.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

export class CotizacionesList extends Component {
    state = {
        cliente: {},
        columns: [
            {name: 'id', title: 'ID Cotiza'},
            {name: 'ingreso', title: 'Ingreso'},
            {name: 'entrega', title: 'Entrega'},
            {name: 'importe_bonificado', title: 'Importe'},
            // {name: 'edit', title: 'Edit'},
        ],
        columnExtensions: [
            {columnName: 'id', width: 120, compare: sortNumeric, direction: 'desc'},
            {columnName: 'ingreso', compare: sortDate},
            {columnName: 'entrega', compare: sortDate},
            {columnName: 'importe_bonificado', type: 'currency', compare: sortNumeric},
        ],
        rows: [],
        dialOpen: false,
        cotizacionReferenciaDialogOpen: false,
        cotizaciones: []
    };

    ClientesService = new ClientesService(this.context);
    CotizacionesService = new CotizacionesService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => this.setState({cliente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    navEdit = (row) => {
        this.props.history.push(`${this.props.match.url}/edit/${row.id}`);
    };

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/view/${row.id}`);
    };

    navCreate = () => {
        this.props.history.push(`${this.props.match.url}/create`);
    };

    onCreateFromReferencia = cotizacion => {
        this.onCloseCotizacionRefenciaDialog();
        this.props.history.push(`${this.props.match.url}/create/ref/${cotizacion.id}`);
    };

    onOpenCotizacionRefenciaDialog = () => this.setState({cotizacionReferenciaDialogOpen: true});

    dialActions = [
        {icon: <AddIcon/>, name: 'Nueva Cotización', action: this.navCreate},
        {icon: <CopyCotizacionIcon/>, name: 'Nueva cotización a partir de una referencia', action: this.onOpenCotizacionRefenciaDialog},
    ];

    onCloseCotizacionRefenciaDialog = () => this.setState({cotizacionReferenciaDialogOpen: false});

    onDialClose = () => this.setState({dialOpen: false});
    onDialOpen = () => this.setState({dialOpen: true});

    render() {
        const {cliente, columns, columnExtensions, rows, dialOpen, cotizacionReferenciaDialogOpen} = this.state;

        return (
            <Box className='niquel-cotizaciones-list'>
                <SubHeader
                    title={'Cotizaciones'}
                    subtitle={`Cotizaciones Ingresadas`}
                    cliente={cliente}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={`Cotizaciones de ${cliente.razsoc} (${rows.length})`}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navView}
                    />
                    <Tooltip title={''} placement={"left-end"}>
                        <Box className='niquel-fab-btn'>
                            <SpeedDial
                                ariaLabel="crear_cotizacion"
                                icon={<SpeedDialIcon/>}
                                onBlur={this.onDialClose}
                                onClick={this.onDialOpen}
                                onClose={this.onDialClose}
                                onFocus={this.onDialOpen}
                                onMouseEnter={this.onDialOpen}
                                onMouseLeave={this.onDialClose}
                                open={dialOpen}
                            >
                                {this.dialActions.map(action => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            action.action()
                                        }}
                                    />
                                ))}
                            </SpeedDial>
                        </Box>
                    </Tooltip>
                </Box>
                <CotizacionReferenciaDialog
                    open={cotizacionReferenciaDialogOpen}
                    cotizaciones={rows}
                    cliente={cliente}
                    onConfirm={this.onCreateFromReferencia}
                    onCancel={this.onCloseCotizacionRefenciaDialog}
                />
            </Box>
        )
    }
}

CotizacionesList.contextType = MainContext;
CotizacionesList = withSnackbar(CotizacionesList);