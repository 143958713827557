import React, {useState} from 'react';
import {Button, IconButton, List, ListItem, ListItemText, Paper, Tooltip, Typography} from "@material-ui/core";
import {formatPrice} from "assets/utils";
import Grid from "@material-ui/core/Grid";
import ConfirmedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import {FixedSizeList} from "react-window";
import InfoIcon from "@material-ui/icons/InfoRounded";

export function ItemList(props) {
    const {
        items, type, loadingText = 'Cargando resultados', noResultsText = 'No se encontraron resultados', onPrimaryAction, onSecondaryAction,
        onInfoPress
    } = props;

    if (items === null)
        return (
            <div>
                <Typography
                    color={"textSecondary"}
                    align={"center"}
                >
                    {loadingText}
                </Typography>
            </div>
        )
    else if (items.length === 0)
        return (
            <div>
                <Typography
                    color={"textSecondary"}
                    align={"center"}
                >
                    {noResultsText}
                </Typography>
            </div>
        )
    else
        return (
            <FixedSizeList height={470} width={'100%'} itemSize={148.2} itemCount={items.length}>
                {({index, style}) => (
                    <div style={style}>
                        <Item
                            item={items[index]}
                            index={index}
                            type={type}
                            onPrimaryAction={onPrimaryAction}
                            onSecondaryAction={onSecondaryAction}
                            onInfoPress={onInfoPress}
                        />
                    </div>
                )}
            </FixedSizeList>
        )
}

export function Item(props) {
    const {item, index, onPrimaryAction, onSecondaryAction, onInfoPress} = props;
    // const [infoDialogOpen, setInfoDialogOpen] = useState(true);
    return (
        <>
            <Paper className={'mb-3'}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <List>
                            <ListItem>
                                {/*<ListItemIcon>*/}
                                {/*    <Checkbox*/}
                                {/*        disabled*/}
                                {/*        disableRipple*/}
                                {/*        edge="start"*/}
                                {/*        checked={false}*/}
                                {/*    />*/}
                                {/*</ListItemIcon>*/}
                                <Grid item>
                                    <Tooltip title={'Detalles del Item'}>
                                        <IconButton onClick={() => onInfoPress(item)}>
                                            <InfoIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <ListItemText
                                    primary={'Fecha'}
                                    secondary={item.fecha}
                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                    secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                    style={{
                                        flexGrow: '0',
                                        paddingRight: '2rem',
                                        minWidth: '7rem'
                                    }}
                                />
                                {/*Importe*/}
                                <ListItemText
                                    primary={'Importe'}
                                    secondary={formatPrice(item.importe, true)}
                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                    secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                    style={{
                                        flexGrow: '0',
                                        paddingRight: '2rem',
                                        minWidth: '7rem'
                                    }}
                                />
                                {item.tipo === 'TRF' && (
                                    <>
                                        {/*Numero*/}
                                        <ListItemText
                                            primary={'Número'}
                                            secondary={item.numero}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                minWidth: '7rem'
                                            }}
                                        />
                                        {/*Cuenta*/}
                                        <ListItemText
                                            primary={'Cuenta'}
                                            secondary={item.cuenta_desc}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                // width: '20rem'
                                            }}
                                        />
                                    </>
                                )}
                                {item.tipo === 'CEL' && (
                                    <>
                                        {/*Numero*/}
                                        <ListItemText
                                            primary={'N° Cheque'}
                                            secondary={item.numero}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                minWidth: '7rem'
                                            }}
                                        />
                                        {/*Banco*/}
                                        <ListItemText
                                            primary={'Banco'}
                                            secondary={item.banco_desc}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                maxWidth: '25rem'
                                            }}
                                        />
                                    </>
                                )}
                                {/*Razsoc*/}
                                <ListItemText
                                    primary={'Cliente'}
                                    secondary={item.cliente}
                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                    secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                    style={{
                                        flexGrow: '0',
                                        paddingRight: '20px',
                                        maxWidth: '25rem'
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    /!*Razsoc*!/*/}
                    {/*    <Typography variant={"caption"} color={"textSecondary"}>Cliente: </Typography>*/}
                    {/*    <Typography variant={"body2"} component={"span"}>{item.razsoc}</Typography>*/}
                    {/*</Grid>*/}
                    <Grid item>
                        <Grid container direction={"row"} alignItems={"center"} justify={"space-between"} className={'pt-1 pb-1 pl-3 pr-3 border-top border-light'}>
                            <div className={'d-flex align-items-center'}>
                                <Typography variant={"caption"} color={"textSecondary"}>{item.tipo_desc}</Typography>
                                {item.recibido === true && (
                                    <Tooltip title={'Item Confirmado'}>
                                        <ConfirmedIcon color={"primary"} className={'ml-2'}/>
                                    </Tooltip>
                                )}
                            </div>
                            <div>
                                {item.tipo === 'TRF' && onSecondaryAction && (
                                    <Button
                                        color={"primary"}
                                        className={'mr-2'}
                                        size={"small"}
                                        onClick={() => onSecondaryAction(item, index)}
                                    >
                                        Editar
                                    </Button>
                                )}
                                {item.recibido === false && (
                                    <Tooltip title={'Confirmar Recepción'}>
                                        <Button
                                            color={"primary"}
                                            onClick={() => onPrimaryAction(item, index)}>
                                            Confirmar
                                        </Button>
                                    </Tooltip>
                                )}
                                {item.recibido === true && (
                                    <Tooltip title={'Cancelar Recepción'}>
                                        <Button
                                            className={'text-danger'}
                                            onClick={() => onPrimaryAction(item, index)}>
                                            Cancelar
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
