import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core/index";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import SaveIcon from "@material-ui/icons/SaveAltRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import {IconButton, Tooltip} from "@material-ui/core";

export const ArbaDialog = ({open, result, dominio, onDownload, onClose}) => (
    <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'md'}
    >
        <DialogTitle style={{height: '4rem'}}>
            Respuesta de ARBA

            <Tooltip title={'Imprimir'}>
                <IconButton onClick={onDownload} className={'ml-2'}>
                    <PrintIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
        </DialogTitle>
        <DialogContent>
            {result !== null && (
                <>
                    <Typography variant="body2"><b>CUIT: </b>{result.cuitEmpresa}</Typography>
                    <Typography variant="body2"><b>Dominio: </b>{dominio}</Typography>
                    <Typography variant="body2"><b>Comprobante: </b>{result.numeroComprobante}</Typography>
                    <Typography variant="body2"><b>Archivo: </b>{result.nombreArchivo}</Typography>
                    <Typography variant="body2"><b>Código: </b>{result.codigoIntegridad}</Typography>

                    <Table size="small" className={'mt-3'}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Documento</TableCell>
                                <TableCell align="center">Procesado</TableCell>
                                <TableCell>COT</TableCell>
                                <TableCell>Mensaje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result.validacionesRemitos.remito.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.numeroUnico}</TableCell>
                                    <TableCell align="center">{row.procesado}</TableCell>
                                    <TableCell>{row.cot ? row.cot : '-'}</TableCell>
                                    <TableCell>{row.errores && row.errores.error ? row.errores.error.descripcion : ''}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>
);
