import BaseService from './base.service'

export default class AuthService extends BaseService {
    login(username, password, fnSuccess, fnError) {
        return this.getApi().post(`/auth/login`, {
            username: username,
            password: password
        })
            .then(response => {
                localStorage.setItem('JWT', response.data.token);
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    logout(fnCallback) {
        localStorage.removeItem('JWT');
        fnCallback();
    }

    getUrlToken(url, fnSuccess, fnError) {
        return this.getApi().get(`/auth/urlToken?url=${url}`)
            .then(response => {
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el URL token')));
    }

    /**
     * Chequea si el usuario está logueado verificando que exista el JWT y que no haya expirado.
     */
    static isLogged() {
        let JWT = localStorage.getItem('JWT');

        if (JWT) {
            let JWTPublicData = JSON.parse(atob(JWT.split('.')[1]));
            const expiration = new Date(JWTPublicData.exp * 1000);
            const today = new Date();

            if (today < expiration)
                return true;
        }

        return false;
    }

    static getUser() {
        let JWT = localStorage.getItem('JWT');

        if (this.isLogged())
            return JSON.parse(atob(JWT.split('.')[1]));
        return null;
    }
}