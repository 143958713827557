import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {AfipMain} from 'components/ventas/afip/main/afip.main';
import {AfipCot} from 'components/ventas/afip/cot/afip.cot';

const basePath = '/ventas/AFIP';

export class Afip extends Component {
    render() {
        return (
            <div className='niquel-afip'>
                <Switch>
                    <Route path={`${basePath}/COT`} component={AfipCot}/>
                    <Route path={`${basePath}`} component={AfipMain}/>
                </Switch>
            </div>
        )
    }
}
