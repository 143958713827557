import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {MastersService} from "services";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";

export class EquivalenciasFiltros extends Component {
    state = {
        columns: [
            {name: 'bosch', title: 'Bosch', width: 150},
            {name: 'fram', title: 'Fram', width: 160},
            {name: 'mann', title: 'Mann', width: 150},
            {name: 'mahle', title: 'Mahle', width: 150},
            {name: 'reemplazo', title: 'Reemplazo'},
        ],
        rows: []
    };

    MastersService = new MastersService(this.context);

    componentDidMount() {
        this.MastersService.getEquivalenciasFiltros(
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    render() {
        const {columns, rows} = this.state;
        return (
            <Box className='niquel-equivalencias-filtros'>
                <SubHeader
                    title={'Equivalencias de Filtros'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        columns={columns}
                        rows={rows}
                        title={'Equivalencias'}
                        enableFiltering={true}
                        enableSorting={true}
                        enableSearch={true}
                        enableSelection={false}
                    />
                </Box>
            </Box>
        )
    }
}

EquivalenciasFiltros.contextType = MainContext;
EquivalenciasFiltros = withSnackbar(EquivalenciasFiltros);
