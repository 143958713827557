import React, {Component} from 'react';
import {Box} from "@material-ui/core";
import {EmailService, ListadosService, ProveedoresService} from 'services';
import {withSnackbar} from "notistack";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {MainContext} from "contexts/main.context";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";

export class ListaProveedor extends Component {
    state = {
        columns: [
            {name: 'id', title: 'Id', width: 150},
            {name: 'apodo', title: 'Apodo'},
            {name: 'razsoc', title: 'Razón Social'}
        ],
        rows: [],
        selected: [],
        all: true,
        dialOpen: false
    };

    ProveedoresService = new ProveedoresService(this.context);
    ListadosService = new ListadosService(this.context);
    EmailService = new EmailService(this.context);

    componentDidMount() {
        this.ProveedoresService.getAll(
            'XX',
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onChangeSwitchAll = () => this.setState(prevState => (prevState.all = !prevState.all));

    onSelect = selected => {
        if (selected.length <= 23)
            this.setState({
                selected: selected
            });
        else
            this.props.enqueueSnackbar('Solo puede seleccionar hasta 23 proveedores.');
    };

    getPDF = (fnSuccess = () => {
    }) => {
        let proveedores = [];
        if (this.state.all === false)
            proveedores = this.state.rows.filter((proveedor, index) => this.state.selected.indexOf(index) !== -1);
        this.ListadosService.listaPrecios(
            'listaProveedor',
            proveedores,
            file => this.setState({pdfBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
            }
        );
    };

    // Print PDF
    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Lista de Precios`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.');
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    render() {
        const {columns, rows, selected, all, dialOpen, mailDialogOpen, pdfDialogOpen, pdfUrl} = this.state;
        return (
            <React.Fragment>
                <ReactGrid
                    title={'Proveedores'}
                    columns={columns}
                    rows={rows}
                    //width={'40rem'}
                    height={450}
                    enableFiltering={false}
                    enableSelection={true}
                    selectedRows={selected}
                    onSelectionChange={this.onSelect}
                    onDelete={selected => this.onSelect([])}
                    showSelectAll={false}
                    showSwitchAll={true}
                    switchAllValue={all}
                    onChangeSwitchAll={() => this.onChangeSwitchAll()}
                />
                <Box className='niquel-fab-btn'>
                    <SpeedDial
                        ariaLabel="lista_precio_grupo"
                        icon={<PDFIcon/>}
                        onBlur={this.onDialClose}
                        onClick={this.onDialOpen}
                        onClose={this.onDialClose}
                        onFocus={this.onDialOpen}
                        onMouseEnter={this.onDialOpen}
                        onMouseLeave={this.onDialClose}
                        open={dialOpen}
                    >
                        {this.dialActions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (all === false && selected.length < 1)
                                        this.props.enqueueSnackbar('Debe seleccionar al menos un proveedor');
                                    else
                                        action.action()
                                }}
                            />
                        ))}
                    </SpeedDial>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`Lista_de_Precios.pdf`}
                    attachFile={true}
                    // to={cliente.email}
                    subject={`Lista Precio`}
                    body={`Estimado,  \n\nSe adjunta la lista de precios.`}
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`Lista_de_Precios.pdf`}
                />
            </React.Fragment>
        )
    }
}

ListaProveedor.contextType = MainContext;
ListaProveedor = withSnackbar(ListaProveedor);
