import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Toolbar, Typography, withMobileDialog, withStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/ArrowBack";
import ChequeEditFields from "components/helpers/cobranzas/items/cheque.edit.fields";
import MomentUtils from "@date-io/moment";
import {withSnackbar} from "notistack";
import UtilsService from "services/utils.service";
import {MainContext} from "contexts/main.context";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export class ChequeAddDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                fullScreen={this.props.fullScreen}
                TransitionComponent={this.props.fullScreen ? Transition : undefined}
                fullWidth
                maxWidth={"sm"}
                classes={{paperFullScreen: 'niquel-dialog-full-screen'}}
            >
                <ChequeAddDialogContent {...this.props} />
            </Dialog>
        )
    }
}

class ChequeAddDialogContent extends React.Component {
    state = {
        iselectro: false,
        depositado: false,
        numero: '0',
        idelectro: '',
        banco: this.props.bancos[0],
        fecha: (new MomentUtils()).date(new Date()),
        importe: 0,
        isValid: false,
    };

    UtilsService = new UtilsService(this.context);

    onClose = () => this.props.onClose();
    onConfirm = () => {
        this.checkNotRepeated()
            .then(() => this.props.onConfirm(this.state))
            .catch(() => this.props.enqueueSnackbar("Este cheque ya fue ingresado anteriormente, verifique el número.", {variant: 'error'}))
    };
    onEditCheque = (cheque) => this.setState({...cheque}, this.validateData);

    validateData = () => {
        const importe = this.state.importe;
        const numero = this.state.numero;

        if (isNaN(parseFloat(importe)) || parseFloat(importe) <= 0) {
            this.setState({isValid: false});
            return;
        }

        if (isNaN(parseInt(numero)) || parseInt(numero) <= 0) {
            this.setState({isValid: false});
            return;
        }

        this.setState({isValid: true});
    };

    checkNotRepeated = () => new Promise((resolve, reject) => {
        const added = this.props.cheques.some(cheque => parseInt(cheque.numero) === parseInt(this.state.numero));
        if (added) {
            reject('NotValid');
            return;
        }

        if (typeof this.props._deleted_cheques !== "undefined") {
            const deleted = this.props._deleted_cheques.some(cheque => parseInt(cheque.numero) === parseInt(this.state.numero));
            if (deleted) {
                resolve('Valid');
                return;
            }
        }

        this.UtilsService.chequeIsValid(
            this.state.numero,
            (response) => {
                if (response.data.result === true)
                    resolve('Valid');
                else
                    reject('NotValid');
            },
            () => this.props.enqueueSnackbar("Error al validar cheque", {variant: 'error'})
        )
    })

    render() {
        const {fullScreen, bancos} = this.props;
        const cheque = this.state;

        return (
            <React.Fragment>
                {fullScreen && (
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.onClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6">
                                Agregar cheque
                            </Typography>
                        </Toolbar>
                    </AppBar>
                )}
                {!fullScreen && (
                    <DialogTitle>Agregar cheque</DialogTitle>
                )}
                <DialogContent className={fullScreen ? 'mt-7' : ''}>
                    <ChequeEditFields
                        cheque={cheque}
                        bancos={bancos}
                        onEdit={this.onEditCheque}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={this.onConfirm}
                        color="primary"
                        autoFocus
                        disabled={!cheque || cheque.isValid === false}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

ChequeAddDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

ChequeAddDialogContent.contextType = MainContext;
ChequeAddDialog = withSnackbar(ChequeAddDialog);
export default withMobileDialog()(withStyles({}, {withTheme: true})(ChequeAddDialog));
