import React, {useState} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVertRounded';
import ViewIcon from '@material-ui/icons/VisibilityRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import ChangeStatusIcon from '@material-ui/icons/LabelRounded';
import NotificationIcon from '@material-ui/icons/AddAlertRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import {IconButton, Tooltip} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

export const OptionsMenu = (props) => {
    const {row, rowId} = props;
    const {onView, onEdit, onNotification, onChangeStatus, onDelete} = props;
    const {loggedUser} = props.context;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        if (!!e)
            e.stopPropagation();
        setAnchorEl(null);
    };

    const handleOption = (e, fnAction) => {
        e.stopPropagation();
        fnAction(row, rowId);
        handleClose();
    }

    const Item = React.forwardRef((props, ref) => {
        const Icon = props.icon;
        return (
            <MenuItem ref={ref} onClick={(e) => handleOption(e, props.fn)}>
                <Icon fontSize={"small"} color={props.color ? props.color : "action"}/>
                <Typography variant="body2" noWrap color={props.color} className={'ml-2'}>
                    {props.title}
                </Typography>
            </MenuItem>
        )
    })

    return (
        <>
            <Tooltip title={'Opciones'}>
                <IconButton aria-controls="fade-menu" aria-haspopup="true" onClick={handleOpen}>
                    <MoreVertIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <Item ref={React.createRef()} fn={onView} icon={ViewIcon} title={"Visualizar"}/>
                {row.status !== 'I' && (
                    <Item fn={onEdit} icon={EditIcon} title={"Editar"}/>
                )}
                {loggedUser.role === "A" && (
                    [
                        <Item key={1} fn={onNotification} icon={NotificationIcon} title={"Notificar al vendedor"}/>,
                        <Item key={2} fn={onChangeStatus} icon={ChangeStatusIcon} title={"Modificar estado"}/>,
                        <Item key={3} fn={onDelete} icon={DeleteIcon} color={"error"} title={"Eliminar"}/>
                    ]
                )}
            </Menu>
        </>
    )
};
