import React, {Component} from 'react'
import {ArticulosService, ClientesService, CotizacionesService, MastersService, VendedoresService} from "services";
import {MainContext} from "contexts/main.context";
import {Box, Fab, Tooltip} from "@material-ui/core";
import {ConfirmationDialog} from './dialogs/confirm.dialog';
import {CotizacionesOperation} from 'components/ventas/cotizaciones/operation/cotizaciones.operation';
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import {cloneArray, regExpPercentage} from "assets/utils.js";
import {withSnackbar} from "notistack";
import DoneIcon from '@material-ui/icons/Done';
import {calcImporteByArticulo} from "assets/utils";

export class CotizacionesCreate extends Component {
    state = {
        cliente: {},
        condComerciales: [],
        vendedores: [],
        articulos: [],
        bonificacionesLitros: [],
        headerData: {
            bonificacion: 0,
            listaprecio: '1',
            condcomercial: '',
            notacompra: '',
            usuario: this.context.loggedUser.name,
            vendedor: this.context.loggedUser.id,
            ingreso: (new MomentUtils()).date(new Date()),
            entrega: (new MomentUtils()).date(new Date()),
            leyenda1: 'Validez de la oferta: 48 horas de fecha de cotización',
            leyenda2: '',
            leyenda3: '',
            leyenda4: '',
        },
        selectedArticulos: [],
        generatedId: '',
        totalArticulos: 0,
        totalLitros: 0,
        importe: 0,
        maxArticulos: 0,
        proveedorShellId: null,
        confirmationDialogOpen: false,
        successDialogOpen: false,
    };

    CotizacionesService = new CotizacionesService(this.context);
    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    ArticulosService = new ArticulosService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => {
                this.setState(prevState => {
                    prevState.cliente = response.data.result;
                    prevState.headerData.bonificacion = response.data.result.bonificacion;
                    prevState.headerData.listaprecio = response.data.result.listaprecio;
                    prevState.headerData.condcomercial = response.data.result.condcomercial || '1';
                    prevState.headerData.vendedor = response.data.result.vendedor;
                    return prevState;
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ArticulosService.getAll(
            this.props.match.params.cliente,
            response => {
                this.setState({articulos: response.data.result});
                // Para calcular la cotización de referencia (si existe) necesito los artículos.
                if (this.props.match.params.cotizacion)
                    this.loadCotizacionReferencia(this.props.match.params.cotizacion);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getMaxLength(
            response => this.setState({maxArticulos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getBonificacionesByRangoLitros(
            response => this.setState({bonificacionesLitros: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    loadCotizacionReferencia = (idCotizacion) => {
        this.CotizacionesService.getById(
            idCotizacion,
            response => {
                const cotizacion = response.data.result;

                // Muestro mensaje avisando que se está copiando una cotización de referencia
                const message = `ATENCIÓN: Está generando una nueva cotización a partir de la ${idCotizacion} del ${cotizacion.ingreso}. Los precios de los artículos fueron actualizados a los valores del día y fueron removidos aquellos que ya no están disponibles.`;
                this.props.enqueueSnackbar(message, {variant: 'info', autoHideDuration: 10000});

                this.setState(prevState => {
                    prevState.headerData = {
                        ...prevState.headerData,
                        ...cotizacion,
                        // Fix campos (no deben copiarse de la cotización de referencia)
                        usuario: this.context.loggedUser.name,
                        ingreso: (new MomentUtils()).date(new Date()),
                        entrega: (new MomentUtils()).date(new Date()),
                    };
                    prevState.importe = parseFloat(cotizacion.importe);
                    return prevState;
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getArticulosById(
            idCotizacion,
            response => {
                let selectedArticulos = [];
                let articulos = response.data.result;
                articulos.forEach(articulo => {
                    const _originalArticulo = this.state.articulos.find(_articulo => _articulo.id === articulo.id);
                    if (!!_originalArticulo) {
                        let _updatedArticulo = {..._originalArticulo};
                        _updatedArticulo.precio = _originalArticulo.lista1;
                        _updatedArticulo.detalle = articulo.detalle;
                        _updatedArticulo.cantidad = articulo.cantidad;
                        _updatedArticulo.bonificacion = articulo.bonificacion;
                        _updatedArticulo.importe = calcImporteByArticulo(_updatedArticulo.cantidad, _updatedArticulo.precio, _updatedArticulo.bonificacion);
                        selectedArticulos.push(_updatedArticulo);
                    }
                });
                this.setState({
                    selectedArticulos: selectedArticulos
                }, this._updateArticulos(selectedArticulos));
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    onCreate = () => {
        if (this.validateData())
            this.onOpenConfirmationDialog();
    };

    validateData = () => {
        // let data = {...this.state.headerData};
        return this._validateArticulos() !== false;
    };

    onConfirm = () => {
        const cliente = this.state.cliente.id;
        let data = {...this.state.headerData};

        data.ingreso = data.ingreso.format('DD/MM/Y');
        data.entrega = data.entrega.format('DD/MM/Y');
        data.importe = this.state.importe;
        data.articulos = this.state.selectedArticulos;

        this.CotizacionesService.create(
            cliente,
            data,
            response => {
                this.setState({generatedId: response.data.id}, () => {
                    this.props.enqueueSnackbar(`Se generó correctamente la cotización número ${response.data.id}`, {variant: 'success'});
                    this.props.history.push(`/ventas/cotizaciones/${this.props.match.params.cliente}`);
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    onChangeHeaderData = (field, value) => {
        if (field === 'bonificacion')
            value = regExpPercentage.test(value) ? value : this.state.headerData.bonificacion;

        this.setState(prevState => {
            prevState.headerData[field] = value;
            return prevState;
        });
    };

    onAddArticulos = (articulos, fnSuccess) => {
        // Valido que no quiera ingresar más artículos que los permitidos.
        if ((this.state.selectedArticulos.length + articulos.length) > this.state.maxArticulos) {
            this.props.enqueueSnackbar(`No puede ingresar más de ${this.state.maxArticulos} artículos en una cotización.`, {variant: 'error'});
            return;
        }

        // Valido que los artículos no estén mezclados entre shell y otros proveedores.
        // let aIsShell = new Set();
        // if (this.state.selectedArticulos.length > 0) aIsShell.add(this.state.selectedArticulos[0].is_shell);
        // articulos.forEach(articulo => aIsShell.add(articulo.is_shell));
        //
        // if (aIsShell.size > 1) {
        //     this.props.enqueueSnackbar(`No puede ingresar artículos de Shell y de otros proveedores en una misma cotización`);
        //     return;
        // }

        // Valido que los artículos no tengan distintas alícuotas de IVA.
        let aIVA = new Set();
        if (this.state.selectedArticulos.length > 0) aIVA.add(this.state.selectedArticulos[0].ivavta);
        articulos.forEach(articulo => aIVA.add(articulo.ivavta));

        if (aIVA.size > 1) {
            this.props.enqueueSnackbar(`No puede ingresar artículos con distintas alícuotas de IVA en un mismo pedido`, {variant: 'error'});
            return;
        }

        for (var i in articulos) {
            const articulo = articulos[i];
            if (this._validateArticulo(articulo) === false)
                return;
        }

        articulos = this.state.selectedArticulos.concat(articulos);
        this._updateArticulos(articulos, fnSuccess);
    };

    onEditArticulo = (articulo, fnSuccess) => {
        if (this._validateArticulo(articulo)) {
            const articulos = this.state.selectedArticulos.map(_articulo => _articulo.key === articulo.key ? articulo : _articulo);
            this._updateArticulos(articulos, fnSuccess);
        }
    };

    onRemoveArticulos = articulos => {
        articulos = this.state.selectedArticulos.filter(articulo => articulos.indexOf(articulo.key) === -1);
        this._updateArticulos(articulos);
    };

    onOpenConfirmationDialog = () => this.setState({confirmationDialogOpen: true});
    onCloseConfirmationDialog = () => this.setState({confirmationDialogOpen: false});

    _validateArticulos = () => {
        const articulos = this.state.selectedArticulos;
        for (var i in articulos) {
            const articulo = articulos[i];
            if (this._validateArticulo(articulo) === false)
                return false;
        }
    };

    _validateArticulo = articulo => {
        // Caluclo el descuento máximo permitido por escala de litros para los artículos de Shell.
        let bonificacionMaxima = 0;
        const totalLitros = this.state.totalLitros + (articulo.cantidad * articulo.unidad);
        this.state.bonificacionesLitros.forEach(bonificacion => {
            if (totalLitros > bonificacion.rango.inicio)
                bonificacionMaxima = bonificacion.bonificacion;
        });

        // Si el artículo es de shell valido que la bonificación ingresada no supere la permitida según escala.
        if (articulo.is_shell)
            if (parseInt(articulo.bonificacion) > parseInt(bonificacionMaxima)) {
                this.props.enqueueSnackbar(`El artículo ${articulo.id} es de Shell, la bonificación máxima permitida está restringida a ${bonificacionMaxima}%.`, {variant: 'error'});
                return false;
            }

        // Si el usuario no es Administrador sólo puede ingresar bonificaciones hasta 10%.
        if (articulo.bonificacion > 10 && isNaN(parseFloat(articulo.bonificacion_cliente)) && this.context.loggedUser.role !== 'A') {
            this.props.enqueueSnackbar(`La bonificación del artículo ${articulo.id} no puede ser mayor a 10%.`, {variant: 'error'});
            return false;
        }

        // Valido que los valores del artículo no afecten la renta mínima:
        // articulo.importe contempla la suma bonificada * la cantidad pedida. El cálculo de renta mínima debe realizarse sobre el valor unitario
        // del artículo bonificado por unidad y por bonificacion general del cliente menos el costo neto.
        // Este valor final debe ser mayor al porcentaje de renta minima definido por articulo.rentaminima.
        const importeArticulo = articulo.importe / articulo.cantidad;
        const importeBonificado = importeArticulo - (importeArticulo * this.state.headerData.bonificacion) / 100;
        const rentabilidad = ((importeBonificado - articulo.costoneto) / articulo.costoneto) * 100;
        if (rentabilidad < articulo.rentaminima) {
            if (this.context.loggedUser.role !== 'A') {
                this.props.enqueueSnackbar(`El artículo ${articulo.id} no cumple con la rentabilidad mínima esperada. Revise su precio y bonificación unitarios o la bonificación general del cliente.`, {variant: 'error'});
                return false;
            } else
                this.props.enqueueSnackbar(`El artículo ${articulo.id} no cumple con la rentabilidad mínima esperada. Revise su precio y bonificación unitarios o la bonificación general del cliente.`, {variant: 'warning'});
        }

        return true;
    };

    _updateArticulos = (articulos, fnSuccess = () => {
    }) => this.setState(prevState => {
        prevState.selectedArticulos = cloneArray(articulos);
        prevState.totalArticulos = articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0);
        prevState.totalLitros = articulos.reduce((count, articulo) => (count + (parseInt(articulo.cantidad) * parseFloat(articulo.unidad))), 0);
        prevState.importe = this._calculateImporte(articulos);
        return prevState;
    }, fnSuccess);

    _calculateImporte = (articulos) => {
        let importeTotal = 0;
        articulos.forEach(articulo => {
            let importe = isNaN(parseFloat(articulo.importe)) ? 0 : parseFloat(articulo.importe);
            importeTotal += importe;
        });

        return importeTotal;
    };

    render() {
        const {
            cliente, condComerciales, vendedores, articulos, bonificacionesLitros, headerData, selectedArticulos, generatedId,
            importe, maxArticulos, confirmationDialogOpen, totalArticulos, totalLitros
        } = this.state;

        const importeBonificado = importe - importe * headerData.bonificacion / 100;

        return (
            <Box className='niquel-cotizaciones-create'>
                <CotizacionesOperation
                    operation='CREATE'
                    cliente={cliente}
                    condComerciales={condComerciales}
                    vendedores={vendedores}
                    bonificacionesLitros={bonificacionesLitros}
                    articulos={articulos}
                    headerData={headerData}
                    selectedArticulos={selectedArticulos}
                    generatedId={generatedId}
                    importe={importe}
                    totalArticulos={totalArticulos}
                    totalLitros={totalLitros}
                    maxArticulos={maxArticulos}
                    importeBonificado={importeBonificado}
                    onChangeHeaderData={this.onChangeHeaderData}
                    onAddArticulos={this.onAddArticulos}
                    onAddFastArticulos={this.onAddArticulos}
                    onEditArticulo={this.onEditArticulo}
                    onRemoveArticulos={this.onRemoveArticulos}
                />

                <Box className='niquel-fab-btn'>
                    <Tooltip title={selectedArticulos.length < 1 ? '' : 'Generar Cotización'}>
                        <Fab color="primary" aria-label="Add" disabled={selectedArticulos.length < 1} onClick={this.onCreate}>
                            <DoneIcon/>
                        </Fab>
                    </Tooltip>
                </Box>

                <ConfirmationDialog
                    open={confirmationDialogOpen}
                    importe={importeBonificado}
                    cliente={cliente}
                    onConfirm={() => {
                        this.onCloseConfirmationDialog();
                        this.onConfirm();
                    }}
                    onCancel={this.onCloseConfirmationDialog}
                />
            </Box>
        )
    }
}

CotizacionesCreate.contextType = MainContext;
CotizacionesCreate = withSnackbar(CotizacionesCreate);
