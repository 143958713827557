import React, {Component} from 'react';
import {UtilsService} from 'services';
import {ItemList} from "./item";
import MomentUtils from "@date-io/moment";
import {Box, Grid, MenuItem, TextField} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import ItemInfoDialog from "components/ventas/recepciones/cobranzas/dialogs/item.info.dialog";

const TIPOS = [
    {id: 'todo', name: 'Todo'},
    {id: 'CEL', name: 'Cheques Electrónicos'},
    {id: 'TRF', name: 'Transferencias Bancarias'},
    {id: 'AEF', name: 'Acreditaciones en Efectivo'},
];

export class Recibidos extends Component {
    state = {
        items: null,
        fechaDesde: (new MomentUtils()).date(new Date()).subtract(2, "month"),
        fechaHasta: (new MomentUtils()).date(new Date()),
        filter_tipo: 'todo',
        tmp_item: null,
        infoDialogOpen: false
    };

    UtilsService = new UtilsService(this.context);

    componentDidMount() {
        this.getRecepciones();
    }

    getRecepciones = () => {
        this.UtilsService.getRecepcionesCobranza(
            {
                filter: 'RECIBIDOS',
                desde: this.state.fechaDesde.format('DD/MM/YY'),
                hasta: this.state.fechaHasta.format('DD/MM/YY'),
                sortBy: 'fecha',
                sortDir: 'desc'
            },
            response => {
                this.setState({items: response.data.result});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
        ;
    }

    onCancelItem = (item, index) => {
        // Anulo la recepción
        item.anular = true;
        this.UtilsService.updateRecepcionesCobranza(item,
            response => {
                this.setState(prevState => {
                    prevState.items.splice(index, 1);
                    return prevState;
                });
                this.props.enqueueSnackbar("El ítem se marcó como NO RECIBIDO", {variant: 'success'})
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    onChangeDate = (field, value) => this.setState({[field]: value}, this.getRecepciones);
    onChangeFilter = (field, value) => this.setState({[field]: value});

    onInfoPress = item => this.setState({tmp_item: item, infoDialogOpen: true})
    closeInfoDialog = () => this.setState({infoDialogOpen: false});

    filterItems = (items, tipo) => {
        if (items !== null && tipo !== 'todo')
            items = items.filter(item => item.tipo === tipo);

        return items;
    }

    render() {
        const {items, fechaDesde, fechaHasta, tmp_item, filter_tipo, infoDialogOpen} = this.state;
        let filteredItems = this.filterItems(items, filter_tipo);

        return (
            <Box className='container-xl mt-3 mb-3'>
                <Grid container spacing={2}>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                autoOk
                                className={'niquel-date-input'}
                                label="Desde"
                                format="DD/MM/Y"
                                value={fechaDesde}
                                onChange={moment => this.onChangeDate('fechaDesde', moment)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                autoOk
                                className={'niquel-date-input'}
                                label="Hasta"
                                format="DD/MM/Y"
                                value={fechaHasta}
                                onChange={moment => this.onChangeDate('fechaHasta', moment)}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            label="Tipo"
                            className='mr-2'
                            value={filter_tipo}
                            onChange={event => this.onChangeFilter('filter_tipo', event.target.value)}
                        >
                            {TIPOS.map(tipo => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Box className={'mt-3'}>
                    <ItemList
                        items={filteredItems}
                        type={'RECIBIDO'}
                        onPrimaryAction={this.onCancelItem}
                        onInfoPress={this.onInfoPress}
                    />
                </Box>
                <ItemInfoDialog
                    open={infoDialogOpen}
                    item={tmp_item}
                    onClose={this.closeInfoDialog}
                />
            </Box>
        )
    }
}

Recibidos.contextType = MainContext;
Recibidos = withSnackbar(Recibidos);
