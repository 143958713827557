import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import NumberFormat from "react-number-format";
import React from "react";
import PendienteIcon from "@material-ui/icons/HistoryRounded";
import ObservadaIcon from "@material-ui/icons/HighlightOffRounded";
import CheckIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import {Tooltip, Typography} from "@material-ui/core";

export const regExpInteger = /^$|^[0-9]+$/;
export const regExpPercentage = /^$|^([0-9]{0,2})(\.[0-9]{0,2})?$|^-[0-9]{0,2}$|^100$|^-100$/;
export const regExpPrice = /^$|^[0-9]+(\.[0-9]{0,2})?$/;
export const regExpEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const calcImporteByArticulo = (cantidad = 1, precio = 0, bonificacion = 0) => {
    cantidad = (!isNaN(parseInt(cantidad))) ? parseInt(cantidad) : 0;
    // Redondeo a 2 decimales para evitar problemas con antidades altas.
    // Notado el 11/12/2019 por Hugo
    precio = (!isNaN(parseFloat(precio))) ? Math.round(parseFloat(precio) * 100) / 100 : 0;
    bonificacion = (!isNaN(parseFloat(bonificacion))) ? parseFloat(bonificacion) : 0;

    let importe = cantidad * precio;
    importe = importe - (importe * bonificacion / 100);
    importe = isNaN(importe) ? 0 : parseFloat(importe.toFixed(2));
    return importe;
};

export const formatPrice = (number, usePrefix = false, prefix = '$') => {
    let price = '-';

    if (number === '')
        number = 0;

    number = parseFloat(number);

    if (!isNaN(number)) {
        price = number
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

        if (usePrefix)
            price = prefix + price;
    }

    // Quito los decimales si son 0
    let aPrice = price.split(',');
    price = (aPrice[1] === '00') ? aPrice[0] : aPrice[0] + ',' + aPrice[1];

    return price;
};

export const formatQuantity = number => {
    let quantity = '-';

    if (number === '')
        number = 0;

    number = parseInt(number);

    if (!isNaN(number)) {
        quantity = number
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

        return quantity;
    }
};

export const sortNumeric = (number1, number2) => {
    number1 = parseFloat(number1);
    number2 = parseFloat(number2);
    return (number1 === number2) ? 0 : (number1 < number2) ? -1 : 1;
};

export const sortDate = (date1, date2) => {
    date1 = (new MomentUtils()).parse(date1, 'D/MM/Y');
    date2 = (new MomentUtils()).parse(date2, 'D/MM/Y');
    return (date1 === date2) ? 0 : (date1 < date2) ? -1 : 1;
};

export const toggleFullScreen = () => isFullScreen() ? exitFullScreen() : requestFullScreen();

const isFullScreen = () => (document.fullScreenElement || document.mozFullScreen || document.webkitIsFullScreen);

export const requestFullScreen = () => {
    const element = document.documentElement;
    if (element.requestFullscreen)
        element.requestFullscreen();
    else if (element.msRequestFullscreen)
        element.msRequestFullscreen();
    else if (element.mozRequestFullScreen)
        element.mozRequestFullScreen();
    else if (element.webkitRequestFullscreen)
        element.webkitRequestFullscreen();
};

const exitFullScreen = () => {
    if (document.exitFullscreen)
        document.exitFullscreen();
    else if (document.msExitFullscreen)
        document.msExitFullscreen();
    else if (document.mozCancelFullScreen)
        document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen)
        document.webkitExitFullscreen();
};

export const cloneArray = (dataArray) => {
    let newData = [];
    dataArray.forEach((value) => {
        newData.push({...value})
    });
    return newData;
};

export function InputInteger(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            defaultValue={0}
            isNumericString
        />
    );
}

export function InputPrice(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            isNumericString
            prefix="$"
        />
    );
}

export function InputPercentage(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            decimalScale={2}
            allowNegative={false}
            isNumericString
            suffix="%"
            isAllowed={(values => values.value === '' || values.floatValue <= 100)}
        />
    );
}

export const roundPrice = (price) => {
    price = parseFloat(price);
    price = Math.round(price * 100) / 100;
    return (Math.abs(price) < 0.05) ? 0 : price;
}

export function getCobranzaStatusInfo(status) {
    let ret = {
        description: "Pendiente",
        color: "textPrimary",
        icon: <PendienteIcon color={"inherit"}/>,
        tooltip: "La cobranza está pendiente de aprobación"
    };

    // eslint-disable-next-line default-case
    switch (status) {
        case "A":
            ret.description = "Aprobada";
            ret.color = "primary";
            ret.icon = <CheckIcon color={"primary"}/>;
            ret.tooltip = "La cobranza está lista para su rendición";
            break;
        case "O":
            ret.description = "Observada";
            ret.color = "secondary";
            ret.icon = <ObservadaIcon color={"secondary"}/>;
            ret.tooltip = "La cobranza fue observada y debe ser editada";
            break;
        case "I":
            ret.description = "Ingresada";
            ret.color = "textSecondary";
            ret.icon = <CheckIcon color={"disabled"}/>;
            ret.tooltip = "La cobranza ya fue ingresada al sistema";
            break;
    }

    return ret;
}

export function getRoleString(role) {
    switch (role) {
        case 'A':
            return 'Administrador/a';
        case 'O':
            return 'Operador/a';
        case 'S':
            return 'Supervisor/a';
        case 'V':
            return 'Vendedor/a';
        case 'C':
            return 'Cliente';
        default:
            return 'Sin rol asignado';
    }
}
