import React from "react";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import {Button, IconButton, InputAdornment, MenuItem, TextField, Tooltip, Typography} from "@material-ui/core";
import {
    AdjustRounded as Adjustcon,
    DeleteRounded as RemoveIcon,
    EditRounded as EditIcon,
    MoreVertRounded as MenuIcon,
    WarningRounded as WarningIcon,
} from "@material-ui/icons";
import {formatPrice, InputPrice} from "assets/utils";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Menu from "@material-ui/core/Menu";
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import Box from "@material-ui/core/Box";

export class CobranzaItemsList extends React.Component {
    render() {
        const {items, headerData, cobranza, cliente, TIPOS, itemValidationError, operation} = this.props;
        const editable = (operation === 'CREATE' || operation === 'EDIT');

        return (
            <React.Fragment>
                <Grid container justify={"space-between"}>
                    <Typography
                        variant={"caption"}
                        color={items.length <= 0 ? "error" : "initial"}
                    >
                        Items de Cobranza ({items.length})
                    </Typography>
                    {!isNaN(cobranza.items.promedio_ponderado) && (
                        <Typography
                            variant={"caption"}
                            color={items.length <= 0 ? "error" : "initial"}
                        >
                            Promedio días: {cobranza.items.promedio_ponderado}
                        </Typography>
                    )}
                    {/*{cliente.saldoCuentaCorriente > 0 && (*/}
                    {/*    <Typography*/}
                    {/*        variant={"caption"}*/}
                    {/*        color={items.length <= 0 ? "error" : "initial"}*/}
                    {/*    >*/}
                    {/*        SCC: {formatPrice(cliente.saldoCuentaCorriente, true)}*/}
                    {/*    </Typography>*/}
                    {/*)}*/}
                    <Box>
                        <Tooltip title={'Agregar Item'}>
                            <span>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={this.props.onAdd}
                                    disabled={!editable}
                                >
                                    Agregar
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title={'Quitar todos'}>
                            <span>
                                <IconButton
                                    onClick={this.props.onRemoveAll}
                                    disabled={!editable}
                                    className={'ml-1'}
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.54) !important'
                                    }}
                                    size={"small"}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Grid>

                {itemValidationError === true && (
                    <Typography variant={"caption"} color={"secondary"} className={'d-flex align-items-center'}>
                        <WarningIcon fontSize={"small"}/> Existen items incompletos
                    </Typography>
                )}

                <List style={{
                    maxHeight: 'calc(100vh - 21rem)',
                    overflowY: 'auto',
                    minHeight: '4.5rem'
                }}>
                    {items.map((item, index) => (
                        <ListItem key={item.id} className={'justify-content-between align-items-baseline'} disableGutters>
                            {item.error && (
                                <Tooltip title={'Item Incompleto'}>
                                    <WarningIcon
                                        fontSize={"small"}
                                        color={"secondary"}
                                        style={{
                                            marginLeft: '0rem',
                                            marginBottom: index === 0 ? '-0.1rem' : '0',
                                            alignSelf: index === 0 ? 'flexEnd' : ''
                                        }}
                                    />
                                </Tooltip>
                            )}
                            <TextField
                                select
                                fullWidth={true}
                                label={index === 0 ? "Tipo" : null}
                                style={{width: item.error ? '11.5rem' : '13rem'}}
                                value={item.tipo}
                                onChange={event => this.props.onChangeTipo(item.id, event.target.value)}
                                InputProps={{
                                    readOnly: (!editable || item.observado),
                                }}
                            >
                                {TIPOS.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.description} ({option.id})
                                    </MenuItem>
                                ))}
                            </TextField>
                            {item.tipo !== 'VAL' && item.tipo !== 'SCC' && (
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        disableToolbar
                                        autoOk
                                        fullWidth
                                        label={index === 0 ? "Fecha" : null}
                                        style={{width: '6.3rem'}}
                                        format="DD/MM/Y"
                                        minDate={editable && (item.tipo !== 'TRF' && item.tipo !== 'AEF') ? headerData.fechaRecibo.toDate() : new Date('1900-01-01')}
                                        minDateMessage={"no puede ser menor a fecha recibo"}
                                        value={item.fecha}
                                        onChange={moment => this.props.onChangeFecha(item.id, moment)}
                                        helperText={operation === 'EDIT' && item.observado ? `Orig: ${item._fecha}` : ''}
                                        readOnly={!editable || item.observado}
                                        InputProps={{
                                            readOnly: (!editable || item.observado),
                                            onClick: (e) => (!editable || item.observado) && e.stopPropagation()
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            )}
                            <TextField
                                label={index === 0 ? "Importe" : null}
                                style={{width: item.tipo !== 'VAL' ? '8rem' : '12rem'}}
                                value={item.importe}
                                error={parseFloat(item.importe) === 0}
                                helperText={
                                    (item.tipo === 'SCC' && editable) ?
                                        `Max. ${formatPrice(cliente.saldoCuentaCorriente, true)}` :
                                        operation === 'EDIT' && item.observado ?
                                            `Orig: ${formatPrice(item._importe, true)}` :
                                            ''
                                }
                                onClick={() => {
                                    if (item.tipo === 'VAL' && !item.observado && editable === true)
                                        this.props.onEdit(item.id);
                                }}
                                onChange={event => this.props.onChangeImporte(item.id, event.target.value)}
                                onBlur={(event) => {
                                    const importe = parseFloat(item.importe);
                                    if (isNaN(importe) || importe <= 0)
                                        this.props.onChangeImporte(item.id, 0);

                                    if (item.tipo === 'SCC' && importe > cliente.saldoCuentaCorriente)
                                        this.props.onChangeImporte(item.id, cliente.saldoCuentaCorriente);
                                }}
                                InputProps={{
                                    readOnly: !editable || item.observado || item.tipo === 'VAL',
                                    inputComponent: InputPrice,
                                    style: {textAlign: 'right'},
                                    endAdornment: (
                                        <InputAdornment position="end" style={{margin: '0'}}>
                                            <Tooltip title={'Ajustar al Saldo'}>
                                                <span>
                                                    <IconButton
                                                        size={"small"}
                                                        disabled={!editable || item.observado || item.tipo === 'VAL' || item.tipo.startsWith('R')}
                                                        onClick={() => {
                                                        this.props.onAdjustItem(item)
                                                    }}>
                                                        <Adjustcon fontSize={"small"}/>
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <PopupState variant="popover" popupId={item.id.toString()}>
                                {popupState => (
                                    <React.Fragment>
                                        <Tooltip title={'Opciones'}>
                                            <IconButton size={"small"} {...bindTrigger(popupState)}>
                                                <MenuIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <Menu keepMounted {...bindMenu(popupState)}>
                                            <MenuItem onClick={() => {
                                                if (!editable || item.observado)
                                                    return;
                                                popupState.close();
                                                this.props.onRemove(item.id)
                                            }}>
                                                <IconButton size={"small"} disabled={(!editable || item.observado)}>
                                                    <RemoveIcon/>
                                                </IconButton>&nbsp;
                                                Quitar
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                if (!editable || item.observado)
                                                    return;
                                                popupState.close();
                                                this.props.onEdit(item.id);
                                            }}>
                                                <IconButton size={"small"} disabled={(!editable || item.observado)}>
                                                    <EditIcon/>
                                                </IconButton>&nbsp;
                                                Editar
                                            </MenuItem>
                                        </Menu>
                                    </React.Fragment>
                                )}
                            </PopupState>
                        </ListItem>
                    ))}

                    {items.length === 0 && (
                        <Typography variant={"body2"} align={"center"} color={"textSecondary"} className={'pt-4 pb-2'}>
                            Ingrese un ítem de cobranza
                        </Typography>
                    )}
                </List>
            </React.Fragment>
        )
    }
}

CobranzaItemsList.defaultProps = {
    onChangeImporte: () => {
    },
};

CobranzaItemsList.propTypes = {
    items: PropTypes.array.isRequired,
    onChangeTipo: PropTypes.func,
    onChangeFecha: PropTypes.func,
    onChangeImporte: PropTypes.func,
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
    onAdd: PropTypes.func
};
