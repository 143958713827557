import BaseService from './base.service'

export default class ProveedoresService extends BaseService {
    getAll(expreso = null, fnSuccess, fnError) {
        return this.getApi().get(`/proveedores?expreso=${expreso}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los proveedores')));
    }

    getById(id, fnSuccess, fnError) {
        return this.getApi().get(`/proveedores?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el proveedor')));
    }

    getGroups(expreso = null, fnSuccess, fnError) {
        return this.getApi().get(`/proveedores/groups`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getProveedorShellId(fnSuccess, fnError) {
        return this.getApi().get(`/proveedores/proveedorShellId`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }
}