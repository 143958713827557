import React, {Component} from 'react'
import SideBarContent from "components/sideBar/content/sideBar.content";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyRounded';
import ListAltcon from '@material-ui/icons/ListAltRounded';

const aMainMenu = [
    {
        key: 'ventas',
        title: 'Ventas',
        icon: <AttachMoneyIcon/>,
        subMenu: [
            {
                key: 'clientes',
                title: 'Clientes',
                icon: '',
                authorizedRoles: ["A", "S", "V"]
            },
            {
                key: 'cotizaciones',
                title: 'Cotizaciones',
                icon: '',
                authorizedRoles: ["A", "S", "V"]
            },
            {
                key: 'pedidos',
                title: 'Pedidos',
                icon: '',
                authorizedRoles: ["A", "S", "V"]
            },
            {
                key: 'facturas',
                title: 'Facturas',
                icon: '',
                authorizedRoles: ["A", "S", "V"]
            },
            {
                key: 'notasCredito',
                title: 'Notas de Crédito',
                icon: '',
                authorizedRoles: ["A", "S", "V"]
            },
            {
                key: 'cobranzas',
                title: 'Cobranzas',
                icon: '',
                authorizedRoles: ["A", "S", "V"],
                authorizedCompanies: ["localhost", "bernerbsas"]
            },
            {
                key: 'recepciones',
                title: 'Recepciones',
                icon: '',
                authorizedRoles: ["A"],
                authorizedCompanies: ["localhost", "bernerbsas"]
            },
            {
                key: 'AFIP',
                title: 'AFIP',
                icon: '',
                authorizedRoles: ["A", "O"],
                authorizedCompanies: ["localhost", "bernerbsas"]
            }
        ]
    },
    {
        key: 'stock',
        title: 'Stock',
        icon: <PlaylistAddCheckIcon/>,
        authorizedRoles: ["A", "S", "V"],
        subMenu: [
            {
                key: 'articulos',
                title: 'Artículos',
                icon: '',
            },
            {
                key: 'listasPrecio',
                title: 'Listas de Precio',
                icon: '',
            },
            {
                key: 'equivalenciasFiltros',
                title: 'Equivalencias de Filtros',
                icon: '',
                authorizedCompanies: ["localhost", "bernerbsas"]
            }
        ]
    },
    // {
    //     key: 'compras',
    //     title: 'Compras',
    //     icon: <MoneyOffIcon/>,
    //     disabled: true
    // },
    // {
    //     key: 'fondos',
    //     title: 'Fondos',
    //     icon: <LocalAtmIcon/>,
    //     disabled: true
    // },
    // {
    //     key: 'contabilidad',
    //     title: 'Contabilidad',
    //     icon: <ExposureIcon/>,
    //     disabled: true
    // },
    {
        key: 'divider'
    },
    // {
    //     key: 'herramientas',
    //     title: 'Herramientas',
    //     icon: <BuildIcon/>,
    //     disabled: true
    // },
    {
        key: 'listados',
        title: 'Listados',
        icon: <ListAltcon/>,
        authorizedRoles: ["A", "S", "V"],
        subMenu: [
            {
                key: 'clientesInhabilitados',
                title: 'Clientes Inhabilitados',
                icon: '',
            },
            {
                key: 'documentosImpagos',
                title: 'Documentos Impagos',
                icon: '',
            },
            {
                key: 'pedidosPendientes',
                title: 'Pedidos Pendientes',
                icon: '',
            },
            {
                key: 'estadisticasVentas',
                title: 'Estadísticas de Ventas',
                icon: '',
            },
            {
                key: 'cuentasCorrientes',
                title: 'Cuentas Corrientes',
                icon: '',
            },
            {
                key: 'cobranzasTransitorias',
                title: 'Cobranzas Transitorias',
                icon: '',
                authorizedCompanies: ["localhost", "bernerbsas"]
            },
            {
                key: 'rendicionCobranzas',
                title: 'Rendición de Cobranzas',
                icon: '',
                authorizedCompanies: ["localhost", "bernerbsas"]
            }
        ]
    },
];

export class SideBarContainer extends Component {
    render() {
        return (
            <SideBarContent mainMenu={aMainMenu}/>
        )
    }
}
