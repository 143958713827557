import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {formatPrice} from "assets/utils";

export const ConfirmationDialog = ({open, importe, cliente, onConfirm, onCancel}) => (
    <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"¿Generar nueva cotización?"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Estás por generar una nueva cotización para el cliente <b>{cliente.razsoc}</b> por un total de <b>{formatPrice(importe, true)}</b>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                Cancelar
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Confirmar
            </Button>
        </DialogActions>
    </Dialog>
);