import React from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, withMobileDialog, withStyles} from '@material-ui/core';
import ArticuloEditFields from "components/helpers/articulos/articulo.edit.fields";

const getInitialState = () => ({
    bIsValidSelection: true
});

class ArticulosEditDialog extends React.Component {
    state = getInitialState(this.props);

    onClose = () => {
        this.props.onClose();
        this.setState(getInitialState());
    };

    onEdit = articulo => {
        this.props.onEdit({...this.props.articulo, ...articulo});
        this.setState({bIsValidSelection: articulo.isValid});
    };

    onConfirm = () => this.props.onConfirm();

    render() {
        const {open, fullScreen, articulo, mode, depositos} = this.props;
        const {bIsValidSelection} = this.state;

        return (
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                open={open}
                onClose={this.onClose}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <DialogTitle>Editar artículo</DialogTitle>
                <DialogContent>
                    {articulo && (
                        <ArticuloEditFields
                            id={articulo.id}
                            detalle={articulo.detalle}
                            cantidad={articulo.cantidad}
                            precio={articulo.precio}
                            bonificacion={articulo.bonificacion}
                            bonificacion_cliente={articulo.bonificacion_cliente}
                            deposito={articulo.deposito}
                            afacturar={articulo.afacturar}
                            adescargar={articulo.adescargar}
                            mode={mode}
                            depositos={depositos}
                            onEditArticulo={this.onEdit}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={this.onConfirm}
                        color="primary"
                        autoFocus
                        disabled={bIsValidSelection === false}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ArticulosEditDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

ArticulosEditDialog.defaultProps = {
    articulo: {}
};

export default withMobileDialog()(withStyles({}, {withTheme: true})(ArticulosEditDialog));
