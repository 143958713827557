import BaseService from './base.service'

export default class CotizacionesService extends BaseService {
    getAll(fnSuccess, fnError) {
        return this.getApi().get(`/cotizaciones`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getById(id, fnSuccess, fnError) {
        return this.getApi().get(`/cotizaciones?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getByCliente(cliente, fnSuccess, fnError) {
        return this.getApi().get(`/cotizaciones?cliente=${cliente}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    create(cliente, data, fnSuccess, fnError) {
        return this.getApi().post(`/cotizaciones?cliente=${cliente}`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getArticulosById(id, fnSuccess, fnError) {
        return this.getApi().get(`/cotizaciones/articulos?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getPDF(id, fnSuccess, fnError) {
        this.getApi()({
            url: '/cotizaciones/pdf',
            method: 'get',
            params: {
                id: id
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de la cotización')));
    }

    getMaxLength(fnSuccess, fnError) {
        return this.getApi().get(`/cotizaciones/maxLength`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }
}