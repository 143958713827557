import BaseService from './base.service'

export default class MastersService extends BaseService {
    getCondicionesVenta(fnSuccess, fnError) {
        return this.getApi().get(`/masters/condicionesVenta`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las condiciones de venta')));
    }

    getTalonariosFactura(fnSuccess, fnError) {
        return this.getApi().get(`/masters/talonarios?comprobante=factura`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los talonarios de factura')));
    }

    getTalonariosRemito(fnSuccess, fnError) {
        return this.getApi().get(`/masters/talonarios?comprobante=remito`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los talonarios de remito')));
    }

    getTalonariosNotaCredito(fnSuccess, fnError) {
        return this.getApi().get(`/masters/talonarios?comprobante=notaCredito`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los talonarios de nota de crédito')));
    }

    getTalonariosRecibo(fnSuccess, fnError) {
        return this.getApi().get(`/masters/talonarios?comprobante=recibo`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los talonarios de recibo')));
    }

    getTiposDeAsiento(fnSuccess, fnError) {
        return this.getApi().get(`/masters/tiposDeAsiento`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los tipos de asiento')));
    }

    getJurisdicciones(fnSuccess, fnError) {
        return this.getApi().get(`/masters/jurisdicciones`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las jurisdicciones')));
    }

    getTransportes(fnSuccess, fnError) {
        return this.getApi().get(`/masters/transportes`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los transportes')));
    }

    getZonas(fnSuccess, fnError) {
        return this.getApi().get(`/masters/zonas`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las zonas')));
    }

    getTiposDocu(fnSuccess, fnError) {
        return this.getApi().get(`/masters/tiposDocu`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los tipos de documento')));
    }

    getDepositos(fnSuccess, fnError) {
        return this.getApi().get(`/masters/depositos`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los depósitos')));
    }

    getEquivalenciasFiltros(fnSuccess, fnError) {
        return this.getApi().get(`/masters/equivalenciasFiltros`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las equivalencias de filtros')));
    }

    getAlicuotas(fnSuccess, fnError) {
        return this.getApi().get(`/masters/alicuotas`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las alícuotas')));
    }

    getRespIVA(fnSuccess, fnError) {
        return this.getApi().get(`/masters/respIVA`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las responsabilidades frente a IVA')));
    }

    getProvincias(fnSuccess, fnError) {
        return this.getApi().get(`/masters/provincias`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las provincias')));
    }

    getBonificacionesByRangoLitros(fnSuccess, fnError) {
        return this.getApi().get(`/masters/bonificacionesByRangoLitros`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener bonificaciones por rango de litros')));
    }

    getBancos(fnSuccess, fnError) {
        return this.getApi().get(`/masters/bancos`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los bancos')));
    }

    getCuentasBancarias(fnSuccess, fnError) {
        return this.getApi().get(`/masters/cuentasBancarias`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las cuentas bancarias')));
    }

    getDocumentosACancelar(idCliente, fnSuccess, fnError) {
        return this.getApi().get(`/masters/documentosACancelar?cliente=${idCliente}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los documentos a cancelar')));
    }
}
