import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Slide,
    Typography
} from "@material-ui/core";
import {formatPrice} from "assets/utils";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export class CobranzaAjustePlazoAddDialog extends React.Component {
    render() {
        return <CobranzaDocumentosAddDialog {...this.props} tipo={"AP"}/>
    }
}

export class CobranzaDocumentosAddDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                fullScreen={this.props.fullScreen}
                TransitionComponent={this.props.fullScreen ? Transition : undefined}
                fullWidth
                maxWidth={"sm"}
                classes={{paperFullScreen: 'niquel-dialog-full-screen'}}
            >
                <CobranzaDocumentosAddDialogContent {...this.props} />
            </Dialog>
        )
    }
}

class CobranzaDocumentosAddDialogContent extends React.Component {
    state = {
        selected: [],
        selectAll: false
    };

    onSelect = documento => {
        const selectedIds = this.state.selected.map(_selected => _selected.id);
        if (selectedIds.indexOf(documento.id) === -1)
            this.setState(prevState => {
                prevState.selected.push(documento);
                if (prevState.selected.length === this.props.documentos.length)
                    prevState.selectAll = true;
                return prevState;
            });
        else
            this.setState(prevState => {
                prevState.selected = prevState.selected.filter(selected => selected.id !== documento.id);
                if (prevState.selected.length !== this.props.documentos.length)
                    prevState.selectAll = false;
                return prevState;
            });
    };

    onSelectAll = () => {
        if (this.state.selectAll === true)
            this.setState(prevState => {
                prevState.selected = [];
                prevState.selectAll = false;
                return prevState;
            });
        else
            this.setState(prevState => {
                prevState.selected = this.props.documentos;
                prevState.selectAll = true;
                return prevState;
            });
    };

    render() {
        const {documentos, cliente, tipo} = this.props;
        const {selectAll} = this.state;
        const selectedIds = this.state.selected.map(_selected => _selected.id);

        const isAP = tipo === "AP";

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Agregar {isAP ? 'Ajuste Plazo' : 'Documentos'}</DialogTitle>

                <DialogContent>
                    {documentos.length > 0 && (
                        <React.Fragment>
                            <DialogContentText>Se muestran {isAP ? 'las notas de crédito por ajuste plazo' : 'los documentos pendientes de cancelar'} de {cliente.razsoc} ({cliente.id})</DialogContentText>
                            <List dense>
                                <Grid container justify={"flex-end"} alignItems={"center"}>
                                    <Typography variant={"caption"}>Seleccionar todos:</Typography>
                                    <Checkbox
                                        onClick={this.onSelectAll}
                                        checked={selectAll}
                                        style={{
                                            marginRight: '0.2rem',
                                            marginLeft: '0.4rem'
                                        }}
                                    />
                                </Grid>
                                {documentos.map(documento => (
                                    <ListItem key={documento.id} button onClick={() => this.onSelect(documento)}>
                                        <ListItemText
                                            primary={`${documento.tipo} ${documento.numero}`}
                                            secondary={`${documento.ingreso} (${documento.dias} días)`}
                                            primaryTypographyProps={{variant: "body2", color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                        />
                                        <ListItemText
                                            primary={`Pendiente: ${formatPrice(documento.pendiente, true)}`}
                                            secondary={`Total: ${formatPrice(documento.total, true)}`}
                                            primaryTypographyProps={{variant: "body2", color: "textPrimary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textSecondary"}}
                                        />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onClick={() => this.onSelect(documento)}
                                                checked={selectedIds.indexOf(documento.id) !== -1}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </React.Fragment>
                    )}

                    {documentos.length <= 0 && (
                        <Typography variant={"body2"} align={"center"}>No hay documentos pendientes de cancelar</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button disabled={selectedIds.length === 0} onClick={() => this.props.onConfirm(this.state.selected)} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

CobranzaDocumentosAddDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool,
    documentos: PropTypes.array.isRequired,
    cliente: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};
