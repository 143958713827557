import React, {Component} from 'react'
import {Box, Tooltip} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {ClientesService, CotizacionesService, PedidosService} from "services";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@material-ui/lab";
import AddIcon from '@material-ui/icons/Add';
import CopyCotizacionIcon from '@material-ui/icons/FilterNoneRounded';
import {CotizacionReferenciaDialog} from "./dialogs/cotizacionReferencia.dialog";
import {sortDate, sortNumeric} from "assets/utils";
import SubHeader from "components/helpers/subHeader/subHeader";

export class PedidosList extends Component {
    state = {
        cliente: {},
        columns: [
            {name: 'id', title: 'ID Pedido'},
            {name: 'ingreso', title: 'Ingreso'},
            {name: 'entrega', title: 'Entrega'},
            {name: 'importe_bonificado', title: 'Importe'},
            {name: 'estado', title: 'Entregado'},
            // {name: 'edit', title: 'Edit'},
        ],
        columnExtensions: [
            {columnName: 'id', width: 120, compare: sortNumeric, direction: 'desc'},
            {columnName: 'ingreso', compare: sortDate, align: 'center'},
            {columnName: 'entrega', compare: sortDate, align: 'center'},
            {columnName: 'importe_bonificado', type: 'currency', compare: sortNumeric, align: 'right'},
            {columnName: 'estado', width: 200, type: 'check', align: 'center'},
        ],
        rows: [],
        deudaPendiente: false,
        direccionesEntrega: [],
        dialOpen: false,
        cotizacionReferenciaDialogOpen: false,
        cotizaciones: []
    };

    ClientesService = new ClientesService(this.context);
    PedidosService = new PedidosService(this.context);
    CotizacionesService = new CotizacionesService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => {
                this.setState({cliente: response.data.result}, () => {
                    // Chequeo si el cliente se encuentra inhabilitado y lo informo.
                    if (this.state.cliente.inhabilitado) {
                        this.props.enqueueSnackbar('ATENCIÓN: Este cliente se encuentra inhabilitado', {variant: 'warning'});
                        return false;
                    }
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ClientesService.getDeudaPendiente(
            this.props.match.params.cliente,
            response => this.setState({deudaPendiente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.ClientesService.getDireccionesEntrega(
            this.props.match.params.cliente,
            response => this.setState({direccionesEntrega: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.PedidosService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));

        this.CotizacionesService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({cotizaciones: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    navEdit = (row) => {
        this.props.history.push(`${this.props.match.url}/edit/${row.id}`);
    };

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/view/${row.id}`);
    };

    validateCliente = () => {
        // Valido que el cliente tenga direcciones de entrega disponibles. Si no tiene no puede ingresar el pedido.
        // FIX 15/07 - La validacíón no aplica para la empresa coutoune.
        if (this.state.direccionesEntrega.length < 1 && this.context.loggedUser.cmpid !== 'coutoune') {
            this.props.enqueueSnackbar('NO es posible ingresar pedidos. El cliente no tiene direcciones de entrega disponibles.', {variant: 'error'});
            return false;
        }

        // Valido que el cliente no tenga deuda y si tiene chequeo el rol del usuario, la categoría del vendedor (si el usuario es vendedor) y el id del proveedor.
        if (this.state.deudaPendiente) {
            if (this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S' || this.context.loggedUser.role === 'O')
                this.props.enqueueSnackbar('ATENCIÓN: Este cliente presenta documentos impagos con fechas anteriores al límite impuesto.', {variant: 'warning'});
            else if (this.context.loggedUser.categoria === '123' && this.state.cliente.proveedor !== '123') {
                this.props.enqueueSnackbar('NO es posible ingresar pedidos. Este cliente presenta documentos impagos con fechas anteriores al límite impuesto.', {variant: 'error'});
                return false;
            } else
                this.props.enqueueSnackbar('ATENCIÓN: Este cliente presenta documentos impagos con fechas anteriores al límite impuesto. Puede ingresar pedidos pero no entregarlos ni facturarlos.', {variant: 'warning'});
        }

        return true;
    };

    navCreate = () => {
        if (this.validateCliente())
            this.props.history.push(`${this.props.match.url}/create`);
    };

    onCreateFromCotizacion = cotizacion => {
        this.onCloseCotizacionRefenciaDialog();

        if (this.validateCliente())
            this.props.history.push(`${this.props.match.url}/create/ref/${cotizacion.id}`);
    };

    onOpenCotizacionRefenciaDialog = () => this.setState({cotizacionReferenciaDialogOpen: true});

    dialActions = [
        {icon: <AddIcon/>, name: 'Nuevo pedido', action: this.navCreate},
        {icon: <CopyCotizacionIcon/>, name: 'Nuevo pedido a partir de una referencia', action: this.onOpenCotizacionRefenciaDialog},
    ];

    onCloseCotizacionRefenciaDialog = () => this.setState({cotizacionReferenciaDialogOpen: false});

    onDialClose = () => this.setState({dialOpen: false});

    onDialOpen = () => this.setState({dialOpen: true});

    render() {
        const {cliente, columns, columnExtensions, rows, dialOpen, cotizacionReferenciaDialogOpen, cotizaciones} = this.state;

        return (
            <Box className='niquel-pedidos-list'>
                <SubHeader
                    title={'Pedidos'}
                    subtitle={'Pedidos Ingresados'}
                    cliente={cliente}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={`Pedidos de ${cliente.razsoc} (${rows.length})`}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navView}
                    />
                    <Tooltip placement={"left-end"} title={cliente.inhabilitado ? 'El cliente está inhabilitado' : ''}>
                        <Box className='niquel-fab-btn'>
                            <SpeedDial
                                ariaLabel="SpeedDial example"
                                icon={<SpeedDialIcon/>}
                                onBlur={this.onDialClose}
                                onClick={this.onDialOpen}
                                onClose={this.onDialClose}
                                onFocus={this.onDialOpen}
                                onMouseEnter={this.onDialOpen}
                                onMouseLeave={this.onDialClose}
                                FabProps={{
                                    disabled: cliente.inhabilitado
                                }}
                                open={dialOpen}
                            >
                                {this.dialActions.map(action => (
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            action.action()
                                        }}
                                    />
                                ))}
                            </SpeedDial>
                        </Box>
                    </Tooltip>
                </Box>
                <CotizacionReferenciaDialog
                    open={cotizacionReferenciaDialogOpen}
                    cotizaciones={cotizaciones}
                    cliente={cliente}
                    onConfirm={this.onCreateFromCotizacion}
                    onCancel={this.onCloseCotizacionRefenciaDialog}
                />
            </Box>
        )
    }
}

PedidosList.contextType = MainContext;
PedidosList = withSnackbar(PedidosList);
