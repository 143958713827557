import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import './login.scss';
import {Formik} from 'formik';
import * as Yup from "yup";
import AuthService from 'services/auth.service';
import {LoginForm} from "./login.form";
import {Typography} from "@material-ui/core";
import {MainContext} from "../../contexts/main.context";

const validationSchema = Yup.object().shape({
    username: Yup.string("Ingrese su nombre de usuario")
        .required("Ingrese su nombre de usuario"),
    password: Yup.string("Ingrese su contraseña")
        .required("Ingrese su contraseña")
});

export class Login extends Component {
    AuthService = new AuthService(this.context);

    render() {
        return (
            <div className='niquel-login container-fluid'>
                <div className='row'>
                    <div className='col-lg-7 d-none d-lg-flex login-title'>
                        <div className='title'>Niquel</div>
                        <div className='subtitle'>Cloud software for enterprises</div>
                    </div>
                    <div className='col-lg-5 col-12 login-form'>
                        <div className='login-form-wrapper'>
                            <Typography variant={"h3"} align={"center"} className='text-dark-gray'>
                                Bienvenido, usuario
                            </Typography>
                            <Formik
                                render={props => <LoginForm {...props} />}
                                initialValues={{
                                    username: '',
                                    password: '',
                                    showPassword: false
                                }}
                                initialStatus={{
                                    error: false,
                                    message: ''
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values, actions) => {
                                    this.AuthService.login(
                                        values.username,
                                        values.password,
                                        response => {
                                            this.props.history.push('/');
                                        },
                                        error => {
                                            actions.setSubmitting(false);
                                            actions.setStatus({error: true, message: error});
                                        });
                                }}
                            />
                        </div>
                        <div className='footer-disclaimer'>
                            2020 IECE SRL Cloud Software
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Login.contextType = MainContext;
export default withRouter(Login);
