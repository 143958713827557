import React, {Component} from 'react'
import {Box, Fab, Tooltip} from "@material-ui/core";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import ClientesService from 'services/clientes.service';
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import PersonAddIcon from "@material-ui/icons/PersonAddRounded";

export class ClientesList extends Component {
    ClientesService = new ClientesService(this.context);

    state = {
        columns: [
            {name: 'id', title: 'ID Cliente', width: 115},
            // {name: 'codigo', title: 'Código'},
            {name: 'razsoc', title: 'Razón Social'},
            {name: 'localidad', title: 'Localidad'},
            {name: 'direccion', title: 'Dirección'},
            {name: 'apenom', title: 'Vendedor', width: 200}
        ],
        rows: []
    };

    componentDidMount() {
        this.ClientesService.getAll(
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    navCreate = (row) => {
        this.props.history.push(`${this.props.match.url}/create`);
    };

    render() {
        const {columns, rows} = this.state;

        return (
            <Box className='niquel-clientes-list'>
                <SubHeader
                    title={'Clientes'}
                    subtitle={'Listado de clientes'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={'Clientes'}
                        columns={columns}
                        rows={rows}
                        enableFiltering={true}
                        showFilters={false}
                        rowClick={false}
                    />
                    <Tooltip title={'Agregar cliente ocasional'}>
                        <Box className='niquel-fab-btn'>
                            <Fab color="primary" onClick={this.navCreate}>
                                <PersonAddIcon/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        )
    }
}

ClientesList.contextType = MainContext;
ClientesList = withSnackbar(ClientesList);