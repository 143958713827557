import BaseService from './base.service'

export default class ListadosService extends BaseService {
    listaPrecios(type, proveedores, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/listaPrecios',
            method: 'post',
            params: {
                type: type
            },
            data: {
                data: proveedores
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar la lista de precios')));
    }

    clientesInhabilitados(idVendedor, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/clientesInhabilitados',
            method: 'post',
            params: {
                vendedor: idVendedor
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de clientes inhabilitados')));
    }

    documentosImpagos(clientes, vendedores, fechaCorte, tipo, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/documentosImpagos',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                fecha: fechaCorte,
                tipo: tipo
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de documentos impagos')));
    }

    pedidosPendientes(clientes, vendedores, fechaInicio, fechaFin, tipo, agrupar, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/pedidosPendientes',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                tipo: tipo,
                agrupar: agrupar,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de pedidos pendientes')));
    }

    estadisticasVentas(clientes, proveedores, vendedores, fechaInicio, fechaFin, listado, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/estadisticasVentas',
            method: 'post',
            data: {
                clientes: clientes,
                proveedores: proveedores,
                vendedores: vendedores,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                listado: listado,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de estadísticas de ventas')));
    }

    cuentasCorrientes(clientes, vendedores, fechaInicio, fechaFin, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/cuentasCorrientes',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de cuentas corrientes')));
    }

    cobranzasTransitorias(clientes, vendedores, filtrar, desde, hasta, visualizar, agrupar, ordenar, status, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/cobranzasTransitorias',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                filtrar: filtrar,
                desde: desde,
                hasta: hasta,
                visualizar: visualizar,
                agrupar: agrupar,
                ordenar: ordenar,
                status: status,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de cobranzas transitorias')));
    }

    rendicionCobranzas(cobranzas, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/rendicionCobranzas',
            method: 'post',
            data: {
                cobranzas: cobranzas,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar la rendición de cobranzas')));
    }
}
