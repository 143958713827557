import React, {Component} from 'react';
import {AppBar, Box, Grid, Tab, Tabs, Toolbar} from "@material-ui/core";
import {MainContext} from "contexts/main.context";
import {ListaProveedor} from "./listaProveedor";
import {ListaGrupo} from "./listaGrupo";
import {Route, Switch} from 'react-router-dom';
import SubHeader from "../../helpers/subHeader/subHeader";

const basePath = '/stock/listasPrecio';

export class ListasPrecio extends Component {
    state = {
        tabActive: 'listaProveedor'
    };

    componentDidMount() {
        if (this.props.location.pathname.indexOf('listaGrupo') > -1)
            this.setState({tabActive: 'listaGrupo'});
    }

    changeTab = (event, value) => {
        this.setState({tabActive: value});
        this.props.history.push(`${this.props.match.url}/${value}`);
    };

    render() {
        const {tabActive} = this.state;
        return (
            <div className='niquel-stock-listasPrecio'>
                <SubHeader
                    title={'Listas de Precio'}
                />
                <AppBar position="relative" elevation={0}>
                    <Toolbar variant="dense" color='primary'>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item md={4}>
                                <Tabs
                                    value={tabActive}
                                    onChange={this.changeTab}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                >
                                    <Tab label="Proveedor" value='listaProveedor'/>
                                    <Tab label="Grupo" value='listaGrupo'/>
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box className='container-xl mt-3 mb-3'>
                    <Switch>
                        <Route path={`${basePath}/listaGrupo`} component={ListaGrupo}/>
                        <Route path={`${basePath}`} component={ListaProveedor}/>
                    </Switch>
                </Box>
            </div>
        )
    }
}

ListasPrecio.contextType = MainContext;
