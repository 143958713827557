import BaseService from './base.service'

export default class UtilsService extends BaseService {
    chequeIsValid(numero, fnSuccess, fnError) {
        return this.getApi().get(`/utils/cheques/isValid?numero=${numero}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al validar cheque')));
    }

    getRecepcionesCobranza(options, fnSuccess, fnError) {
        const {filter = '', desde= '', hasta= '', sortBy = '', sortDir = ''} = options;
        return this.getApi().get(`/utils/recepciones/cobranza?filter=${filter}&desde=${desde}&hasta=${hasta}&sortBy=${sortBy}&sortDir=${sortDir}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los items de recepción')));
    }

    updateRecepcionesCobranza(item, fnSuccess, fnError) {
        return this.getApi().put(`/utils/recepciones/cobranza`, item)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al actualizar ítem')));
    }
}
