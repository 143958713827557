import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {formatPrice} from "assets/utils";

export class ConfirmationDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ConfirmationDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ConfirmationDialogContent extends Component {
    render() {
        const {cliente, cobranza, operation, id, onConfirm, onCancel} = this.props;

        return (
            <React.Fragment>
                {operation === 'CREATE' && (
                    <DialogTitle id="alert-dialog-title">¿Confirmar ingreso de cobranza?</DialogTitle>
                )}
                {operation === 'EDIT' && (
                    <DialogTitle id="alert-dialog-title">¿Confirmar edición de cobranza?</DialogTitle>
                )}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        {operation === 'CREATE' && (
                            <Typography variant={"body2"}>
                                Estás por generar una nueva cobranza para el cliente <b>{cliente.razsoc}</b>
                            </Typography>
                        )}
                        {operation === 'EDIT' && (
                            <Typography variant={"body2"}>
                                Estás por editar la cobranza {id} del cliente <b>{cliente.razsoc}</b>
                            </Typography>
                        )}
                        <Grid container direction={"column"} className={'mt-2 mb-2'}>
                            <Grid container direction={"row"} className={'mt-2'}>
                                <Typography variant={"caption"}>Total a cancelar (documentos)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {formatPrice(cobranza.documentos.total, true)}
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"} className={'mt-3'}>
                                <Typography variant={"caption"}>Total a cobrar (items)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {formatPrice(cobranza.items.total, true)}
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Typography variant={"caption"}>Promedio dias de pago (ponderado)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {cobranza.items.promedio_ponderado === 0 ? `Pago al día (0)` : `Pago a ${cobranza.items.promedio_ponderado} días`}
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Typography variant={"caption"} color={"primary"}>Porcentaje descuento *</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"} color={"primary"}>
                                    {cobranza.descuento.porcentaje}%
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Typography variant={"caption"} color={"primary"}>Importe descuento (nota de crédito)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"} color={"primary"}>
                                    {formatPrice(cobranza.descuento.importe, true)} ({formatPrice(cobranza.descuento.importe_sin_iva, true)} +
                                    IVA: {formatPrice(cobranza.descuento.iva, true)})
                                </Typography>
                            </Grid>
                            {cobranza.ajustes_plazo.total > 0 && (
                                <Grid container direction={"row"}>
                                    <Typography variant={"caption"}>Ajuste Plazo</Typography>
                                    <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                    <Typography variant={"body2"} align={"right"}>
                                        {formatPrice(cobranza.ajustes_plazo.total, true)}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid container direction={"row"} className={'mt-3'}>
                                <Typography variant={"caption"}>Saldo</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {formatPrice(cobranza.saldo_cliente, true)}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant={"caption"} className={'mb-2'}>
                            * El descuento se aplica sobre el importe neto de los documentos (sin percepciones).
                        </Typography>

                        {cobranza.saldo_cliente > 0 && (
                            <Typography variant={"body2"} className={'mt-2'}>
                                <b>ATENCIÓN:</b> Queda un saldo a favor del cliente de <b>{formatPrice(cobranza.saldo_cliente, true)}</b>.
                                Si confirma el ingreso de cobranza se ingresará ese monto como saldo positivo en la cuenta corriente del cliente.
                            </Typography>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={onConfirm} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}
