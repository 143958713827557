import * as React from 'react';
import PropTypes from 'prop-types';
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";

export default class ArticulosTable extends React.PureComponent {
    columns = [
        {name: 'id', numeric: false, disablePadding: false, title: 'Artículo', width: 170},
        {name: 'detalle', numeric: false, disablePadding: false, title: 'Detalle'},
    ];

    changeArticulosSelection = articulosSelectedId => {
        const articulosSelectedData = this.props.articulos.filter(value => articulosSelectedId.indexOf(value.id) !== -1);
        articulosSelectedData.forEach(articulo => {
            this.props.setDefaultsArticulo(articulo);
        });
        this.props.changeArticulosSelection(articulosSelectedId, articulosSelectedData);
    };

    render() {
        const {articulos} = this.props;

        return (
            <div className='niquel-articulosTable'>
                <ReactGrid
                    height={280}
                    columns={this.columns}
                    rows={articulos}
                    getRowId={row => row.id}
                    enableFiltering={false}
                    enableSorting={true}
                    enableSearch={true}
                    enableSelection={true}
                    showSelectAll={false}
                    selectedRows={this.props.articulosSelectedId}
                    onSelectionChange={this.changeArticulosSelection}
                    elevation={0}
                />
            </div>
        );
    }
}

ArticulosTable.propTypes = {
    articulos: PropTypes.array.isRequired,
    articulosSelectedId: PropTypes.array.isRequired,
    changeArticulosSelection: PropTypes.func.isRequired
};

ArticulosTable.defaultProps = {
    articulos: []
};
