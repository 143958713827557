import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import themeOptions from 'assets/styles/theme';
import {Login} from "components/login/login";
import Main from "components/main/main";
import {LoginRoute, PrivateRoute} from 'components/helpers/customRoutes';
import {SnackbarProvider} from 'notistack';
import './App.css';
import './assets/styles/all.scss';

const theme = createMuiTheme(themeOptions);

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider
                    preventDuplicate
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <Switch>
                        <LoginRoute path='/login' component={Login}/>
                        <PrivateRoute path='/' component={Main}/>
                    </Switch>
                </SnackbarProvider>
            </MuiThemeProvider>
        );
    }
}

export default App;
