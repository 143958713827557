import BaseService from './base.service'

export default class ArticulosService extends BaseService {
    getAll(cliente = null, fnSuccess, fnError) {
        return this.getApi().get(`/articulos?cliente=${cliente}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getById(id, cliente = null, fnSuccess, fnError) {
        return this.getApi().get(`/articulos?id=${id}&cliente=${cliente}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getImage(id, fnSuccess, fnError) {
        return this.getApi().get(`/articulos/image?id=${id}`)
            .then(response => {
                if (response.status === 200)
                    fnSuccess(response.data);
                else
                    fnSuccess(null);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getValoresFacturados(id, cliente, fnSuccess, fnError) {
        return this.getApi().get(`/articulos/valoresFacturados?id=${id}&cliente=${cliente}`)
            .then(response => {
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }
}