import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import {STATUS} from "components/helpers/cobranzas/status";
import {getCobranzaStatusInfo} from "assets/utils";

export class ChangeStatusDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ChangeStatusDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ChangeStatusDialogContent extends Component {
    state = {
        status: this.props.status,
    }

    onChangeStatus = e => {
        this.setState({status: e.target.value});
    }

    onConfirm = () => {
        this.props.onConfirm(this.state.status);
    }

    render() {
        const {cobranza, cliente, id, onCancel} = this.props;
        const {status} = this.state;

        const status_changed = status !== cobranza.status;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Modificar estado</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        <Typography variant={"body2"}>
                            Seleccione un nuevo estado para la cobranza <b>{id}</b> del cliente <b>{cliente.razsoc}</b>
                        </Typography>
                    </DialogContentText>
                    <TextField
                        fullWidth
                        select
                        // label={"Estado"}
                        value={status}
                        onChange={this.onChangeStatus}
                    >
                        {
                            STATUS.map((status, index) => {
                                const statusInfo = getCobranzaStatusInfo(status);
                                return (
                                    <MenuItem key={index} value={status}>
                                        <div className={'d-flex align-items-end'}>
                                            {statusInfo.icon}
                                            <Typography component={"span"} color={statusInfo.color}>{statusInfo.description}</Typography>
                                            <Typography component={"span"} variant={"caption"} color={"textSecondary"}>&nbsp;{` — ${statusInfo.tooltip}`}</Typography>
                                        </div>
                                    </MenuItem>
                                );
                            })
                        }
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color={"primary"}>
                        Cancelar
                    </Button>
                    <Button
                        autoFocus
                        color="primary"
                        onClick={this.onConfirm}
                        disabled={!status_changed}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}
