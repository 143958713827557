import React from "react";
import {Button, IconButton, InputAdornment, TextField, Typography} from "@material-ui/core";
import {AccountCircle, Lock, Visibility, VisibilityOff} from "@material-ui/icons";

export const LoginForm = props => {
    const {
        values,
        status,
        errors,
        touched,
        handleSubmit,
        handleChange,
        isValid,
        setFieldTouched,
        setFieldValue,
        setStatus
    } = props;

    const onChange = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
        setStatus({error: false});
    };

    return (
        <form className='form' onSubmit={handleSubmit}>
            <TextField
                name='username'
                helperText={touched.username ? errors.username : ''}
                error={touched.username && Boolean(errors.username)}
                value={values.username}
                onChange={onChange.bind(null, 'username')}
                fullWidth
                variant="outlined"
                label="Usuario"
                className='mb-3'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <AccountCircle/>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                name='password'
                type={values.showPassword ? 'text' : 'password'}
                helperText={touched.password ? errors.password : ''}
                error={touched.password && Boolean(errors.password)}
                value={values.password}
                onChange={onChange.bind(null, 'password')}
                fullWidth
                variant="outlined"
                label="Contraseña"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Lock/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={() => setFieldValue('showPassword', !values.showPassword, false)}
                            >
                                {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            {
                status && status.error && status.message &&
                <Typography className='mt-2' variant='caption' color='error'>{status.message}</Typography>
            }
            <div className='confirmation mt-2'>
                <Button disabled={!isValid} type="submit" variant="contained" color="primary" fullWidth className='mb-2'>
                    Login
                </Button>
                <Button color="primary" fullWidth>
                    Olvidé mi contraseña
                </Button>
            </div>
        </form>
    )
};