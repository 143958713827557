import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography} from '@material-ui/core';
import {formatPrice} from "assets/utils";
import {ArticulosService} from "services";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";

class ArticuloInfoDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                maxWidth={"sm"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ArticuloInfoDialogContent {...this.props}/>
            </Dialog>
        );
    }
}

class ArticuloInfoDialogContent extends React.Component {
    state = {
        valoresFacturados: []
    };

    ArticulosService = new ArticulosService(this.context);

    componentDidMount() {
        this.ArticulosService.getValoresFacturados(
            this.props.articulo.id,
            this.props.cliente.id,
            response => this.setState({valoresFacturados: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }


    getImporteUnitarioFinal = (precio, bonificacion, bonificacionGeneral) => {
        return precio * (1 - bonificacion / 100) * (1 - bonificacionGeneral / 100);
    };

    render() {
        const {onClose, articulo, bonificacionGeneral} = this.props;
        const {valoresFacturados} = this.state;

        return (
            <React.Fragment>
                <DialogTitle>Información del Artículo <Typography color={"textSecondary"} component={"span"}>{articulo.id}</Typography></DialogTitle>
                <DialogContent>
                    <Typography variant={"caption"} color={"textSecondary"}>Documento Actual</Typography>
                    <Grid container spacing={1} justify={"space-between"} className={'mt-1'}>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Precio
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                {formatPrice(articulo.precio)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Bonificación
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                {articulo.bonificacion}%
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Bonificación (general)
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                {bonificacionGeneral}%
                            </Typography>
                        </Grid>
                        <Grid item className={'ml-5'}>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Precio Final
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                <b>{formatPrice(this.getImporteUnitarioFinal(articulo.precio, articulo.bonificacion, bonificacionGeneral))}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={'mt-4 mb-3'}/>
                    <Typography variant={"caption"} color={"textSecondary"}>Historial de valores facturados</Typography>
                    <Grid container direction={"column"} className={'mt-1'}>
                        {valoresFacturados.map((valor, index) => (
                            <Grid item key={index} className={'mt-2 mb-2'}>
                                <Grid item>
                                    <Typography variant={"body2"} component={'span'}>{valor.fecha}</Typography>
                                    <Typography variant={"body2"} component={'span'} color={"textSecondary"}>&nbsp;-&nbsp;{valor.documento}</Typography>
                                </Grid>
                                <Grid container spacing={1} justify={"space-between"}>
                                    <Grid item>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"} title={'seba'}>
                                            Precio
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            {formatPrice(valor.precio)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Bonificación
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            {valor.bonificacion}%
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Bonificación (general)
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            {valor.bonificacion_general}%
                                        </Typography>
                                    </Grid>
                                    <Grid item className={'ml-5'}>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Precio Final
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            <b>{formatPrice(this.getImporteUnitarioFinal(valor.precio, valor.bonificacion, valor.bonificacion_general))}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        {valoresFacturados.length < 1 && (
                            <Grid item>
                                <Typography color={"textSecondary"} align={"center"} variant={"body2"}>
                                    No se encontraron valores facturados para este artículo
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}

ArticuloInfoDialogContent.contextType = MainContext;
ArticuloInfoDialogContent = withSnackbar(ArticuloInfoDialogContent);

export default ArticuloInfoDialog;
