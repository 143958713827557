import React, {Component} from 'react'
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {Box, Button, Card, Grid, IconButton, MenuItem, Switch, TextField, Tooltip, Typography} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import SubHeader from "components/helpers/subHeader/subHeader";
import {CobranzaDocumentosList} from "components/helpers/cobranzas/documentos/cobranza.documentos.list";
import {CobranzaItemsList} from "components/helpers/cobranzas/items/cobranza.items.list";
import {formatPrice, getCobranzaStatusInfo, InputInteger, InputPercentage, InputPrice} from "assets/utils";
import {DeleteRounded as DeleteIcon, DoneRounded as DoneIcon} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

export class CobranzasOperation extends Component {
    state = {
        leyendasDialogOpen: false
    };

    render() {
        const {loggedUser} = this.context;
        const {
            operation, cliente, headerData, changeHeaderData, documentosACancelar, itemsDeCobranza, ajustesPlazo, cobranza,
            dateValidationError, notaCreditoValidationError, TIPOS, itemValidationError, cobranzas, id, onChangeDocument, descuentoFijo, descuentoError
        } = this.props;
        const editable = operation === 'CREATE';

        return (
            <Box className='niquel-cobranzas-operation'>
                <SubHeader
                    title={'Cobranzas'}
                    subtitle={operation === 'VIEW' ? `Revisión de Cobranza ${id}` : operation === 'EDIT' ? `Edición de Cobranza ${id}` : 'Nueva Cobranza'}
                    cliente={cliente}
                    options={operation === 'VIEW' ? cobranzas : undefined}
                    optionsTitle={'Cobranza'}
                    optionSelectedId={id}
                    optionsDescriptionField={'numrec'}
                    optionsDescriptionSecondaryField={'fecharecibo'}
                    onChangeOption={onChangeDocument}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={3}>
                        <Grid container item md={12}>
                            <Grid container item alignItems='center' spacing={3}>
                                <Grid item>
                                    {(loggedUser.role === 'A' || loggedUser.role === 'O') && (
                                        <TextField
                                            required
                                            label="Usuario"
                                            className='mr-2'
                                            value={headerData.usuario}
                                            onChange={event => changeHeaderData('usuario', event.target.value)}
                                            InputProps={{
                                                readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                            }}
                                        />
                                    )}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            required
                                            className='mr-2'
                                            label="Fecha Recibo"
                                            format="DD/MM/Y"
                                            disablePast={editable}
                                            value={headerData.fechaRecibo}
                                            onChange={moment => changeHeaderData('fechaRecibo', moment)}
                                            readOnly={editable === false}
                                            InputProps={{
                                                style: {width: '6.8rem'},
                                                readOnly: editable === false,
                                                onClick: (e) => editable === false && e.stopPropagation()
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Recibo N°"
                                        className='mr-2'
                                        value={headerData.recibo}
                                        onChange={event => changeHeaderData('recibo', event.target.value)}
                                        InputProps={{
                                            inputComponent: operation !== 'VIEW' ? InputInteger : undefined,
                                            style: {width: '5rem'},
                                            readOnly: operation !== 'CREATE' && operation !== 'EDIT'
                                        }}
                                    />
                                </Grid>
                                {(loggedUser.role === 'A' || descuentoFijo.enabled) && (
                                    <Grid item container spacing={1} className={'w-auto'}>
                                        <Grid item>
                                            <Typography
                                                variant={"caption"}
                                                component={'div'}
                                                style={{
                                                    color: 'rgba(117, 117, 117, 1)',
                                                    lineHeight: '0.9rem'
                                                }}
                                            >
                                                Desc. Fijo
                                            </Typography>
                                            <Switch
                                                disabled={operation === 'VIEW' || loggedUser.role !== 'A'}
                                                checked={descuentoFijo.enabled}
                                                onChange={this.props.toggleDescuentoFijo}
                                            />
                                        </Grid>
                                        {descuentoFijo.enabled && (
                                            <Grid item>
                                                <TextField
                                                    select
                                                    label="Tipo"
                                                    className='mr-2'
                                                    value={descuentoFijo.tipo}
                                                    onChange={event => this.props.changeDescuentoTipo(event.target.value)}
                                                    InputProps={{
                                                        readOnly: operation === 'VIEW' || loggedUser.role !== 'A'
                                                    }}
                                                >
                                                    {[{id: 'importe', tipo: '$'}, {id: 'porcentaje', tipo: '%'}].map(option => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.tipo}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    label="Descuento"
                                                    value={descuentoFijo.tipo === 'importe' ? descuentoFijo.importe : descuentoFijo.porcentaje}
                                                    onChange={event => this.props.changeDescuentoValue(event.target.value)}
                                                    InputProps={{
                                                        inputComponent: descuentoFijo.tipo === 'importe' ? InputPrice : InputPercentage,
                                                        style: descuentoFijo.tipo === 'importe' ? {width: '6rem'} : {width: '3rem'},
                                                        readOnly: operation === 'VIEW' || loggedUser.role !== 'A'
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                                {(operation === 'VIEW' || operation === 'EDIT') && (
                                    <Grid item className={'align-self-baseline'}>
                                        <Typography variant={"caption"} component={"div"} color={"textSecondary"}>
                                            Estado
                                        </Typography>
                                        <Tooltip title={getCobranzaStatusInfo(headerData.status).tooltip}>
                                            <div className={'d-inline-flex align-items-center'}>
                                                {getCobranzaStatusInfo(headerData.status).icon}
                                                <Typography
                                                    className={'ml-1'}
                                                    component={"span"}
                                                    variant={"body2"}
                                                    color={getCobranzaStatusInfo(headerData.status).color}>
                                                    {getCobranzaStatusInfo(headerData.status).description}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {loggedUser.role === 'A' && operation === 'VIEW' && (
                                    <Grid item className={'flex-grow-1 text-right'}>
                                        <Tooltip title={'Eliminar Cobranza'}>
                                            <Button className={'background-error'} style={{color: 'white'}} onClick={this.props.onDelete}>
                                                <DeleteIcon fontSize={"small"}/> ELIMINAR
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {operation === 'CREATE' && (
                                    <Grid item className={'flex-grow-1 text-right'}>
                                        <Tooltip title={'Ingresar Cobranza'}>
                                            <Button variant="contained" color={"primary"} onClick={this.props.onCreate}>
                                                <DoneIcon fontSize={"small"}/> INGRESAR
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {operation === 'EDIT' && (
                                    <Grid item className={'flex-grow-1 text-right'}>
                                        <Tooltip title={'Editar Cobranza'}>
                                            <Button variant="contained" color={"primary"} onClick={this.props.onEdit}>
                                                <DoneIcon fontSize={"small"}/> CONFIRMAR
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box className={'mt-2'}>
                        <Grid container direction={"column"} spacing={1}>
                            <Grid item container wrap={"nowrap"} spacing={2}>
                                <Grid item xs={6}>
                                    <Card className={'pr-1 pl-2 pt-2 pb-2 h-100'}>
                                        <CobranzaDocumentosList
                                            documentos={documentosACancelar}
                                            cobranza={cobranza}
                                            dateValidationError={dateValidationError}
                                            notaCreditoValidationError={notaCreditoValidationError}
                                            onChangeImporte={this.props.changeDocumentoImporte}
                                            onRemove={this.props.removeDocumento}
                                            onRemoveAll={this.props.removeAllDocumentos}
                                            onAdjust={this.props.adjustDocumentos}
                                            setMinImporte={this.props.setMinDocumentoImporte}
                                            setMaxImporte={this.props.setMaxDocumentoImporte}
                                            showInfoDocumento={this.props.showInfoDocumento}
                                            onAdd={this.props.openDocumentosAddDialog}
                                            operation={operation}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card className={'pr-1 pl-2 pt-2 pb-2 h-100'}>
                                        <CobranzaItemsList
                                            items={itemsDeCobranza}
                                            headerData={headerData}
                                            cobranza={cobranza}
                                            cliente={cliente}
                                            TIPOS={TIPOS}
                                            itemValidationError={itemValidationError}
                                            onChangeTipo={this.props.changeItemTipo}
                                            onChangeFecha={this.props.changeItemFecha}
                                            onChangeImporte={this.props.changeItemImporte}
                                            onAdjustItem={this.props.adjustItem}
                                            onRemove={this.props.removeItem}
                                            onRemoveAll={this.props.removeAllItems}
                                            onEdit={this.props.handleEditItem}
                                            onAdd={this.props.addItem}
                                            operation={operation}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item container wrap={"nowrap"} spacing={2}>
                                <Grid item xs={6}>
                                    <Card className={'pt-2 pb-2 pr-3 pl-3 h-100'}>
                                        <Grid container direction={"column"} spacing={1}>
                                            <Grid container item direction={"row"}>
                                                <Typography variant={"caption"} className={'font-weight-bold'}>Total a Cancelar</Typography>
                                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                                <Typography variant={"caption"} align={"right"} className={'font-weight-bold'}>
                                                    {formatPrice(cobranza.documentos.total, true)}
                                                </Typography>
                                                {(cobranza.documentos.scc > 0 && (
                                                    <Typography variant={"caption"} align={"right"}>
                                                        &nbsp;({formatPrice(cobranza.documentos.total + cobranza.documentos.scc, true)}&nbsp;
                                                        - SCC: {formatPrice(cobranza.documentos.scc, true)})
                                                    </Typography>
                                                ))}
                                            </Grid>

                                            <Grid item container justify={"flex-end"}>
                                                <Tooltip title={'Agregar Ajuste Plazo'}>
                                                    <span>
                                                        <Button
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            color={"primary"}
                                                            disabled={!editable}
                                                            onClick={this.props.openAjustesPlazoAddDialog}
                                                        >
                                                            + Ajuste Plazo
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Grid>

                                            <List dense>
                                                {ajustesPlazo.map(ajuste => (
                                                    <ListItem key={ajuste.id}>
                                                        <ListItemText secondary={`${ajuste.tipo} ${ajuste.numero}`}/>
                                                        <ListItemText secondary={`${ajuste.ingreso} (${ajuste.dias} días)`}/>
                                                        <ListItemText secondary={`${formatPrice(ajuste.total, true)}`}/>
                                                        <ListItemSecondaryAction>
                                                            <Tooltip title={'Quitar Ajuste Plazo'}>
                                                                <span>
                                                                    <IconButton
                                                                        disabled={!editable}
                                                                        onClick={() => this.props.removeAjustePlazo(ajuste.id)}
                                                                    >
                                                                        <DeleteIcon fontSize={"small"}/>
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card className={'pt-2 pb-2 pr-3 pl-3 h-100'}>
                                        <Grid container direction={"row"}>
                                            <Typography variant={"caption"} className={'font-weight-bold'}>Total a Cobrar</Typography>
                                            <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                            <Typography variant={"caption"} align={"right"} className={'font-weight-bold'}>
                                                {formatPrice(cobranza.items.total, true)}
                                            </Typography>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                            <Typography
                                                variant={"caption"}
                                                color={descuentoError ? "error" : "primary"}>
                                                Descuento ({cobranza.descuento.porcentaje}%)
                                            </Typography>
                                            <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                            <Typography variant={"caption"} align={"right"} color={descuentoError ? "error" : "primary"}>
                                                {formatPrice(cobranza.descuento.importe, true)}&nbsp;
                                                ({formatPrice(cobranza.descuento.importe_sin_iva, true)} + IVA: {formatPrice(cobranza.descuento.iva, true)})
                                            </Typography>
                                        </Grid>
                                        {cobranza.ajustes_plazo.total > 0 && (
                                            <Grid container direction={"row"}>
                                                <Typography variant={"caption"} color={cobranza.saldo_total_cliente < 0 ? "error" : "textPrimary"}>
                                                    Ajuste Plazo
                                                </Typography>
                                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                                <Typography variant={"caption"} align={"right"}>
                                                    {formatPrice(cobranza.ajustes_plazo.total, true)}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid container direction={"row"}>
                                            <Typography variant={"caption"}>Total a Cancelar</Typography>
                                            <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                            <Typography variant={"caption"} align={"right"}>
                                                {formatPrice(parseFloat(cobranza.items.total) + parseFloat(cobranza.descuento.importe) + parseFloat(cobranza.ajustes_plazo.total), true)}
                                            </Typography>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                            <Typography variant={"caption"} color={cobranza.saldo_cliente < 0 ? "error" : "textPrimary"}>
                                                Saldo
                                            </Typography>
                                            <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                            <Typography variant={"caption"} align={"right"} color={cobranza.saldo_cliente < 0 ? "error" : "textPrimary"}>
                                                {formatPrice(cobranza.saldo_cliente, true)}
                                            </Typography>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        )
    }
}

CobranzasOperation.contextType = MainContext;
CobranzasOperation = withSnackbar(CobranzasOperation);
CobranzasOperation.defaultProps = {
    operation: 'CREATE',
    cliente: {},
    cobranzas: {
        columns: [],
        data: []
    },
    vendedores: [],
    talrecibos: [],
    headerData: {
        talrecibo: '3',
        jurisdiccion: '',
        observaciones: '',
        usuario: '',
        vendedor: '',
        ingreso: (new MomentUtils()).date(new Date()),
    }
};
