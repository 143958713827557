import BaseService from './base.service'

export default class CobranzasService extends BaseService {
    getAll(fnSuccess, fnError) {
        return this.getApi().get(`/cobranzas`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getById(id, fnSuccess, fnError) {
        return this.getApi().get(`/cobranzas?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getByCliente(cliente, fnSuccess, fnError) {
        return this.getApi().get(`/cobranzas?cliente=${cliente}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getFiltered(options, fnSuccess, fnError) {
        return this.getApi().post(`/cobranzas/getFiltered`, options)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    create(cliente, data, fnSuccess, fnError) {
        return this.getApi().post(`/cobranzas?cliente=${cliente}`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    edit(id, data, fnSuccess, fnError) {
        return this.getApi().put(`/cobranzas?id=${id}`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    changeStatus(id, status, fnSuccess, fnError) {
        return this.getApi().put(`/cobranzas/changeStatus?id=${id}&status=${status}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getDescuentos(fnSuccess, fnError) {
        return this.getApi().get(`/cobranzas/descuentos`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getPDF(id, fnSuccess, fnError) {
        this.getApi()({
            url: '/cobranzas/pdf',
            method: 'get',
            params: {
                id: id
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de la cobranza')));
    }

    deleteById(id, fnSuccess, fnError) {
        return this.getApi().delete(`/cobranzas?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }
}
