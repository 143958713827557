import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import ArticulosService from "services/articulos.service";
import {withSnackbar} from "notistack";
import {ArticuloDetailDialog} from "./dialogs/articuloDetail.dialog";
import './articulos.list.scss';
import {MainContext} from "contexts/main.context";
import SubHeader from "../../helpers/subHeader/subHeader";

export class ArticulosList extends Component {
    state = {
        columns: [
            {name: 'id', title: 'Código', width: 140},
            {name: 'detalle', title: 'Detalle'},
        ],
        rows: [],
        dialogDetailOpened: false,
        selectedItem: {},
        selectedItemImage: null
    };

    ArticulosService = new ArticulosService(this.context);

    componentDidMount() {
        this.ArticulosService.getAll(
            null,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/${row.id}/view`);
    };

    onOpenDialogDetail = (row) => {
        this.setState(prevState => {
            prevState.selectedItem = row;
            prevState.dialogDetailOpened = true;
            return prevState;
        });

        this.ArticulosService.getImage(
            row.id,
            response => this.setState({selectedItemImage: response}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    onCloseDialogDetail = () => this.setState({dialogDetailOpened: false});

    render() {
        const {columns, rows, dialogDetailOpened, selectedItem, selectedItemImage} = this.state;
        return (
            <React.Fragment>
                <Box className='niquel-articulos-list'>
                    <SubHeader
                        title={'Artículos'}
                    />
                    <Box className='container-xl mt-3 mb-3'>
                        <ReactGrid
                            title={'Artículos'}
                            columns={columns}
                            rows={rows}
                            //height={500}
                            showFilters={false}
                            rowClick={true}
                            onRowClick={this.onOpenDialogDetail}
                        />
                    </Box>
                </Box>
                <ArticuloDetailDialog
                    open={dialogDetailOpened}
                    item={selectedItem}
                    image={selectedItemImage}
                    onCancel={this.onCloseDialogDetail}
                />
            </React.Fragment>
        )
    }
}

ArticulosList.contextType = MainContext;
ArticulosList = withSnackbar(ArticulosList);