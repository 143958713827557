import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core/index";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {Grid, TextField, Typography} from "@material-ui/core";
import {InputPrice} from "assets/utils";

export class ConfirmDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                // maxWidth={"md"}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ConfirmDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ConfirmDialogContent extends Component {
    state = {
        fecha: (new MomentUtils()).parse(this.props.item.fecha, 'D/MM/Y'),
        importe: this.props.item.importe
    };

    // Es necesario esto?
    componentDidMount() {
        this.setState({
            fecha: (new MomentUtils()).parse(this.props.item.fecha, 'D/MM/Y'),
            importe: this.props.item.importe,
        });
    }

    onConfirm = () => {
        let item = this.props.item;
        item.fecha = this.state.fecha.format('DD/MM/Y');
        item.importe = this.state.importe;
        this.props.onConfirm(item);
    }

    render() {
        const {item, onClose} = this.props;
        const {fecha, importe} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Confirmar Recepción</DialogTitle>
                <DialogContent>
                    {/*<Box style={{width: '7rem', borderBottom: '1px solid', marginBottom: '1rem'}}>*/}
                    {/*    <Typography variant={"caption"}>Transferencia</Typography>*/}
                    {/*</Box>*/}
                    <Grid container direction={"row"} spacing={3} className={'mb-3'}>
                        {item.tipo !== 'CEL' && (
                            <>
                                <Grid item>
                                    <Typography variant={"caption"} color={"textSecondary"}>N° Transfe</Typography>
                                    <Typography variant={"body2"}>{item.numero}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"caption"} color={"textSecondary"}>Cuenta</Typography>
                                    <Typography variant={"body2"}>{item.cuenta_desc}</Typography>
                                </Grid>
                            </>
                        )}

                        {item.tipo == 'CEL' && (
                            <>
                                <Grid item>
                                    <Typography variant={"caption"} color={"textSecondary"}>N° Cheque</Typography>
                                    <Typography variant={"body2"}>{item.numero}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant={"caption"} color={"textSecondary"}>Banco</Typography>
                                    <Typography variant={"body2"}>{item.banco_desc}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    <TextField
                        fullWidth
                        className={'mb-4'}
                        label={'Importe'}
                        value={importe}
                        onChange={(event) => this.setState({importe: event.target.value})}
                        // disabled={item.tipo === 'CEL'}
                        InputProps={{
                            // readOnly: item.tipo === 'CEL',
                            inputComponent: InputPrice,
                        }}
                    />

                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            autoOk
                            fullWidth
                            autoFocus
                            className={'niquel-date-input'}
                            label="Fecha"
                            format="DD/MM/Y"
                            value={fecha}
                            // disabled={item.tipo === 'CEL'}
                            onChange={moment => this.setState({fecha: moment})}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {'Cerrar'}
                    </Button>
                    <Button onClick={this.onConfirm} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    };
}
