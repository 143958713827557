import React, {Component} from 'react'
import {ClientesService, CobranzasService, MastersService} from "services";
import {MainContext} from "contexts/main.context";
import 'moment/locale/es';
import {withSnackbar} from "notistack";
import {CobranzaAjustePlazoAddDialog, CobranzaDocumentosAddDialog} from "components/helpers/cobranzas/documentos/cobranza.documentos.add.dialog";
import MomentUtils from "@date-io/moment";
import {Box} from "@material-ui/core";
import {CobranzasOperation} from "components/ventas/cobranzas/operation/cobranzas.operation";
import {CobranzaDocumentoInfoDialog} from "components/helpers/cobranzas/documentos/cobranza.documento.info.dialog";
import {ConfirmationDialog} from "components/ventas/cobranzas/create/dialogs/confirm.dialog";
import cloneDeep from "lodash/cloneDeep";
import {formatPrice, roundPrice} from "assets/utils";
import CobranzaItemsEditDialog from "components/helpers/cobranzas/items/cobranza.items.edit.dialog";
import {TIPOS} from "components/helpers/cobranzas/tipos";

export class CobranzasCreate extends Component {
    state = {
        operation: 'CREATE',
        cliente: {
            saldoCuentaCorriente: 0
        },
        _documentos: [],
        _descuentos: [],
        headerData: {
            recibo: '',
            usuario: this.context.loggedUser.name,
            fechaRecibo: (new MomentUtils()).date(new Date()),
        },
        documentosACancelar: [],
        itemsDeCobranza: [],
        ajustesPlazo: [],
        cobranza: {
            documentos: {
                total: 0,
                dias: {
                    total: 0,
                    promedio: 0
                }
            },
            items: {
                total: 0,
                retenciones: 0,
                dias: {
                    total: 0,
                    promedio: 0
                }
            },
            ajustes_plazo: {
                total: 0,
            },
            descuento: {
                porcentaje: 0,
                importe: 0
            }
        },
        documentosAddDialogOpen: false,
        ajustesPlazoAddDialogOpen: false,
        documentoInfoDialogOpen: false,
        showingDocumento: null,
        dateValidationError: false,
        notaCreditoValidationError: false,
        confirmDialogOpen: false,
        editItemDialogOpen: false,
        itemInEdition: null,
        itemValidationError: false,
        descuentoFijo: {
            enabled: false,
            tipo: 'porcentaje',
            importe: 0,
            porcentaje: 0
        },
        _originalDescuento: {},
        descuentoError: false
    };

    MastersService = new MastersService(this.context);
    ClientesService = new ClientesService(this.context);
    CobranzasService = new CobranzasService(this.context);

    componentDidMount() {
        // Si existe una cobranza de referencia
        if (this.props.match.params.cobranza) {
            this.setState({operation: 'EDIT', id: this.props.match.params.cobranza});
            this.loadCobranzaReferencia(this.props.match.params.cobranza);
        }

        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => {
                this.setState(prevState => {
                    prevState.cliente = response.data.result;
                    prevState.headerData.vendedor = response.data.result.vendedor;
                    return prevState;
                }, () => {
                    this.ClientesService.getSaldoCuentaCorriente(
                        this.props.match.params.cliente,
                        response => {
                            this.setState(prevState => {
                                prevState.cliente.saldoCuentaCorriente = response.data.result;
                                const itemSCC = prevState.itemsDeCobranza.find(item => item.tipo === 'SCC');
                                if (itemSCC)
                                    prevState.cliente.saldoCuentaCorriente += itemSCC.importe;
                            })
                        },
                        error => this.props.enqueueSnackbar(error, {variant: 'error'})
                    );
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getDocumentosACancelar(
            this.props.match.params.cliente,
            response => {
                let documentos = response.data.result;
                documentos.forEach(documento => {
                    const ingreso = (new MomentUtils()).parse(documento.ingreso, 'D/MM/Y');
                    documento.dias = this.state.headerData.fechaRecibo.diff(ingreso, 'days');
                    if (documento.tipo === "AP") {
                        documento.total *= -1;
                        documento.pendiente *= -1;
                    }

                });
                this.setState({_documentos: documentos})
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CobranzasService.getDescuentos(
            response => this.setState({_descuentos: response.data.result}, this.calculateCobranza),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    loadCobranzaReferencia = (cobranza) => {
        this.CobranzasService.getById(
            cobranza,
            response => {
                const res = response.data.result;
                this.setState(prevState => {
                    prevState.headerData = {
                        recibo: res.numrec,
                        usuario: res.usuario,
                        fechaRecibo: (new MomentUtils()).parse(res.fecharecibo, 'D/MM/Y'),
                        status: res.status
                    };

                    // Limitación 25/05/20 -> Al editar un documento solo se puede cargar un importe igual o menor al que tenía.
                    // De esta manera se evitan algunos problemas, por ejemplo que la factura actual haya sido cancelada
                    // por una cobranza mas nueva.
                    prevState.documentosACancelar = res.documentos.filter(documento => documento.tipo !== 'AP');
                    for (let i in prevState.documentosACancelar) {
                        let documento = prevState.documentosACancelar[i];
                        // doumento.pendiente no es el pendiente real sino el que tenía cuando se cargó esta cobranza.
                        // Seteo este pendiente para recrear las condiciones del documento al momento de cargarse y evitar
                        // descontar las percepciones en el calculo del descuento si no corresponde (es decir, si se descontaron en otro
                        // pago parcial posterior).
                        documento.pendiente = parseFloat(documento.pendi) + parseFloat(documento.importe);
                        // Seteo la propiedad max_importe para evitar que se ingrese un importe mayor al ingresado originalmente.
                        documento.max_importe = documento.importe;
                        // documento.pendiente = parseFloat(documento.importe);
                    }

                    prevState.itemsDeCobranza = res.items;
                    for (let i in prevState.itemsDeCobranza) {
                        let item = prevState.itemsDeCobranza[i];

                        item.fecha = (new MomentUtils()).parse(item.fecha, 'D/MM/Y');
                        item.transferencia = {
                            cuenta: item.cuenta,
                            numero: item.numero
                        }

                        for (let j in item.cheques) {
                            let cheque = item.cheques[j];
                            cheque.fecha = (new MomentUtils()).parse(cheque.fecha, 'D/MM/Y');
                            cheque.banco = {id: cheque.banco}
                        }

                        if (item.tipo === 'VAL')
                            item.importe = item.cheques.reduce((suma, cheque) => suma + parseFloat(cheque.importe), 0);
                    }

                    prevState.ajustesPlazo = res.documentos.filter(documento => documento.tipo === 'AP');
                    prevState.ajustesPlazo.forEach(ajuste => {
                        ajuste.total *= -1;
                        ajuste.importe *= -1;
                        ajuste.pendiente *= -1;
                    });

                    let itemSCC = prevState.itemsDeCobranza.find(item => item.tipo === 'SCC');

                    prevState.cobranza = {
                        saldo_cliente: res.saldo,
                        saldo_total_cliente: res.saldototal,
                        documentos: {
                            _array: prevState.documentosACancelar,
                            total: res.totdocs,
                            scc: itemSCC ? parseFloat(itemSCC.importe) : 0,
                            promedio_ponderado: res.dpromdocs,
                            // fecha_ponderada: fechaPonderadaDocumentos
                        },
                        items: {
                            _array: prevState.itemsDeCobranza,
                            total: res.totitems,
                            // retenciones: totalRetenciones,
                            promedio_ponderado: res.dpromitems
                        },
                        ajustes_plazo: {
                            _array: prevState.ajustesPlazo,
                            total: prevState.ajustesPlazo.reduce((count, ajuste) => count + ajuste.total, 0)
                        },
                        descuento: {
                            porcentaje: parseFloat(res.pordesc),
                            importe: parseFloat(res.impdesc),
                            importe_sin_iva: res.impdesc / 1.21,
                            iva: (res.impdesc / 1.21) * 0.21
                        }
                    };

                    prevState.descuentoFijo = {
                        enabled: res.descfijo,
                        tipo: res.tipodesc === 'I' ? 'importe' : 'porcentaje',
                        importe: res.impdesc,
                        porcentaje: res.pordesc
                    };

                    // Guardo el descuento original para comparar los cambios.
                    prevState._originalDescuento = prevState.cobranza.descuento;
                    // prevState.descuentoError = this.context.loggedUser.role !== 'A' && prevState.headerData.status !== 'O' && prevState.operation === 'EDIT' && prevState.cobranza.descuento.importe !== prevState._originalDescuento.importe;

                    return prevState;
                }, this.calculateCobranza)
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    onChangeHeaderData = (field, value) => {
        this.setState(prevState => {
            if (field === 'ingreso') {
                let documentos = this.state._documentos;
                documentos.forEach(documento => {
                    const ingreso = (new MomentUtils()).parse(documento.ingreso, 'D/MM/Y');
                    documento.dias = value.diff(ingreso, 'days');
                });
                prevState.headerData.ingreso = value;
                prevState._documentos = documentos;
                this.calculateCobranza();
            }

            prevState.headerData[field] = value;
            return prevState;
        });
    };

    calculateCobranza = () => {
        // Importe total a cancelar de documentos (ingresados por el usuario)
        let totalDocumentos = this.state.documentosACancelar.reduce((count, documento) => (count + parseFloat(documento.importe)), 0);
        totalDocumentos = Math.round(totalDocumentos * 100) / 100;

        // Importe total a cobrar (ingresado por el usuario)
        let totalItems = this.state.itemsDeCobranza.reduce((count, item) => (count + parseFloat(item.importe)), 0);
        totalItems = Math.round(totalItems * 100) / 100;

        // Importe total ajuste plazo (ingresado por el usuario)
        let totalAjustesPlazo = this.state.ajustesPlazo.reduce((count, item) => (count + parseFloat(item.importe)), 0);
        totalAjustesPlazo = Math.round(totalAjustesPlazo * 100) / 100;

        let itemSCC = this.state.itemsDeCobranza.find(item => item.tipo === 'SCC');

        // Fix 11/03/20 - Los items scc descuentan del importe a cancelar.
        if (itemSCC) {
            itemSCC.importe = roundPrice(itemSCC.importe);
            totalDocumentos -= itemSCC.importe;
            totalItems -= itemSCC.importe;
        }

        // Importe total a cancelar de facturas (ingresadas por el usuario)
        let totalFacturas = this.state.documentosACancelar.reduce((count, documento) => {
            if (documento.tipo === 'FAC')
                return count + parseFloat(documento.importe);
            else
                return count + 0;
        }, 0);
        // Total ponderado documentos
        let totalPonderadoFacturas = this.state.documentosACancelar.reduce(
            (count, documento) => {
                if (documento.tipo === 'FAC')
                    return count + parseFloat(documento.importe) * parseFloat(documento.dias);
                else
                    return count + 0;
            }, 0);
        // Promedio ponderado facturas
        let promedioPonderadoFacturas = (totalFacturas !== 0) ? Math.round(totalPonderadoFacturas / totalFacturas) : 0;
        // Fecha ponderada facturas
        let fechaRecibo = this.state.headerData.fechaRecibo.clone();
        let fechaPonderadaFacturas = fechaRecibo.subtract(promedioPonderadoFacturas, 'days').minutes(0).seconds(0);

        // Diferencia dias entre items de cobranza y fecha ponderada de documentos
        this.state.itemsDeCobranza.forEach(item => {
            if (item.tipo === 'VAL') {
                item.dias = null;
                item.cheques.forEach(cheque => {
                    // FIX 29/12: Se toma la menor fecha entre la del cheque y la del recibo.
                    cheque.fecha = (new MomentUtils()).parse(cheque.fecha, 'D/MM/Y');
                    let fecha_cheque = cheque.fecha < this.state.headerData.fechaRecibo ? this.state.headerData.fechaRecibo.clone() : cheque.fecha.clone();
                    cheque.dias = fecha_cheque.diff(fechaPonderadaFacturas, 'days')
                });
            } else
                item.dias = item.fecha.diff(fechaPonderadaFacturas, 'days')
        });
        // Total ponderado items
        let totalPonderadoItems = 0;
        this.state.itemsDeCobranza.forEach(item => {
            if (item.tipo === 'VAL')
                item.cheques.forEach(cheque => totalPonderadoItems += parseFloat(cheque.importe) * parseFloat(cheque.dias));
            // Fix 10/06/20 - Las retenciones no ponderan.
            else if (item.tipo !== 'SCC' && !item.tipo.startsWith('R'))
                totalPonderadoItems += parseFloat(item.importe) * parseFloat(item.dias);
        });
        totalPonderadoItems = Math.round(totalPonderadoItems * 100) / 100;

        // Fix 10/06/20 - Las retenciones no ponderan.
        let totalRetenciones = this.state.itemsDeCobranza.reduce((count, item) => {
            const importe = item.tipo.startsWith('R') ? parseFloat(item.importe) : 0;
            return count + importe;
        }, 0);
        totalRetenciones = Math.round(totalRetenciones * 100) / 100;

        // Las retenciones y los items SCC no ponderan. totalItems ya tiene descontado el ítem SCC.
        const _totalItems = totalItems - totalRetenciones;

        // Promedio ponderado items
        let promedioPonderadoItems = (_totalItems !== 0) ? Math.round(totalPonderadoItems / _totalItems) : NaN;

        // Obtengo el porcentaje de descuento según el promedio ponderado de items
        let porcentajeDescuento = 0;
        // Si el descuento fijo por porcentaje está habilitado entonces uso ese.
        if (this.state.descuentoFijo.enabled) {
            porcentajeDescuento = this.state.descuentoFijo.tipo === 'porcentaje' ? this.state.descuentoFijo.porcentaje : '-';
        } else {
            for (let i = 0; i < this.state._descuentos.length; i++)
                if (promedioPonderadoItems <= parseInt(this.state._descuentos[i].dias)) {
                    porcentajeDescuento = parseFloat(this.state._descuentos[i].porcentaje);
                    break;
                }
        }

        let totalDeducible = 0;
        let notasCredito = this.state.documentosACancelar.filter(documento => documento.tipo === 'CRE');

        // Calculo el total deducible
        // Fix 29/12: Si hay notas de crédito el total deducible es el monto a cancelar menos la suma de percepciones (de las facturas).
        // Sino el total deducible se calcula por factura.
        if (notasCredito.length > 0) {
            let totalPercepciones = this.state.documentosACancelar.reduce(
                (count, documento) => {
                    if (documento.tipo === 'FAC')
                        return count + parseFloat(documento.percepciones);
                    else
                        return count + 0;
                }, 0);

            totalDeducible = (totalDocumentos - totalPercepciones) / 1.21;
        } else {
            // Las percepciones y el iva se cobran al final, por eso si lo pendiente es menor a la suma de estos no se deduce un descuento.
            // El deducible de cada factura no puede incluir el iva ni las percepciones.
            totalDeducible = this.state.documentosACancelar.reduce((count, documento) => {
                    let pendiente = parseFloat(documento.pendiente);
                    let importe = parseFloat(documento.importe);
                    let percepciones = parseFloat(documento.percepciones);

                    if (pendiente <= percepciones || documento.tipo !== 'FAC')
                        return count + 0;
                    else {
                        let proxPendiente = Math.round((pendiente - importe) * 100) / 100;
                        if (proxPendiente === 0)
                            return count + (importe - percepciones) / 1.21;
                        else if (proxPendiente < percepciones)
                            return count + (importe - (percepciones - proxPendiente)) / 1.21;
                        else
                            return count + importe / 1.21;
                    }
                }, 0
            );
            // Fix 11/03/20 - Resto el monto SCC al total deducible.
            if (itemSCC)
                totalDeducible -= itemSCC.importe;
        }

        // Calculo el importe descontado
        // Si el descuento fijo está habilitado entonces uso ese.
        let importeDescuento = 0;
        if (this.state.descuentoFijo.enabled && this.state.descuentoFijo.tipo === 'importe') {
            importeDescuento = this.state.descuentoFijo.importe / 1.21;
            // Si el importe del descuento es fijo calculo el porcentaje sobre el total a deducir (solo informativo).
            porcentajeDescuento = Math.round((importeDescuento * 100 / totalDeducible) * 100) / 100;
        } else
            importeDescuento = totalDeducible * porcentajeDescuento / 100;

        // Fix para evitar descuentos negativos
        importeDescuento = Math.max(importeDescuento, 0);

        let ivaDescuento = importeDescuento * 0.21;
        let saldoCliente = totalItems + importeDescuento + ivaDescuento + totalAjustesPlazo - totalDocumentos;

        // 12/05/20 - Queda en desuso el saldo total. Pasado 1 mes eliminar de todos lados incluido el API y el campo de la DB.
        let saldoTotalCliente = 0;

        this.setState(prevState => {
            prevState.cobranza = {
                saldo_cliente: roundPrice(saldoCliente),
                saldo_total_cliente: roundPrice(saldoTotalCliente),
                documentos: {
                    _array: this.state.documentosACancelar,
                    total: roundPrice(totalDocumentos),
                    scc: itemSCC ? parseFloat(itemSCC.importe) : 0,
                    promedio_ponderado: promedioPonderadoFacturas,
                    fecha_ponderada: fechaPonderadaFacturas
                },
                items: {
                    _array: this.state.itemsDeCobranza,
                    total: roundPrice(totalItems),
                    promedio_ponderado: promedioPonderadoItems
                },
                ajustes_plazo: {
                    _array: this.state.ajustesPlazo,
                    total: roundPrice(totalAjustesPlazo),
                },
                descuento: {
                    porcentaje: porcentajeDescuento,
                    importe: roundPrice(importeDescuento + ivaDescuento),
                    importe_sin_iva: roundPrice(importeDescuento),
                    iva: roundPrice(ivaDescuento)
                }
            };

            prevState.descuentoError = this.context.loggedUser.role !== 'A' && prevState.headerData.status !== 'O' && prevState.operation === 'EDIT' && prevState.cobranza.descuento.importe !== prevState._originalDescuento.importe;

            return prevState;
        }, () => console.log(this.state.cobranza));
    };

    isSelectedDocumento = numDocumento => {
        for (let i in this.state.documentosACancelar)
            if (this.state.documentosACancelar[i].numero === numDocumento)
                return true;
        return false;
    };

    validateDocumentsDate = () => {
        for (let i in this.state.documentosACancelar) {
            let documento = this.state.documentosACancelar[i];
            let ingreso = (new MomentUtils()).parse(documento.ingreso, 'D/MM/Y');

            for (let i in this.state._documentos) {
                let _documento = this.state._documentos[i];
                if (_documento.tipo === 'AP')
                    continue;
                let _ingreso = (new MomentUtils()).parse(_documento.ingreso, 'D/MM/Y');
                if (_ingreso < ingreso && !this.isSelectedDocumento(_documento.numero)) {
                    this.setState(
                        {dateValidationError: true},
                        () => this.props.enqueueSnackbar("Existen documentos a cancelar anteriores a los seleccionados", {variant: 'warning'})
                    );
                    return;
                }
            }
        }

        this.setState({dateValidationError: false});
    };

    validateNotasCredito = () => {
        let seleccionadas = this.state.documentosACancelar.reduce((count, documento) => {
            return (documento.tipo === 'CRE') ? count + 1 : count + 0;
        }, 0);
        let disponibles = this.state._documentos.reduce((count, documento) => {
            return (documento.tipo === 'CRE') ? count + 1 : count + 0;
        }, 0);

        if (seleccionadas !== disponibles)
            this.setState(
                {notaCreditoValidationError: true},
                () => this.props.enqueueSnackbar("Existen notas de crédito pendientes de cancelar", {variant: 'warning'})
            );
        else
            this.setState({notaCreditoValidationError: false});
    };

    confirmDocumentosAddDialog = documentos => {
        documentos.forEach(documento => {
            documento.importe = documento.pendiente;
        });

        this.setState(
            prevState => {
                prevState.documentosACancelar = prevState.documentosACancelar.concat(documentos);
                prevState.dateValidationError = false;
                return prevState;
            },
            () => {
                this.calculateCobranza();
                this.closeDocumentosAddDialog();
                this.validateDocumentsDate();
                this.validateNotasCredito();
            },
        )
    };
    openDocumentosAddDialog = () => this.setState({documentosAddDialogOpen: true});
    closeDocumentosAddDialog = () => this.setState({documentosAddDialogOpen: false});

    changeDocumentoImporte = (id, importe) => {
        this.setState(prevState => {
            prevState.documentosACancelar.forEach(documento => {
                // Solo se puede modificar el importe de facturas y notas de debito.
                if (documento.id === id && (documento.tipo === 'FAC' || documento.tipo === 'DEB')) {
                    documento.importe = importe;
                }
            });
            return prevState;
        }, this.calculateCobranza);
    };
    setMinDocumentoImporte = (documento, withMessage = false) => {
        let pendiente = parseFloat(documento.pendiente);
        let percepciones = parseFloat(documento.percepciones);
        let importe = (pendiente < percepciones) ? pendiente : percepciones;
        this.changeDocumentoImporte(documento.id, importe);
        if (withMessage)
            setTimeout(() => {
                if (pendiente < percepciones)
                    this.props.enqueueSnackbar(`Documento ${documento.numero}: el mínimo a cancelar es el monto pendiente (${formatPrice(importe, true)})`);
                else
                    this.props.enqueueSnackbar(`Documento ${documento.numero}: el mínimo a cancelar es el monto de las percepciones (${formatPrice(importe, true)})`);
            }, 0);
    };
    setMaxDocumentoImporte = (documento, withMessage = false) => {
        let max_importe = documento.pendiente;
        if (this.state.operation === 'EDIT')
            max_importe = documento.max_importe;

        this.changeDocumentoImporte(documento.id, max_importe);
        if (withMessage)
            setTimeout(() => {
                if (this.state.operation === 'EDIT')
                    this.props.enqueueSnackbar(`Edición de documento ${documento.numero}: el monto a cancelar no puede ser mayor que el monto original cancelado (${formatPrice(documento.max_importe, true)})`);
                else
                    this.props.enqueueSnackbar(`Documento ${documento.numero}: el máximo a cancelar es el monto pendiente de la factura (${formatPrice(documento.pendiente, true)})`);

            }, 0);
    };
    removeDocumento = id => this.setState(
        prevState => prevState.documentosACancelar = prevState.documentosACancelar.filter(documento => documento.id !== id),
        () => {
            this.calculateCobranza();
            this.validateDocumentsDate();
            this.validateNotasCredito();
        }
    );
    removeAllDocumentos = () => this.setState(
        prevState => prevState.documentosACancelar = [],
        () => {
            this.calculateCobranza();
            this.validateDocumentsDate();
            this.validateNotasCredito();
        }
    );
    adjustDocumentos = () => {
        let saldo = parseFloat(this.state.cobranza.saldo_cliente);
        let aDocumentos = [...this.state.documentosACancelar];
        aDocumentos.sort((doc1, doc2) => {
            let date1 = (new MomentUtils()).parse(doc1.ingreso, 'D/MM/Y');
            let date2 = (new MomentUtils()).parse(doc2.ingreso, 'D/MM/Y');
            return (date1 === date2) ? 0 : (date1 < date2) ? -1 : 1;
        });
        if (saldo < 0)
            this._adjustDown(aDocumentos.reverse(), saldo);
        else
            this._adjustUp(aDocumentos, saldo);
    };
    _adjustDown = (aDocumentos, saldo) => {
        if (saldo < 0 && aDocumentos.length > 0) {
            saldo *= -1;
            let documento = aDocumentos[0];

            // Fix para notas de crédito
            if (documento.tipo !== 'FAC' && documento.tipo !== 'DEB') {
                aDocumentos.shift();
                setTimeout(() => this._adjustDown(aDocumentos, this.state.cobranza.saldo_cliente), 0);
            }

            // Modificación al cálculo. La factura puede quedar en 0, en ese caso se eliminará.
            // Si queda un importe menor al mínimo entre pendiente y percepciones entonces se setea el mínimo (no se puede cancelar menos que las percepciones).
            let minimo = Math.min(parseFloat(documento.pendiente), parseFloat(documento.percepciones));
            let nuevoImporte = parseFloat(documento.importe) - saldo;
            if (nuevoImporte >= minimo) {
                this.changeDocumentoImporte(documento.id, nuevoImporte);
                setTimeout(() => this._adjustDown(aDocumentos, this.state.cobranza.saldo_cliente), 0);
            } else {
                // 25/05/2020 -> Ahora se pueden ajustar los documentos en la edición de cobranzas, pero no se pueden eliminar (ni agregar nuevos).
                // Agrego chequeo del tipo de operación.
                if (nuevoImporte <= 0 && this.state.operation !== 'EDIT')
                    this.removeDocumento(documento.id);
                else
                    this.changeDocumentoImporte(documento.id, minimo);
                aDocumentos.shift();
                setTimeout(() => this._adjustDown(aDocumentos, this.state.cobranza.saldo_cliente), 0);
            }
        }
    };
    _adjustUp = (aDocumentos, saldo) => {
        if (saldo > 0 && aDocumentos.length > 0) {
            let documento = aDocumentos[0];

            // Fix para notas de crédito
            if (documento.tipo !== 'FAC' && documento.tipo !== 'DEB') {
                aDocumentos.shift();
                setTimeout(() => this._adjustDown(aDocumentos, this.state.cobranza.saldo_cliente), 0);
            }

            let maximo = parseFloat(documento.pendiente);
            let diferencia = maximo - parseFloat(documento.importe);
            if (diferencia > saldo) {
                this.changeDocumentoImporte(documento.id, parseFloat(documento.importe) + saldo);
                setTimeout(() => this._adjustUp(aDocumentos, this.state.cobranza.saldo_cliente), 0);
            } else {
                this.changeDocumentoImporte(documento.id, maximo);
                aDocumentos.shift();
                setTimeout(() => this._adjustUp(aDocumentos, this.state.cobranza.saldo_cliente), 0);
            }
        }
    };
    showDocumentoInfoDialog = id => {
        let documento = this.state.documentosACancelar.find(_documento => _documento.id === id);
        this.setState({documentoInfoDialogOpen: true, showingDocumento: documento});
    };
    closeDocumentoInfoDialog = () => this.setState({documentoInfoDialogOpen: false});

    validateItems = () => {
        this.setState({itemValidationError: false});

        for (let i in this.state.itemsDeCobranza) {
            let item = this.state.itemsDeCobranza[i];
            item.error = false;
            if (item.tipo === 'TRF') {
                if (item.transferencia.cuenta === '' || item.transferencia.numero === '') {
                    item.error = true;
                    this.setState({itemValidationError: true});
                    this.props.enqueueSnackbar("Algunos items requieren datos adicionales.", {variant: 'warning'});
                }
            }
        }
    };

    changeItemTipo = (id, tipoId) => {
        let tipo = TIPOS.find(_tipo => _tipo.id === tipoId);

        // Chequeo que no haya otro ítem de tipo saldo en cuenta corriente.
        if (tipoId === 'SCC')
            if (this.state.itemsDeCobranza.find(item => item.tipo === 'SCC') !== undefined) {
                this.props.enqueueSnackbar("Ya existe un ítem de tipo Saldo en Cuenta Corriente.", {variant: 'warning'});
                return false;
            }

        this.setState(prevState => {
                prevState.itemsDeCobranza.forEach(item => {
                    if (item.id === id) {
                        item.tipo = tipoId;
                        item.leyenda = tipo.description;
                        item.cheques = [];

                        if (tipoId === 'VAL')
                            item.importe = 0;

                        if (tipoId === 'SCC') {
                            item.importe = this.state.cliente.saldoCuentaCorriente;
                            // Update 11/03/20 - Ya no se consideran los días del ítem SCC (se va a descontar del total a cancelar)
                            // let diasSCC = this.state._descuentos[this.state._descuentos.length - 1].dias;
                            // item.fecha = (new MomentUtils()).date(new Date()).add(diasSCC, 'days');
                        }

                        if (item.fecha < (new MomentUtils()).date(new Date()))
                            item.fecha = (new MomentUtils()).date(new Date());
                    }
                });
                return prevState;
            },
            () => {
                this.calculateCobranza();
                this.validateItems();
            })
    };
    changeItemFecha = (id, fecha) => {
        this.setState(prevState => {
            prevState.itemsDeCobranza.forEach(item => {
                if (item.id === id)
                    item.fecha = fecha;
            });
            return prevState;
        }, () => {
            this.calculateCobranza();
            this.validateItems();
        })
    };
    changeItemImporte = (id, importe) => {
        this.setState(prevState => {
            prevState.itemsDeCobranza.forEach(item => {
                if (item.id === id) {
                    item.importe = importe;
                }
            });
            return prevState;
        }, () => {
            this.calculateCobranza();
            this.validateItems();
        })
    };
    adjustItem = (item, _recursion = 0) => {
        _recursion++;
        if (_recursion < 6 && Math.abs(this.state.cobranza.saldo_cliente) > 0.05) {
            let importe = parseFloat(item.importe);
            importe += (this.state.cobranza.saldo_cliente * -1);

            if (item.tipo === 'SCC')
                importe = Math.min(importe, this.state.cliente.saldoCuentaCorriente);

            this.changeItemImporte(item.id, Math.max(importe, 0));
            setTimeout(() => this.adjustItem(item, _recursion), 0);
        }
    };
    addItem = () => this.setState(prevState => prevState.itemsDeCobranza.push({
        id: Math.floor(Math.random() * 10000000),
        tipo: 'EFE',
        fecha: (new MomentUtils()).date(new Date()),
        importe: 0,
        leyenda: 'Efectivo',
        transferencia: {
            numero: '',
            cuenta: ''
        },
        cheques: []
    }), () => {
        this.calculateCobranza();
        this.validateItems();
    });
    handleEditItem = id => {
        let item = this.state.itemsDeCobranza.find(_item => _item.id === id);
        this.setState({editItemDialogOpen: true, itemInEdition: item});
    };
    onConfirmEditItem = item => {
        console.log(item);
        this.closeEditItemDialog();
        this.setState(prevState => {
            for (let i in prevState.itemsDeCobranza) {
                if (prevState.itemsDeCobranza[i].id === item.id)
                    prevState.itemsDeCobranza[i] = item;
            }
            return prevState;
        }, () => {
            this.calculateCobranza();
            this.validateItems();
        })
    };
    closeEditItemDialog = () => this.setState({editItemDialogOpen: false});
    removeItem = id => this.setState(
        prevState => prevState.itemsDeCobranza = prevState.itemsDeCobranza.filter(item => item.id !== id),
        () => {
            this.calculateCobranza();
            this.validateItems();
        }
    );
    removeAllItems = () => this.setState(
        // Borro los items de cobranza (dejo solo los observados, que no pueden borrarse)
        prevState => prevState.itemsDeCobranza = prevState.itemsDeCobranza.filter(item => item.observado === true),
        () => {
            this.calculateCobranza();
            this.validateItems();
        }
    );

    // Ajuste Plazo
    confirmAjustesPlazoAddDialog = documentos => {
        documentos.forEach(documento => {
            documento.importe = documento.pendiente;
        });

        this.setState(
            prevState => {
                prevState.ajustesPlazo = prevState.ajustesPlazo.concat(documentos);
                return prevState;
            },
            () => {
                this.calculateCobranza();
                this.closeAjustesPlazoAddDialog();
            },
        )
    };
    removeAjustePlazo = id => this.setState(
        prevState => prevState.ajustesPlazo = prevState.ajustesPlazo.filter(documento => documento.id !== id),
        this.calculateCobranza
    );
    openAjustesPlazoAddDialog = () => this.setState({ajustesPlazoAddDialogOpen: true});
    closeAjustesPlazoAddDialog = () => this.setState({ajustesPlazoAddDialogOpen: false});

    onCreate = () => {
        if (this.validateCreation())
            this.openConfirmDialog();
    };

    onEdit = () => {
        if (this.validateCreation() && this.validateEdition())
            this.openConfirmDialog();
    };

    validateCreation = () => {
        if (this.state.headerData.recibo.length === 0) {
            this.props.enqueueSnackbar("Debe ingresar un número de recibo", {variant: 'error'});
            return false;
        }

        if (this.state.headerData.recibo.length !== 5) {
            this.props.enqueueSnackbar("El número de recibo no tiene 5 dígitos, ¿es correcto?", {variant: 'warning'});
            // return false;
        }

        if (this.state.documentosACancelar.length === 0) {
            this.props.enqueueSnackbar("Debe seleccionar al menos un documento a cancelar", {variant: 'error'});
            return false;
        }

        if (this.state.itemsDeCobranza.length === 0) {
            this.props.enqueueSnackbar("Debe seleccionar al menos un ítem de cobranza", {variant: 'error'});
            return false;
        }

        if (this.state.cobranza.saldo_cliente < 0) {
            this.props.enqueueSnackbar("El saldo del cliente no puede ser negativo. Aumente el importe a cobrar o reduzca el monto a cancelar.", {variant: 'error'});
            return false;
        }

        if (this.state.cobranza.documentos.total <= 0) {
            this.props.enqueueSnackbar("El total a cancelar debe ser mayor a cero. Aumente el monto a cancelar o elimine notas de crédito.", {variant: 'error'});
            return false;
        }

        if (this.state.cobranza.items.total <= 0) {
            this.props.enqueueSnackbar("El total a cobrar debe ser mayor a cero. Ingrese un importe mayor a los ítems de cobranza.", {variant: 'error'});
            return false;
        }

        if (this.state.itemValidationError === true) {
            this.props.enqueueSnackbar("Algunos items requieren datos adicionales.", {variant: 'error'});
            return false;
        }

        this.removeEmptyDocuments();
        this.removeEmptyItems();

        return true;
    };

    validateEdition = () => {
        // Si el usuario no es admin no puede ingresar un descuento distinto al original, excepto que la cobranza esté observada.
        if (this.context.loggedUser.role !== 'A' && this.state.headerData.status !== 'O' && this.state.cobranza.descuento.importe !== this.state._originalDescuento.importe) {
            this.props.enqueueSnackbar(`El descuento no puede ser distinto a ${formatPrice(this.state._originalDescuento.importe, true)} (${this.state._originalDescuento.porcentaje}%)`, {variant: 'error'});
            return false;
        }

        return true;
    };

    removeEmptyDocuments = () => {
        this.state.documentosACancelar.forEach(documento => {
            if (parseFloat(documento.importe) === 0)
                this.removeItem(documento.id);
        });
    };

    removeEmptyItems = () => {
        this.state.itemsDeCobranza.forEach(item => {
            if (parseFloat(item.importe) === 0)
                this.removeItem(item.id);
        });
    };

    openConfirmDialog = () => this.setState({confirmDialogOpen: true});
    closeConfirmDialog = () => this.setState({confirmDialogOpen: false});
    confirmCobranza = () => {
        if (this.state.operation === 'CREATE')
            this.createCobranza();
        else
            this.editCobranza();
    };
    createCobranza = () => {
        const data = this.getBodyCobranza();

        this.CobranzasService.create(
            this.props.match.params.cliente,
            data,
            response => {
                this.props.enqueueSnackbar(`Se generó correctamente la cobranza transitoria número ${response.data.id}`, {variant: 'success'});
                this.props.history.push(`/ventas/cobranzas/${this.props.match.params.cliente}`);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };
    getBodyCobranza = () => {
        const data = {};
        data.headerData = {...this.state.headerData};
        data.headerData.ingreso = data.headerData.fechaRecibo.format('D/MM/Y');
        data.cobranza = cloneDeep(this.state.cobranza);
        data.cobranza.items._array.forEach(item => {
            item.fecha = item.fecha.format('DD/MM/Y');
            item.cheques.forEach(cheque => {
                cheque.fecha = cheque.fecha.format('DD/MM/Y');
            });
        });

        data.cobranza.ajustes_plazo._array.forEach(documento => {
            if (documento.tipo === "AP") {
                console.log(documento);
                documento.total *= -1;
                documento.pendiente *= -1;
                documento.importe *= -1;
                console.log(documento);
            }
        });

        // Corrijo el valor a cancelar (si hay ajuste plazo)
        data.cobranza.documentos.total -= data.cobranza.ajustes_plazo.total;

        data.descuentoFijo = this.state.descuentoFijo;

        return data;
    };
    editCobranza = () => {
        const data = this.getBodyCobranza();

        this.CobranzasService.edit(
            this.props.match.params.cobranza,
            data,
            response => {
                this.props.enqueueSnackbar(`Se editó correctamente la cobranza transitoria número ${this.props.match.params.cobranza}`, {variant: 'success'});
                this.props.history.push(`/ventas/cobranzas/${this.props.match.params.cliente}`);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    toggleDescuentoFijo = () => this.setState(prevState => (prevState.descuentoFijo.enabled = !prevState.descuentoFijo.enabled),
        this.calculateCobranza
    );
    changeDescuentoValue = value => this.setState(
        prevState => {
            if (prevState.descuentoFijo.tipo === 'importe')
                prevState.descuentoFijo.importe = parseFloat(value);
            else
                prevState.descuentoFijo.porcentaje = parseFloat(value);
            return prevState;
        },
        this.calculateCobranza
    );
    changeDescuentoTipo = tipo => this.setState(prevState => (prevState.descuentoFijo.tipo = tipo), this.calculateCobranza);

    render() {
        const {
            operation, cliente, id, _documentos, headerData, documentosACancelar, itemsDeCobranza, ajustesPlazo, cobranza,
            documentosAddDialogOpen, ajustesPlazoAddDialogOpen, documentoInfoDialogOpen, showingDocumento, dateValidationError,
            notaCreditoValidationError, confirmDialogOpen, editItemDialogOpen, itemInEdition, itemValidationError, descuentoFijo, descuentoError
        } = this.state;

        return (
            <React.Fragment>
                <Box className='niquel-cobranzas-create'>
                    <CobranzasOperation
                        operation={operation}
                        cliente={cliente}
                        documentos={_documentos}
                        headerData={headerData}
                        documentosACancelar={documentosACancelar}
                        itemsDeCobranza={itemsDeCobranza}
                        ajustesPlazo={ajustesPlazo}
                        cobranza={cobranza}
                        dateValidationError={dateValidationError}
                        notaCreditoValidationError={notaCreditoValidationError}
                        itemValidationError={itemValidationError}
                        descuentoError={descuentoError}
                        changeHeaderData={this.onChangeHeaderData}
                        changeDocumentoImporte={this.changeDocumentoImporte}
                        removeDocumento={this.removeDocumento}
                        removeAllDocumentos={this.removeAllDocumentos}
                        adjustDocumentos={this.adjustDocumentos}
                        setMinDocumentoImporte={this.setMinDocumentoImporte}
                        setMaxDocumentoImporte={this.setMaxDocumentoImporte}
                        showInfoDocumento={this.showDocumentoInfoDialog}
                        openDocumentosAddDialog={this.openDocumentosAddDialog}
                        openAjustesPlazoAddDialog={this.openAjustesPlazoAddDialog}
                        removeAjustePlazo={this.removeAjustePlazo}
                        TIPOS={TIPOS}
                        id={id}
                        changeItemTipo={this.changeItemTipo}
                        changeItemFecha={this.changeItemFecha}
                        changeItemImporte={this.changeItemImporte}
                        adjustItem={this.adjustItem}
                        removeItem={this.removeItem}
                        removeAllItems={this.removeAllItems}
                        handleEditItem={this.handleEditItem}
                        addItem={this.addItem}
                        onCreate={this.onCreate}
                        onEdit={this.onEdit}
                        descuentoFijo={descuentoFijo}
                        toggleDescuentoFijo={this.toggleDescuentoFijo}
                        changeDescuentoValue={this.changeDescuentoValue}
                        changeDescuentoTipo={this.changeDescuentoTipo}
                    />
                </Box>

                <CobranzaDocumentosAddDialog
                    open={documentosAddDialogOpen}
                    documentos={_documentos.filter(_documento => _documento.tipo !== 'AP' && documentosACancelar.find(documento => documento.id === _documento.id) === undefined)}
                    cliente={cliente}
                    onConfirm={this.confirmDocumentosAddDialog}
                    onClose={this.closeDocumentosAddDialog}
                />

                <CobranzaAjustePlazoAddDialog
                    open={ajustesPlazoAddDialogOpen}
                    documentos={_documentos.filter(_documento => _documento.tipo === 'AP' && ajustesPlazo.find(_ajuste => _ajuste.id === _documento.id) === undefined)}
                    cliente={cliente}
                    onConfirm={this.confirmAjustesPlazoAddDialog}
                    onClose={this.closeAjustesPlazoAddDialog}
                />

                <CobranzaDocumentoInfoDialog
                    open={documentoInfoDialogOpen}
                    documento={showingDocumento}
                    onClose={this.closeDocumentoInfoDialog}
                />

                <CobranzaItemsEditDialog
                    open={editItemDialogOpen}
                    item={itemInEdition}
                    TIPOS={TIPOS}
                    cobranza={cobranza}
                    onClose={this.closeEditItemDialog}
                    onConfirm={this.onConfirmEditItem}
                />

                <ConfirmationDialog
                    open={confirmDialogOpen}
                    cliente={cliente}
                    cobranza={cobranza}
                    operation={operation}
                    id={this.props.match.params.cobranza}
                    onConfirm={this.confirmCobranza}
                    onCancel={this.closeConfirmDialog}
                />
            </React.Fragment>
        )
    }
}

CobranzasCreate.contextType = MainContext;
CobranzasCreate = withSnackbar(CobranzasCreate);
