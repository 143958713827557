import React from "react";
import {Button, Grid, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import {regExpInteger, regExpPrice,} from "assets/utils.js";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import {DeleteRounded as DeleteIcon} from '@material-ui/icons';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {formatPrice, InputPrice} from "assets/utils";
import Divider from "@material-ui/core/Divider";
import AdjustIcon from "@material-ui/icons/AdjustRounded";

class CobranzaEditFields extends React.Component {
    handleChange = sField => oEvent => {
        let {tipo, leyenda, importe, transferencia, cheques} = this.props.item;
        let value = oEvent.target.value;

        switch (sField) {
            case 'tipo':
                tipo = this.props.TIPOS.find(_tipo => _tipo.id === value);
                leyenda = tipo.description;
                tipo = tipo.id;
                if (tipo === 'VAL')
                    importe = cheques.reduce((suma, cheque) => suma + parseFloat(cheque.importe), 0);
                break;
            case 'leyenda':
                leyenda = value;
                break;
            case 'importe':
                importe = regExpPrice.test(value) ? value : importe;
                // importe = isNaN(importe) ? 0 : importe;
                break;
            case 'numero_transferencia':
                transferencia.numero = regExpInteger.test(value) ? value : transferencia.numero;
                let _cuentaBancaria = this.props.cuentasBancarias.find(cuenta => cuenta.id === transferencia.cuenta);
                if (_cuentaBancaria)
                    leyenda = `Tran.Nº ${transferencia.numero}-Cta#${_cuentaBancaria.description}-Bco#${_cuentaBancaria.codigo}`;
                break;
            case 'cuenta_transferencia':
                transferencia.cuenta = value;
                let cuentaBancaria = this.props.cuentasBancarias.find(cuenta => cuenta.id === value);
                leyenda = `Tran.Nº ${transferencia.numero}-Cta#${cuentaBancaria.description}-Bco#${cuentaBancaria.codigo}`;
                break;
            default:
                value = null;
        }

        this.props.onEditItem({
            ...this.props.item,
            tipo: tipo,
            leyenda: leyenda,
            importe: importe,
            transferencia: transferencia,
            cheques: cheques
        });
    };

    addCheque = () => {
        this.props.onAddCheque();
    };

    removeCheque = index => {
        let item = this.props.item;
        // Elimino el cheque y me lo guardo.
        const cheque = item.cheques.splice(index, 1);

        // Agrego el cheque eliminado al arreglo de cheques eliminados.
        if (typeof item._deleted_cheques === "undefined")
            item._deleted_cheques = cheque;
        else
            item._deleted_cheques = item._deleted_cheques.concat(cheque);

        item.importe = item.cheques.reduce((suma, cheque) => suma + parseFloat(cheque.importe), 0);
        this.props.onEditItem(item);
    };

    onFillImporte = () => {
        let importe = this.props._importe + (this.props.cobranza.saldo_cliente * -1);
        importe = Math.max(importe, 0);
        this.props.onEditItem({
            ...this.props.item,
            importe: importe,
        });
    };

    render() {
        const {item, TIPOS, cuentasBancarias} = this.props;

        return (
            <Grid container direction={"column"} spacing={2}>
                <Grid item style={{width: '100%'}}>
                    <TextField
                        select
                        fullWidth={true}
                        label="Tipo"
                        value={item.tipo}
                        onChange={this.handleChange('tipo')}
                    >
                        {TIPOS.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.description} ({option.id})
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <TextField
                        required
                        fullWidth
                        label="Leyenda"
                        value={item.leyenda}
                        error={item.leyenda === ''}
                        helperText={item.leyenda === '' ? 'Debe ingresar una leyenda' : ''}
                        onChange={this.handleChange('leyenda')}
                        InputProps={{readOnly: item.tipo === 'TRF'}}
                    />
                </Grid>
                {item.tipo === 'TRF' && (
                    <React.Fragment>
                        <Grid item style={{width: '100%'}}>
                            <TextField
                                required
                                label="Numero de Transferencia"
                                fullWidth={true}
                                value={item.transferencia.numero}
                                error={item.transferencia.numero === ''}
                                helperText={item.transferencia.numero === '' ? 'Debe ingresar un número de transferencia' : ''}
                                onChange={this.handleChange('numero_transferencia')}
                            />
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <TextField
                                select
                                required
                                label="Cuenta Bancaria"
                                fullWidth={true}
                                value={item.transferencia.cuenta}
                                error={!item.transferencia.cuenta}
                                helperText={!item.transferencia.cuenta ? 'Debe seleccionar una cuenta bancaria' : ''}
                                onChange={this.handleChange('cuenta_transferencia')}
                            >
                                {cuentasBancarias.map((cuenta, index) => (
                                    <MenuItem key={index} value={cuenta.id}>
                                        {cuenta.id} | {cuenta.nombre} | {cuenta.description} | {cuenta.codigo}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item style={{width: '100%'}}>
                    <TextField
                        required
                        fullWidth={true}
                        label="Importe"
                        value={item.importe}
                        error={item.importe <= 0}
                        helperText={item.tipo !== 'VAL' && item.importe <= 0 ? 'Debe ingresar un importe mayor a cero' : ''}
                        InputProps={{
                            readOnly: item.tipo === 'VAL',
                            disabled: item.tipo === 'VAL',
                            inputComponent: InputPrice,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={'Completar con importe a cancelar'}>
                                        <span>
                                            <IconButton onClick={this.onFillImporte} disabled={item.tipo === 'VAL' || item.tipo.startsWith('R')}>
                                                <AdjustIcon/>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                        onChange={this.handleChange('importe')}
                    />
                </Grid>
                {item.tipo === 'VAL' && (
                    <Grid item style={{width: '100%'}}>
                        <List subheader={
                            <Grid container justify={"space-between"}>
                                <Typography
                                    variant={"caption"}
                                    color={item.cheques.length <= 0 ? "error" : "initial"}
                                >
                                    Cheques ({item.cheques.length})
                                </Typography>
                                <Button
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"small"}
                                    onClick={this.addCheque}
                                >
                                    Agregar Cheque
                                </Button>
                            </Grid>
                        }>
                            {item.cheques.map((cheque, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <ListItem>
                                            <ListItemText
                                                primary={'Electrónico'}
                                                secondary={cheque.iselectro === true ? 'Si' : 'No'}
                                                primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                                secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                                style={{
                                                    flexGrow: '0',
                                                    paddingRight: '20px'
                                                }}
                                            />
                                            <Tooltip title={'Número de Cheque: ' + cheque.numero} placement={"top-start"}>
                                                <ListItemText
                                                    primary={'Número'}
                                                    secondary={'**' + cheque.numero.substring(cheque.numero.length - 8, cheque.numero.length)}
                                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                                    secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                                    style={{
                                                        flexGrow: '0',
                                                        paddingRight: '20px',
                                                        minWidth: '6rem'
                                                    }}
                                                />
                                            </Tooltip>
                                            {cheque.banco.description && (
                                                <Tooltip title={'Banco: ' + cheque.banco.description} placement={"top-start"}>
                                                    <ListItemText
                                                        primary={'Banco'}
                                                        secondary={cheque.banco.description}
                                                        primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                                        secondaryTypographyProps={{
                                                            variant: 'body2',
                                                            color: "textPrimary",
                                                            style: {
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                overflow: "hidden"
                                                            }
                                                        }}
                                                        style={{
                                                            paddingRight: '20px',
                                                            maxWidth: this.props.fullScreen ? 'initial' : '9rem'
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                            <ListItemText
                                                primary={'Fecha de Cobro'}
                                                secondary={cheque.fecha.format("DD/MM/YYYY")}
                                                primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                                secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                                style={{
                                                    flexGrow: '0',
                                                    paddingRight: '20px',
                                                    minWidth: '7rem'
                                                }}
                                            />
                                            <ListItemText
                                                primary={'Importe'}
                                                secondary={formatPrice(cheque.importe, true)}
                                                primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                                secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                                style={{
                                                    textAlign: 'right',
                                                    flexGrow: '0',
                                                    paddingRight: '20px'
                                                }}
                                            />
                                            <ListItemSecondaryAction>
                                                <Tooltip title={'Quitar Cheque'}>
                                                    <IconButton
                                                        onClick={() => this.removeCheque(index)}
                                                    >
                                                        <DeleteIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider/>
                                    </React.Fragment>
                                )
                            })}
                        </List>
                    </Grid>
                )}
            </Grid>
        )
    }
}

CobranzaEditFields.contextType = MainContext;
CobranzaEditFields = withSnackbar(CobranzaEditFields);

export default CobranzaEditFields;
