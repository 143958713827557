export const TIPOS = [
    {id: 'EFE', description: 'Efectivo'},
    {id: 'VAL', description: 'Valores a Depositar'},
    {id: 'CAN', description: 'Compensaciones'},
    {id: 'TRF', description: 'Transferencia Bancaria'},
    {id: 'AEF', description: 'Acreditación Efectivo'},
    {id: 'RSS', description: 'Retención Seguridad Social'},
    {id: 'RIV', description: 'Retención IVA'},
    {id: 'RGA', description: 'Retención Ganancias'},
    {id: 'RIB', description: 'Retención Ing. Brutos'},
    // {id: 'SCC', description: 'Saldo en Cuenta Corriente'},
];
