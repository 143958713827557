import React, {Component} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core/index";
import {formatPrice, formatQuantity} from "assets/utils";
import ImgNotFoundIcon from '@material-ui/icons/PhotoCameraRounded';
import './articuloDetail.dialog.scss';

export class ArticuloDetailDialog extends Component {
    onCancel = () => {
        this.props.onCancel();
    };

    render() {
        const {open, item, image} = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='articulo-detail-dialog'
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={"body1"}>{item.id}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>{item.detalle}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container className='mb-4 item-img'>
                        {image !== null && (
                            <Box className='img-found'>
                                <img src={`data:image/jpeg;base64,${image}`} alt={item.detalle}/>
                            </Box>
                        )}
                        {image === null && (
                            <Box className='img-not-found'>
                                <ImgNotFoundIcon/>
                            </Box>
                        )}
                    </Grid>
                    <Grid container justify={"space-between"}>
                        <Grid item container sm={4} direction={"column"}>
                            <Grid item>
                                <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 1: </Typography>
                                <Typography variant={"body2"} component={'span'}>{formatPrice(item.lista1, true)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 2: </Typography>
                                <Typography variant={"body2"} component={'span'}>{formatPrice(item.lista2, true)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 3: </Typography>
                                <Typography variant={"body2"} component={'span'}>{formatPrice(item.lista3, true)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container sm={4} direction={"column"}>
                            <Grid item>
                                <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 4: </Typography>
                                <Typography variant={"body2"} component={'span'}>{formatPrice(item.lista4, true)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 5: </Typography>
                                <Typography variant={"body2"} component={'span'}>{formatPrice(item.lista5, true)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 6: </Typography>
                                <Typography variant={"body2"} component={'span'}>{formatPrice(item.lista6, true)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container sm={4} direction={"column"} alignItems={"flex-end"} className='column-cantidad'>
                            <Grid item>
                                <Typography variant={"body1"} color={"textSecondary"} component={'span'}>Total: </Typography>
                                <Typography variant={"body1"} component={'span'}>{formatQuantity(item.total)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"} color={"textSecondary"} component={'span'}>Reservado: </Typography>
                                <Typography variant={"body1"} component={'span'}>{formatQuantity(item.reservado)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"} color={"textSecondary"} component={'span'}>Orden Compra: </Typography>
                                <Typography variant={"body1"} component={'span'}>{formatQuantity(item.ordencompra)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancel} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        )
    }
}