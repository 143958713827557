import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from "@material-ui/core/index";
import TxtFileIcon from "@material-ui/icons/InsertDriveFileRounded";
import XlsFileIcon from "@material-ui/icons/DescriptionRounded";
import SaveIcon from "@material-ui/icons/SaveAltRounded";
import ArbaIcon from "@material-ui/icons/CloudUploadRounded";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {TextField} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

export const FilesDialog = ({open, dominio, onChangeDominio, documentos, onDownloadTxt, onDownloadXls, onSendToArba, onClose}) => (
    <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>Generación de archivos</DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                autoFocus
                className={'mb-2'}
                label={'Dominio del Transporte'}
                value={dominio}
                onChange={onChangeDominio}
            />
            <Collapse in={dominio !== ''}>
                <Typography variant={"caption"} color={"textSecondary"}>
                    Documentos seleccionados: {documentos.length}
                </Typography>
                <List dense={false} style={{minWidth: '23rem'}}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <TxtFileIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="TXT"
                            secondary={'Archivo para ARBA'}
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title={'Enviar a ARBA'}>
                                <IconButton edge="end" onClick={onSendToArba} className={'mr-2'}>
                                    <ArbaIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Descargar'}>
                                <IconButton edge="end" onClick={onDownloadTxt}>
                                    <SaveIcon/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <XlsFileIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="XLSX"
                            secondary={'Archivo para Quadminds'}
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title={'Descargar'}>
                                <IconButton edge="end" onClick={onDownloadXls}>
                                    <SaveIcon/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Collapse>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cerrar
            </Button>
        </DialogActions>
    </Dialog>
);
