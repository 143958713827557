import React, {Component} from 'react'
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {Box, Grid, MenuItem, TextField, Tooltip} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import SubHeader from "components/helpers/subHeader/subHeader";
import './clientes.operation.scss';

export class ClientesOperation extends Component {
    render() {
        const {loggedUser} = this.context;
        const {
            tiposDocu, respIVA, provincias, transportes, zonas, vendedores, headerData, onChangeHeaderData, error
        } = this.props;
        // const editable = operation === 'CREATE';

        return (
            <Box className='niquel-clientes-operation'>
                <SubHeader
                    title={'Clientes'}
                    subtitle={'Nuevo Cliente Ocasional'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item container spacing={2}>
                            <Grid item xs={5}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Raz. Soc."
                                    value={headerData.razsoc}
                                    onChange={event => onChangeHeaderData('razsoc', event.target.value)}
                                    error={error && headerData.razsoc === ''}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    label="Tipo Doc."
                                    value={headerData.tipodocu}
                                    onChange={event => onChangeHeaderData('tipodocu', event.target.value)}
                                    error={error && headerData.tipodocu === ''}
                                >
                                    {tiposDocu.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Num Doc."
                                    value={headerData.numdocu}
                                    onChange={event => onChangeHeaderData('numdocu', event.target.value)}
                                    error={error && headerData.numdocu === ''}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Resp. IVA"
                                    value={headerData.respIVA}
                                    onChange={event => onChangeHeaderData('respIVA', event.target.value)}
                                >
                                    {respIVA.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Dirección"
                                    value={headerData.direccion}
                                    onChange={event => onChangeHeaderData('direccion', event.target.value)}
                                    error={error && headerData.direccion === ''}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Localidad"
                                    value={headerData.localidad}
                                    onChange={event => onChangeHeaderData('localidad', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Cod. Postal"
                                    value={headerData.codpostal}
                                    onChange={event => onChangeHeaderData('codpostal', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Provincia"
                                    value={headerData.provincia}
                                    onChange={event => onChangeHeaderData('provincia', event.target.value)}
                                >
                                    {provincias.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Teléfono"
                                    value={headerData.telefono}
                                    onChange={event => onChangeHeaderData('telefono', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Transporte"
                                    value={headerData.transporte}
                                    onChange={event => onChangeHeaderData('transporte', event.target.value)}
                                >
                                    {transportes.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Zona"
                                    value={headerData.zona}
                                    onChange={event => onChangeHeaderData('zona', event.target.value)}
                                >
                                    {zonas.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {(loggedUser.role === 'A' || loggedUser.role === 'S' || loggedUser.role === 'O') && (
                            <Grid item xs={3}>
                                <TextField
                                    select
                                    required
                                    fullWidth
                                    label="Vendedor"
                                    value={headerData.vendedor}
                                    onChange={event => onChangeHeaderData('vendedor', event.target.value)}
                                    error={error && headerData.vendedor === ''}
                                >
                                    {vendedores.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }
}

ClientesOperation.contextType = MainContext;
ClientesOperation = withSnackbar(ClientesOperation);
ClientesOperation.defaultProps = {
    operation: 'CREATE',
    cliente: {},
    cotizaciones: [],
    condComerciales: [],
    vendedores: [],
    articulos: [],
    headerData: {
        bonificacion: 0,
        listaprecio: '1',
        condcomercial: '',
        notacompra: '',
        usuario: '',
        vendedor: '',
        ingreso: (new MomentUtils()).date(new Date()),
        entrega: (new MomentUtils()).date(new Date()),
        leyenda1: '',
        leyenda2: '',
        leyenda3: '',
        leyenda4: ''
    },
    selectedArticulos: [],
    importe: 0,
    importeBonificado: 0
};