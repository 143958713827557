const drawerWidth = 220;

const styles = theme => ({
    niquelMain: {
        left: '0px',
        width: `calc(100% - ${theme.spacing(7)}px)`,
        marginLeft: theme.spacing(7),
        transition: theme.transitions.create(['width', 'margin'], {
            easing: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    niquelMainShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }
});

export default styles;