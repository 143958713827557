import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core/index";

export class LeyendasDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <LeyendasDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class LeyendasDialogContent extends Component {
    state = {
        leyenda1: this.props.leyenda1,
        leyenda2: this.props.leyenda2,
        leyenda3: this.props.leyenda3,
        leyenda4: this.props.leyenda4
    };

    componentDidMount() {
        this.setState({
            leyenda1: this.props.leyenda1,
            leyenda2: this.props.leyenda2,
            leyenda3: this.props.leyenda3,
            leyenda4: this.props.leyenda4
        });
    }

    render() {
        const {onConfirm, onCancel, editable, mode} = this.props;
        const {leyenda1, leyenda2, leyenda3, leyenda4} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Leyendas</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={mode === 'P' ? 'Dirección de entrega' : 'Leyenda 1'}
                        helperText={mode === 'P' ? 'Leyenda reservada.' : ''}
                        value={leyenda1}
                        onChange={event => this.setState({leyenda1: event.target.value})}
                        fullWidth
                        InputProps={{
                            readOnly: editable === false,
                            disabled: mode === 'P'
                        }}
                        inputProps={{
                            maxlength: 50
                        }}
                    />
                    <TextField
                        margin="dense"
                        label={mode === 'P' ? 'Observación de entrega' : 'Leyenda 2'}
                        helperText={mode === 'P' ? 'Leyenda reservada.' : ''}
                        value={leyenda2}
                        onChange={event => this.setState({leyenda2: event.target.value})}
                        fullWidth
                        InputProps={{
                            readOnly: editable === false,
                            disabled: mode === 'P'
                        }}
                        inputProps={{
                            maxlength: 50
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="Leyenda 3"
                        helperText={mode === 'P' ? 'ATENCIÓN! Esta leyenda saldrá impresa en el pedido enviado al cliente.' : ''}
                        value={leyenda3}
                        onChange={event => this.setState({leyenda3: event.target.value})}
                        fullWidth
                        InputProps={{
                            readOnly: editable === false
                        }}
                        inputProps={{
                            maxlength: 50
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="Leyenda 4"
                        helperText={mode === 'P' ? 'Esta leyenda NO saldrá impresa. Puede escribir observaciones internas.' : ''}
                        value={leyenda4}
                        onChange={event => this.setState({leyenda4: event.target.value})}
                        fullWidth
                        InputProps={{
                            readOnly: editable === false
                        }}
                        inputProps={{
                            maxlength: 50
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        {editable ? 'Cancelar' : 'Cerrar'}
                    </Button>
                    {editable && (
                        <Button onClick={() => onConfirm(leyenda1, leyenda2, leyenda3, leyenda4)} color="primary" autoFocus>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    };
}