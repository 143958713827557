import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {CotizacionesOperation} from 'components/ventas/cotizaciones/operation/cotizaciones.operation';
import {ClientesService, CotizacionesService, EmailService, MastersService, VendedoresService} from "services";
import {calcImporteByArticulo} from 'assets/utils';
import MomentUtils from "@date-io/moment/build/index";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";

export class CotizacionesView extends Component {
    state = {
        cliente: {},
        cotizaciones: [],
        id: this.props.match.params.cotizacion,
        condComerciales: [],
        vendedores: [],
        headerData: {
            bonificacion: 0,
            listaprecio: '1',
            condcomercial: '',
            notacompra: '',
            usuario: '',
            vendedor: '',
            ingreso: (new MomentUtils()).date(new Date()),
            entrega: (new MomentUtils()).date(new Date()),
            leyenda1: '',
            leyenda2: '',
            leyenda3: '',
            leyenda4: ''
        },
        selectedArticulos: [],
        generatedId: '',
        importe: 0,
        confirmationDialogOpen: false,
        successDialogOpen: false,
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
    };

    CotizacionesService = new CotizacionesService(this.context);
    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    EmailService = new EmailService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => this.setState({cliente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({cotizaciones: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.loadCotizacionData();
    }

    loadCotizacionData = () => {
        this.CotizacionesService.getById(
            this.state.id,
            response => {
                const {...res} = response.data.result;
                const headerData = {
                    bonificacion: res.bonificacion || 0,
                    listaprecio: res.listaprecio || '1',
                    condcomercial: res.condcomercial || '',
                    notacompra: res.notacompra || '',
                    usuario: res.usuario || '',
                    vendedor: res.vendedor && res.vendedor > 0 ? res.vendedor : '',
                    ingreso: res.ingreso ? (new MomentUtils()).parse(res.ingreso, 'D/MM/Y') : '',
                    entrega: res.entrega ? (new MomentUtils()).parse(res.entrega, 'D/MM/Y') : '',
                    leyenda1: res.leyenda1 || '',
                    leyenda2: res.leyenda2 || '',
                    leyenda3: res.leyenda3 || '',
                    leyenda4: res.leyenda4 || ''
                };
                this.setState({headerData});
                this.setState({importe: parseFloat(res.importe)})
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getArticulosById(
            this.state.id,
            response => {
                let articulos = response.data.result;
                articulos.forEach(articulo => articulo.importe = calcImporteByArticulo(articulo.cantidad, articulo.precio, articulo.bonificacion));
                this.setState({
                    selectedArticulos: articulos,
                    totalArticulos: articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0)
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    onChangeDocument = id => this.setState({id, pdfBlob: null}, () => this.loadCotizacionData());

    getPDF = (fnSuccess = () => {
    }) => {
        if (this.state.pdfBlob != null)
            fnSuccess();
        else
            this.CotizacionesService.getPDF(
                this.state.id,
                file => this.setState({pdfBlob: file}, fnSuccess),
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            )
    };

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
                // window.open(fileURL);
            }
        );
    };

    // Print PDF
    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Cotización ${this.state.id}`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});

    onDialClose = () => this.setState({dialOpen: false});

    render() {
        const {
            cliente, cotizaciones, id, condComerciales, vendedores, articulos, headerData, selectedArticulos, generatedId, importe,
            totalArticulos, mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen
        } = this.state;
        const importeBonificado = importe - importe * headerData.bonificacion / 100;

        return (
            <React.Fragment>
                <Box className='niquel-cotizaciones-view'>
                    <CotizacionesOperation
                        operation='VIEW'
                        cliente={cliente}
                        cotizaciones={cotizaciones}
                        id={id}
                        condComerciales={condComerciales}
                        vendedores={vendedores}
                        articulos={articulos}
                        headerData={headerData}
                        selectedArticulos={selectedArticulos}
                        generatedId={generatedId}
                        importe={importe}
                        totalArticulos={totalArticulos}
                        importeBonificado={importeBonificado}
                        onChangeDocument={this.onChangeDocument}
                    />
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="crear_cotizacion"
                            icon={<PDFIcon/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        action.action()
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`Cotización_${this.state.id}.pdf`}
                    attachFile={true}
                    to={cliente.email}
                    subject={`Cotización ${this.state.id}`}
                    body={`Estimado cliente,  \n\nSe adjunta la cotización número ${this.state.id}.`}
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`Cotización_${this.state.id}.pdf`}
                />
            </React.Fragment>
        )
    }
}

CotizacionesView.contextType = MainContext;
CotizacionesView = withSnackbar(CotizacionesView);
