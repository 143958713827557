import React, {Component} from 'react'
import {Box, Typography} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

export class AfipMain extends Component {
    navCot = () => {
        this.props.history.push(`${this.props.match.url}/COT`);
    };

    render() {
        // const {} = this.state;

        return (
            <Box className='niquel-afip-main'>
                <SubHeader
                    title={'AFIP'}
                    subtitle={'Servicios AFIP'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Card style={{maxWidth: '30rem'}}>
                        <CardActionArea onClick={this.navCot}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    COT
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Generación de archivo para COT y carga automática de remito electrónico.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary" disableRipple>
                                    Acceder
                                </Button>
                            </CardActions>
                        </CardActionArea>
                    </Card>
                </Box>
            </Box>
        )
    }
}

AfipMain.contextType = MainContext;
AfipMain = withSnackbar(AfipMain);
