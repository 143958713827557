import BaseService from './base.service'

export default class AfipService extends BaseService {
    getDocumentosCOT(desde, hasta, fnSuccess, fnError) {
        return this.getApi().get(`/afip/cot/documentos?desde=${desde}&hasta=${hasta}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los documentos de venta')));
    }

    generateFileCOT(facturas, remitos, dominio, action, fnSuccess, fnError) {
        this.getApi()({
            url: '/afip/cot/generateFiles',
            method: 'post',
            data: {
                facturas: facturas,
                remitos: remitos,
                dominio: dominio,
                action: action,
            },
            responseType: action === 'send_to_arba' ? 'json' : 'blob'
        })
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar los archivos de COT')));
    }
}
