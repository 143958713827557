import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {FacturasClientes} from 'components/ventas/facturas/clientes/facturas.clientes';
import {FacturasList} from 'components/ventas/facturas/list/facturas.list';
import {FacturasView} from "components/ventas/facturas/view/facturas.view";

const basePath = '/ventas/facturas';

export class Facturas extends Component {
    render() {
        return (
            <div className='niquel-facturas'>
                <Switch>
                    <Route path={`${basePath}/:cliente/view/:factura`} component={FacturasView}/>
                    <Route exact path={`${basePath}/:cliente`} component={FacturasList}/>
                    <Route path={`${basePath}`} component={FacturasClientes}/>
                </Switch>
            </div>
        )
    }
}