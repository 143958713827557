import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {ArticulosList} from "./articulos/articulos.list";
import {ListasPrecio} from "./listasPrecio/listasPrecio";
import {EquivalenciasFiltros} from "./equivalenciasFiltros/equivalenciasFiltros";
import {MainContext} from "contexts/main.context";

const basePath = '/stock';

export class Stock extends Component {
    componentDidMount() {
        this.context.setHeaderTitle('Stock');
    }

    render() {
        return (
            <div className='niquel-stock'>
                <Switch>
                    <Route path={`${basePath}/articulos`} component={ArticulosList}/>
                    <Route path={`${basePath}/listasPrecio`} component={ListasPrecio}/>
                    <Route path={`${basePath}/equivalenciasFiltros`} component={EquivalenciasFiltros}/>
                </Switch>
            </div>
        )
    }
}

Stock.contextType = MainContext;