import React, {Component} from "react";
import PropTypes from "prop-types";
import {AppBar, Grid, MenuItem, TextField, Toolbar, Typography} from "@material-ui/core";
import './subHeader.styles.scss'

export class SubHeader extends Component {
    render() {
        const {
            title, subtitle, cliente, optionsTitle, options, optionSelectedId, optionsDescriptionField = 'id',
            optionsDescriptionSecondaryField, onChangeOption
        } = this.props;

        return (
            <AppBar position="relative" elevation={0}>
                <Toolbar variant="dense" color='primary' className='pl-4 pr-4'>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item md={4}>
                            <Typography variant="h5" component={'span'} align="left">{title}
                                <Typography variant="body1" align="left" className='text-light'>{subtitle}</Typography>
                            </Typography>
                        </Grid>
                        <Grid item container direction={"row-reverse"} sm={8} spacing={3} alignItems={"center"}>
                            {options && (
                                <Grid item>
                                    <Typography variant={"caption"} component={'div'} className='text-light'>{optionsTitle}</Typography>
                                    <TextField
                                        select
                                        value={optionSelectedId}
                                        onChange={event => onChangeOption(event.target.value)}
                                        classes={{
                                            root: 'sel-options'
                                        }}
                                        SelectProps={{
                                            classes: {
                                                select: 'select',
                                                icon: 'icon'
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true
                                        }}
                                    >
                                        {options.map((option, key) => (
                                            <MenuItem key={key} value={option.id} className={'d-flex align-items-end'}>
                                                {/*{optionsDescriptionField !== 'id' ? `${option.id} - ` : ''}*/}
                                                {option[optionsDescriptionField]}
                                                {optionsDescriptionSecondaryField && (
                                                    <Typography
                                                        variant={"caption"}
                                                        style={{
                                                            opacity: '.8',
                                                            marginLeft: '3px'
                                                        }}
                                                    >
                                                        {` — ${option[optionsDescriptionSecondaryField]}`}
                                                    </Typography>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}

                            {cliente && (
                                <Grid item>
                                    <Typography variant={"caption"} component={'div'} className='text-light'>
                                        Cliente {cliente.id} {cliente.inhabilitado ? ' (INHABILITADO)' : ''}
                                    </Typography>
                                    <Typography variant="body2" component={'div'} align="right">{cliente.razsoc}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }
}

SubHeader.propTypes = {
    title: PropTypes.string.isRequired,
    cliente: PropTypes.object,
    optionsTitle: PropTypes.string,
    options: PropTypes.array,
    optionSelectedId: PropTypes.string,
    onChangeOption: PropTypes.func
};

export default SubHeader;
