import React, {useState} from "react";
import {Box, Button, ClickAwayListener, Fab, Grid, Grow, Paper, Popper, Typography} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {getRoleString} from "assets/utils";

export const UserMenu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <>
            <Fab
                variant={"extended"}
                size={"small"}
                color="primary"
                edge={"end"}
                ref={props.anchorRef}
                onClick={() => setMenuOpen(!menuOpen)}
                classes={{root: 'account-btn'}}
            >
                <AccountCircle/>
                <span className='username'>{props.context.loggedUser.username}</span>
            </Fab>
            <Popper
                open={menuOpen}
                anchorEl={props.anchorRef.current}
                transition
                disablePortal
                placement={"bottom-end"}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: 'top center'}}
                    >
                        <Paper id="menu-list-grow">
                            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                                <Box className='account-content'>
                                    <Box className='pb-4'>
                                        <Box>
                                            <Typography component={'span'}>{props.context.loggedUser.name}</Typography>
                                            <Typography
                                                component={'span'}
                                                color={"textSecondary"}
                                                variant={"body2"}
                                                className='ml-2'
                                            >
                                                {props.context.loggedUser.id ? `(${props.context.loggedUser.id})` : ''}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            component={'span'}
                                            variant={"body2"}
                                            color={"textSecondary"}
                                        >
                                            Rol: {getRoleString(props.context.loggedUser.role)}
                                        </Typography>
                                        <Typography
                                            component={'span'}
                                            variant={"body2"}
                                            color={"textSecondary"}
                                            className='ml-2'
                                        >
                                            Nivel: {props.context.loggedUser.level}
                                        </Typography>
                                    </Box>
                                    <Grid container className='pb-2' justify={"space-between"}>
                                        <Button
                                            disabled
                                            variant={"contained"}
                                            color={"primary"}
                                            size={"small"}
                                        >
                                            Cuenta
                                        </Button>
                                        <Button
                                            //variant={"contained"}
                                            size={"small"}
                                            onClick={props.onLogout}
                                        >
                                            Salir
                                        </Button>
                                    </Grid>
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}
