import * as React from 'react';
import {array, bool, func, number, object, string} from "prop-types";
import {Box, Button, IconButton, Paper, Switch, Tooltip, Typography} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {formatPrice, getCobranzaStatusInfo} from 'assets/utils';
import {connectProps, Plugin, Template, TemplateConnector, TemplatePlaceholder} from '@devexpress/dx-react-core';
import {
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedSelection,
    IntegratedSorting,
    SearchState,
    SelectionState,
    SortingState
} from '@devexpress/dx-react-grid';
import {Grid, SearchPanel, Table, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable} from '@devexpress/dx-react-grid-material-ui';
import './reactGrid.styles.scss';
import DeleteIcon from '@material-ui/icons/ClearRounded';

const localization = {
    Table: {
        noData: 'No hay datos para mostrar'
    },
    TableFilterRow: {
        filterPlaceholder: 'Filtrar',
        contains: 'Contiene',
        notContains: 'No Contiene',
        startsWith: 'Comienza con',
        endsWith: 'Termina con',
        equal: 'Es igual a',
        notEqual: 'Es distinto de',
        greaterThan: 'Es mayor a',
        greaterThanOrEqual: 'Es mayor o igual a',
        lessThan: 'Es menor a',
        lessThanOrEqual: 'Es menor o igual a'
    },
    SearchPanel: {
        searchPlaceholder: 'Buscar...'
    },
    TableHeaderRow: {
        sortingHint: 'Ordenar'
    }
};

function CustomToolbar(props) {
    const {
        rows, title, enableFiltering, showSwitchAll, switchAllValue, onChangeSwitchAll, toggleFilterPanel, showAddButton, onClickAddButton,
        enableClearSelection, selectedRows, onDelete, customBtn1, showResultCount
    } = props;

    return (
        <Plugin name="FilterToggle">
            <Template name="toolbarContent">
                {(enableClearSelection && selectedRows.length > 0) && (
                    <Tooltip title="Cancelar selección">
                        <Box>
                            <IconButton aria-label="Delete" onClick={() => onDelete(selectedRows)} edge={"start"}>
                                <DeleteIcon/>
                            </IconButton>
                        </Box>
                    </Tooltip>
                )}

                <Typography variant='body2' color='textSecondary'>
                    {title}
                    {
                        ((showSwitchAll && switchAllValue === false) || selectedRows.length > 0) ?
                            (` (${selectedRows.length} seleccionados)`) :
                            showResultCount ?
                                (` (${rows.length})`) : ''
                    }
                </Typography>

                <TemplatePlaceholder/>

                <TemplateConnector>
                    {() => (
                        <React.Fragment>
                            {enableFiltering && (!showSwitchAll || !switchAllValue) && (
                                <Tooltip title={'Filtrar'}>
                                    <IconButton aria-label="Filter" onClick={toggleFilterPanel}>
                                        <FilterListIcon/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </React.Fragment>
                    )}
                </TemplateConnector>

                <TemplateConnector>
                    {() => (
                        <React.Fragment>
                            {customBtn1}
                        </React.Fragment>
                    )}
                </TemplateConnector>

                <TemplateConnector>
                    {() => (
                        <React.Fragment>
                            {showSwitchAll && selectedRows.length <= 0 && (
                                <div className={'ml-2'}>
                                    <Switch checked={switchAllValue} onChange={onChangeSwitchAll}/>
                                    <Typography
                                        variant={"caption"}
                                        onClick={onChangeSwitchAll}
                                        style={{cursor: 'pointer'}}
                                        color={switchAllValue ? 'textPrimary' : 'textSecondary'}>todos</Typography>
                                </div>
                            )}

                            {showAddButton && selectedRows.length <= 0 && (
                                <Tooltip title="Agregar item">
                                    <Box>
                                        <Button
                                            variant="outlined"
                                            size='small'
                                            color='primary'
                                            onClick={onClickAddButton}
                                        >
                                            Agregar
                                        </Button>
                                    </Box>
                                </Tooltip>
                            )}
                        </React.Fragment>
                    )}
                </TemplateConnector>
            </Template>
        </Plugin>
    );
}

const TableRow = ({row, rowClick, onRowClick, ...restProps}) => (
    <Table.Row
        {...restProps}
        onClick={() => {
            if (rowClick)
                onRowClick(row);
        }}
        style={{cursor: rowClick ? 'pointer' : 'default'}}
    />
);

const Cell = ({onEdit, preventEditRow, columnExtensions, ...restProps}) => {
    const {column} = restProps;

    if (column.name === 'edit') {
        return (
            <Table.Cell
                {...restProps}
                style={{padding: '0'}}
            >
                {(preventEditRow === null || preventEditRow(restProps.row) === false) && (
                    <Tooltip title={'Editar'} placement="left">
                        <IconButton
                            aria-label="Edit"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEdit(restProps.row, restProps.tableRow.rowId);
                            }}>
                            <EditIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                )}
            </Table.Cell>
        );
    }

    if (column.name.startsWith('custom')) {
        const columnExtension = columnExtensions.find(columnExtension => columnExtension.columnName === column.name);
        const Component = columnExtension.component;
        return (
            <Table.Cell
                {...restProps}
                style={{padding: '0'}}
            >
                <Component row={restProps.tableRow.row} rowId={restProps.rowId}/>
            </Table.Cell>
        );
    }

    return <Table.Cell {...restProps} />;
};

const FilterButton = ({type, ...restProps}) => {
    return <TableFilterRow.ToggleButton style={{marginLeft: '0px', marginRight: '-5px', padding: '5px'}} {...restProps} />;
};

const FilterIcon = ({type, ...restProps}) => {
    return <TableFilterRow.Icon style={{fontSize: '1.4rem'}} type={type} {...restProps} />;
};

const CurrencyFormatter = (
    ({value, classes}) => `${formatPrice(value, true)}`
);

const CurrencyTypeProvider =
    (props) => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

const CheckColumnTypeFormatter = (
    ({value, classes}) => value ? <Tooltip title={""}><CheckIcon color={"primary"}/></Tooltip> : ''
);

const CheckColumnTypeProvider =
    (props) => (
        <DataTypeProvider
            formatterComponent={CheckColumnTypeFormatter}
            {...props}
        />
    );

const ColStatus = (props) => {
    const {icon, color, text, tooltipText} = props;
    return (
        <Tooltip title={tooltipText}>
            <div className={'d-inline-flex align-items-center'}>
                {icon}
                <Typography className={'ml-1'} component={"span"} variant={"body2"} color={color}>{text}</Typography>
            </div>
        </Tooltip>
    )
}

const CobranzaStatusColumnTypeFormatter = (
    ({value, classes}) => {
        const cobranzaInfo = getCobranzaStatusInfo(value);
        return <ColStatus
            icon={cobranzaInfo.icon}
            color={cobranzaInfo.color}
            text={cobranzaInfo.description}
            tooltipText={cobranzaInfo.tooltip}
        />;
    }
);

const CobranzaStatusColumnTypeProvider =
    (props) => (
        <DataTypeProvider
            formatterComponent={CobranzaStatusColumnTypeFormatter}
            {...props}
        />
    );

export class ReactGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: props.columns,
            columnExtensions: props.columnExtensions,
            rows: props.rows,
            showFilters: props.showFilters,
            searchValue: props.searchValue
        };

        this.row = connectProps(TableRow, () => ({rowClick: props.rowClick, onRowClick: props.onRowClick}));
        this.cell = connectProps(Cell, () => ({
            onEdit: props.onEdit,
            preventEditRow: props.preventEditRow,
            columnExtensions: props.columnExtensions
        }));

        // Si el usuario eligió la opción edition agrego la columna
        if (this.props.enableEdition) {
            this.state.columns.push({name: 'edit', title: ' '});
            this.state.columnExtensions.push({columnName: 'edit', sortingEnabled: false, width: 70});
        }
    }

    changeSearchValue = value => this.setState({searchValue: value});
    toggleFilterPanel = () => this.setState(prevState => ({showFilters: !prevState.showFilters}));

    render() {
        const {searchValue, showFilters} = this.state;
        const {
            enableVirtualMode, width, height, rows, columns, columnExtensions, title, enableSorting, enableFiltering, enableSearch, enableSelection,
            selectedRows, onSelectionChange, selectByRowClick, showSelectAll, showSwitchAll, getRowId, elevation, switchAllValue,
            onChangeSwitchAll, showAddButton, onClickAddButton, enableClearSelection, onDelete, customBtn1, showResultCount
        } = this.props;

        let currencyColumns = [];
        let checkColumns = [];
        let cobranzaStatusColumns = [];

        if (columnExtensions) {
            currencyColumns = columnExtensions.map(column => {
                let sReturn;

                if (column.type === 'currency')
                    sReturn = column.columnName;

                return sReturn;
            });

            checkColumns = columnExtensions.map(column => {
                let sReturn;

                if (column.type === 'check')
                    sReturn = column.columnName;

                return sReturn;
            });

            cobranzaStatusColumns = columnExtensions.map(column => {
                let sReturn;

                if (column.type === 'cobranza_status')
                    sReturn = column.columnName;

                return sReturn;
            });
        }

        return (
            <div className='niquel-reactGrid'>
                <Paper elevation={elevation} style={{width: width ? width : 'auto', margin: 'auto'}}>
                    <Grid
                        rows={rows}
                        columns={columns}
                        getRowId={getRowId}
                    >
                        {/*Sorting*/}
                        {enableSorting && (<SortingState defaultSorting={columnExtensions}/>)}
                        {enableSorting && (<IntegratedSorting columnExtensions={columnExtensions}/>)}

                        {/*{Selection}*/}
                        {enableSelection && (
                            <SelectionState
                                selection={selectedRows}
                                onSelectionChange={onSelectionChange}
                            />
                        )}

                        {enableSelection && (<IntegratedSelection/>)}

                        {/*Searching*/}
                        {enableSearch && (
                            <SearchState
                                value={searchValue}
                                onValueChange={this.changeSearchValue}
                            />
                        )}

                        {/*Filter*/}
                        {enableFiltering && (<FilteringState defaultFilters={[]}/>)}
                        <IntegratedFiltering/>

                        <CurrencyTypeProvider for={currencyColumns}/>
                        <CheckColumnTypeProvider for={checkColumns}/>
                        <CobranzaStatusColumnTypeProvider for={cobranzaStatusColumns}/>

                        {enableVirtualMode === true ? (
                            <VirtualTable
                                height={(showSwitchAll && switchAllValue) ? 0 : height}
                                rowComponent={this.row}
                                cellComponent={this.cell}
                                messages={localization.Table}
                                columnExtensions={columnExtensions}
                            />
                        ) : (
                            <Table
                                height={(showSwitchAll && switchAllValue) ? 0 : height}
                                rowComponent={this.row}
                                cellComponent={this.cell}
                                messages={localization.Table}
                                columnExtensions={columnExtensions}
                            />
                        )}

                        <TableHeaderRow showSortingControls={enableSorting} messages={localization.TableHeaderRow}/>

                        {showFilters && (
                            <TableFilterRow
                                showFilterSelector
                                iconComponent={FilterIcon}
                                toggleButtonComponent={FilterButton}
                                messages={localization.TableFilterRow}
                            />
                        )}

                        {(title || enableSearch || enableFiltering) && (<Toolbar/>)}
                        {enableSearch && (!showSwitchAll || !switchAllValue) && (<SearchPanel InputProps={{width: 50}} messages={localization.SearchPanel}/>)}

                        {enableSelection && (<TableSelection selectByRowClick={selectByRowClick} showSelectAll={showSelectAll}/>)}
                        <CustomToolbar
                            rows={rows}
                            title={title}
                            enableFiltering={enableFiltering}
                            showSwitchAll={showSwitchAll}
                            switchAllValue={switchAllValue}
                            onChangeSwitchAll={onChangeSwitchAll}
                            showAddButton={showAddButton}
                            onClickAddButton={onClickAddButton}
                            toggleFilterPanel={this.toggleFilterPanel}
                            enableClearSelection={enableClearSelection}
                            selectedRows={selectedRows}
                            onDelete={onDelete}
                            customBtn1={customBtn1}
                            showResultCount={showResultCount}
                        />
                    </Grid>
                </Paper>
            </div>
        );
    }
}

ReactGrid.defaultProps = {
    enableVirtualMode: true,
    width: undefined,
    height: undefined,
    rows: [],
    columns: [],
    columnExtensions: [],
    title: '',
    enableSorting: true,
    enableSearch: true,
    enableSelection: false,
    enableFiltering: false,
    enableEdition: false,
    enableClearSelection: true,
    showFilters: false,
    searchValue: '',
    defaultFilters: [],
    selectedRows: [],
    onSelectionChange: selected => false,
    selectByRowClick: true,
    showSelectAll: true,
    showSwitchAll: false,
    switchAllValue: true,
    onChangeSwitchAll: () => {
    },
    getRowId: null,
    elevation: 1,
    showAddButton: false,
    onClickAddButton: () => {
    },
    onEdit: (row, rowId) => {
    },
    preventEditRow: null,
    onDelete: selected => {
    },
    customBtn1: null,
    showResultCount: false
};

ReactGrid.propTypes = {
    enableVirtualMode: bool,
    width: number,
    height: number,
    rows: array,
    columns: array,
    columnExtensions: array,
    title: string,
    enableSorting: bool,
    enableSearch: bool,
    enableSelection: bool,
    enableFiltering: bool,
    enableEdition: bool,
    enableClearSelection: bool,
    showFilters: bool,
    searchValue: string,
    defaultFilters: array,
    selectedRows: array,
    onSelectionChange: func,
    selectByRowClick: bool,
    showSelectAll: bool,
    showSwitchAll: bool,
    switchAllValue: bool,
    onChangeSwitchAll: func,
    getRowId: func,
    elevation: number,
    showAddButton: bool,
    onClickAddButton: func,
    onEdit: func,
    preventEditRow: func,
    onDelete: func,
    customBtn1: object,
    showResultCount: bool
};
